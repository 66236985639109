import { FC } from 'react';
import { Flex, Image, Text, H } from '../../components';
import { StorageDevices } from '../../assets';
import styled from '@emotion/styled';
import theme from '../../theme';

const StorageImage = styled(Image)`
  width: 40px;
  height: 32px;
`;

const EmptySchedule: FC = () => {
  return (
    <Flex
      height={'700px'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Flex
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'262px'}
        height={'140px'}>
        <StorageImage src={StorageDevices} marginBottom={'24px'} />
        <H type="headlineBold" textAlign={'center'}>
          Setup the details of your cleaning schedule
        </H>
        <Text
          type="footnote-regular"
          color={theme.gray800}
          textAlign={'center'}>
          Once your schedule is set, you can add cleaning instructions.
        </Text>
      </Flex>
    </Flex>
  );
};
export default EmptySchedule;
