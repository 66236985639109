import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import { FileUploadTeal } from '../../assets';
import theme from '../../theme';
import { Text } from '../typography/Text';

interface Props extends DropzoneProps {
  isUploaded?: boolean;
  setFormImage?: (file: File) => void;
  defaultImage?: string;
}

const Card = styled.div`
  height: 140px;
  width: 140px;
  background: ${theme.white};
  border: 0.5px dashed ${theme.gray400};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const DragAndDropFileUpload: FC<Props> = ({ ...props }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(
    props.defaultImage || null,
  );

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const [file] = acceptedFiles;
      const reader = new FileReader();
      reader.onload = () => {
        const { result } = reader;
        if (typeof result === 'string') {
          setUploadedImage(result);
        }
      };
      reader.readAsDataURL(file);

      if (props.setFormImage) {
        props.setFormImage(file);
      }
    }
  };

  useEffect(() => {
    if (props.defaultImage) {
      setUploadedImage(props.defaultImage);
    }
  }, [props.defaultImage]);

  return (
    <Dropzone {...props} onDrop={handleDrop}>
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
        const additionalClass = isDragAccept
          ? 'accept'
          : isDragReject
            ? 'reject'
            : '';
        return (
          <Card {...getRootProps({ className: `dropzone ${additionalClass}` })}>
            <input {...getInputProps()} />
            {uploadedImage ? (
              <ImagePreview src={uploadedImage} alt="Uploaded Image" />
            ) : (
              <>
                <div className="ant-upload-drag-icon">
                  <img src={FileUploadTeal} alt="Upload Icon" />
                </div>
                <Text type="footnote-regular" color={theme.brandTeal} pt="4px">
                  Upload
                </Text>
                <Text type="caption2-regular" color={theme.gray600} pt="4px">
                  Supported file types: (PNG, JPG)
                </Text>
              </>
            )}
          </Card>
        );
      }}
    </Dropzone>
  );
};

export default DragAndDropFileUpload;
