import styled from '@emotion/styled';
import { Flex as AntFlex, Dropdown, Menu, Spin, TableColumnsType } from 'antd';
import { FC, useEffect, useState } from 'react';
import {
  useAddCategory,
  useDeleteCategory,
  useEditCategory,
  useGetCategories,
  useGetItemsForCategory,
} from '../../../api/categoryHooks';
import { CirclePlus, MenuThreeDots, Sort } from '../../../assets';
import { Flex, MenuItem, PrimaryButton, Text } from '../../../components';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { RemoveCategoriesModal } from '../../../components/modals/RemoveCategoriesModal';
import TableComponent from '../../../components/table/TableComponent';
import { useAuth } from '../../../providers/auth-context';
import theme from '../../../theme';
import {
  CategoryI,
  CreateCategoryI,
  EditCategoryI,
} from '../../../types/category.types';
import { ManageByEnum } from '../../../types/cleaningProducts.types';
import { hideThreeDotsInCodeTable } from '../../../utils/codeUtil';
import { tableSorter } from '../../../utils/tableSorter';
import { isSuperAdmin } from '../../../utils/userRoleUtil';
import { getManageByTag } from '../../cleaning-products/ManageBy';
import SettingsLayout from '../settingsLayout/SettingsLayout';
import AddEditCategoryModal from './AddEditCategoryModal';
import { Image } from '../../../components';
import { Nederlanden, UnitedStates } from '../../../assets';

const FlexComp = styled(AntFlex)`
  border-bottom: 1px solid var(--Button-Fill, ${theme.gray400});
  padding: 24px 24px 16px 24px;

  @media screen and (max-width: 768px) {
    display: none !important;
  }
`;

const StyledImage = styled(Image)`
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
`;

const Categories: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [rowData, setRowData] = useState<CategoryI>();
  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const { currentUser, isLoading } = useAuth();

  const {
    data: categories,
    isLoading: categoriesLoading,
    refetch: getCategories,
  } = useGetCategories();

  const {
    mutate: addCategory,
    data: addedCategory,
    isSuccess: isSuccessAddCategory,
    isLoading: isLoadingAddCategory,
  } = useAddCategory();

  const {
    mutate: editCategory,
    data: editedCategory,
    isSuccess: isSuccessEditCategory,
  } = useEditCategory();

  const { mutate: getItemsForCategory, data: itemsForCategory } =
    useGetItemsForCategory();

  const {
    mutate: deleteCategory,
    isSuccess: isSuccessDeletedCategory,
    data: deletedCategoryData,
  } = useDeleteCategory();

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (
      isSuccessAddCategory ||
      isSuccessEditCategory ||
      isSuccessDeletedCategory
    ) {
      setVisible(false);
    }
    getCategories();
  }, [addedCategory, editedCategory, deletedCategoryData]);

  const columns: TableColumnsType<CategoryI> = [
    {
      title: (
        <Flex display="flex" alignItems="center">
          <StyledImage src={UnitedStates} />
          Name
        </Flex>
      ),
      dataIndex: 'name',
      width: 250,
      render: (value) => (
        <Flex display="flex">
          <Text type="subheading-regular">{value}</Text>
        </Flex>
      ),
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          <StyledImage src={Nederlanden} />
          Naam
        </Flex>
      ),
      dataIndex: 'nameDutch',
      width: 250,
      render: (value) => (
        <Flex display="flex">
          <Text type="subheading-regular">{value}</Text>
        </Flex>
      ),
    },
  ];

  if (currentUser && !isSuperAdmin(currentUser.role.id)) {
    columns.push({
      title: (
        <Flex display="flex" alignItems="center">
          <Text type={'footnote-regular'}>Manage by</Text>
          <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'type',
      width: 300,
      render: (value: ManageByEnum) => getManageByTag(value),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.type, b.type),
      },
    });
  }

  columns.push({
    title: ' ',
    dataIndex: 'id',
    width: 80,
    render: (_value, data) =>
      currentUser &&
      hideThreeDotsInCodeTable(data.type, currentUser.role.id) ? null : (
        <Dropdown
          dropdownRender={() => (
            <Menu>
              <MenuItem
                key="edit"
                onClick={() => {
                  setVisible(true);
                  setRowData(data);
                }}>
                Edit
              </MenuItem>
              {itemsForCategory && itemsForCategory.length > 0 ? (
                <MenuItem
                  key="archive"
                  onClick={() => setRemoveModalVisible(true)}>
                  <Text color={theme.red} type="subheading-regular">
                    Remove
                  </Text>
                </MenuItem>
              ) : (
                <MenuItem
                  key="archive"
                  onClick={() => {
                    setDeleteModalVisible(true);
                    setRowData(data);
                  }}>
                  <Text color={theme.red} type="subheading-regular">
                    Remove
                  </Text>
                </MenuItem>
              )}
            </Menu>
          )}>
          <Flex
            textAlign={'center'}
            onMouseOver={() => getItemsForCategory(data.id)}>
            <img src={MenuThreeDots} />
          </Flex>
        </Dropdown>
      ),
  });

  return (
    <>
      <SettingsLayout>
        <FlexComp>
          <Text type="body-bold">Categories</Text>
          <Flex marginLeft="auto">
            <PrimaryButton
              icon={<CirclePlus />}
              size="middle"
              onClick={() => {
                setRowData(undefined);
                setVisible(true);
              }}>
              Add Category
            </PrimaryButton>
          </Flex>
        </FlexComp>
        <Spin size="large" spinning={categoriesLoading || isLoading}>
          <Flex overflow="auto">
            <TableComponent
              columns={columns}
              dataSource={categories}
              showSorterTooltip={false}
              scroll={{
                y: window.innerHeight - 300,
              }}
              withBorders={true}
            />
          </Flex>
        </Spin>
      </SettingsLayout>
      {visible && (
        <AddEditCategoryModal
          visible={visible}
          data={rowData}
          onCancel={() => setVisible(false)}
          onSubmit={(formValues: EditCategoryI | CreateCategoryI) => {
            if (!rowData) {
              addCategory(formValues);
            } else {
              const editData: EditCategoryI = { ...formValues, id: rowData.id };
              editCategory(editData);
            }
          }}
          loading={isLoadingAddCategory}
        />
      )}
      {removeModalVisible && (
        <RemoveCategoriesModal
          open={removeModalVisible}
          content={`Category have been recorded for these items; hence they cannot be removed.`}
          title="Cannot be removed"
          items={itemsForCategory ? itemsForCategory.map((item) => item) : []}
          onCancel={() => setRemoveModalVisible(false)}
        />
      )}
      {deleteModalVisible && rowData && (
        <DeleteModal
          visible={deleteModalVisible}
          onClickCancel={() => {
            if (rowData && rowData.id) {
              deleteCategory(rowData.id);
            }
          }}
          content={`Are you sure you would like to remove ${rowData.name} category from the database? This action cannot be undone.`}
          title="Confirm Remove"
          buttonLabel="Remove"
          handleCancel={() => {
            setDeleteModalVisible(false);
            setRowData(undefined);
          }}
        />
      )}
    </>
  );
};
export default Categories;
