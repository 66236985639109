import { FC } from 'react';
import { Flex, H, PrimaryButton, Text } from '../../components';
import theme from '../../theme';
import styled from '@emotion/styled';
import { ForgotPasswordFormI } from '../../types/auth.types';
import { FormInstance } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSubmitForgotPassword } from '../../api/authHooks';
import { DeleteAccountEmailI } from '../../types/user.types';
import { useSubmitDeleteAccountEmail } from '../../api/userHooks';

interface Props {
  form: FormInstance;
  isDeleteAccount?: boolean;
}

const Content = styled.div`
  z-index: 1;
  display: flex;
  margin: 0 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 0.5px solid ${theme.gray400};
`;

const ResentLinkSent: FC<Props> = ({ form, isDeleteAccount }) => {
  const navigate = useNavigate();
  const { mutate: submitForgotPassword, isLoading: isForgotPasswordLoading } =
    useSubmitForgotPassword();
  const {
    mutate: submitDeleteUserEmail,
    isLoading: isLoadingSubmitDeleteEmail,
  } = useSubmitDeleteAccountEmail();

  const resendLink = () => {
    if (isDeleteAccount) {
      if (!isLoadingSubmitDeleteEmail) {
        const values: DeleteAccountEmailI = {
          email: form.getFieldValue('email'),
        };
        submitDeleteUserEmail(values);
      }
    } else {
      if (!isForgotPasswordLoading) {
        const values: ForgotPasswordFormI = {
          email: form.getFieldValue('email'),
        };
        submitForgotPassword(values);
      }
    }
  };

  return (
    <div>
      <Flex>
        <H type="largeTitle" color={theme.brandDarkTeal} mb="16px">
          {isDeleteAccount
            ? 'Delete account link sent'
            : 'Password resent link sent'}
        </H>
        <Text type="subheading-regular" color={theme.gray800} mb="40px">
          Check your inbox, we just sent you an email at{' '}
          <Text type="subheading-regular" color={theme.brandDarkTeal}>
            {form.getFieldValue('email')}
          </Text>
        </Text>
      </Flex>

      <PrimaryButton
        htmlType="submit"
        block
        mt="40px"
        mb="32px"
        onClick={() => navigate('/login')}>
        Back to Sign In
      </PrimaryButton>
      <Content>
        <Text
          onClick={resendLink}
          mt="32px"
          type="subheading-regular"
          color={theme.brandTeal}
          cursor="pointer">
          Resend Link
        </Text>
      </Content>
    </div>
  );
};

export default ResentLinkSent;
