import { CustomTag } from '../../components/tags/CategoryTag';
import theme from '../../theme';
import { CleaningProductsStatusEnum } from '../../types/cleaningProducts.types';
import { getPascalCaseUtil } from '../../utils/caseUtil';

export const getStatusTag = (type: CleaningProductsStatusEnum) => {
  switch (type) {
    case CleaningProductsStatusEnum.ACTIVE:
      return (
        <CustomTag color={theme.lightCream} size="large">
          {getPascalCaseUtil(CleaningProductsStatusEnum.ACTIVE)}
        </CustomTag>
      );

    case CleaningProductsStatusEnum.ARCHIVE:
      return (
        <CustomTag color={theme.gray300} size="large">
          {getPascalCaseUtil(CleaningProductsStatusEnum.ARCHIVE)}
        </CustomTag>
      );

    default:
      null;
  }
};
