import { useMutation, useQuery } from 'react-query';
import {
  CategoryI,
  CreateCategoryI,
  EditCategoryI,
} from '../types/category.types';
import { ApiError } from './_types';
import { http } from './_apiService';
import { Message } from '../components/message/Message';

export const useGetCategories = () =>
  useQuery<CategoryI[], ApiError>(
    'getCategories',
    async () => {
      const response = await http.get('/item-category');
      return response.data;
    },
    {
      enabled: false,
    },
  );

export const useAddCategory = () =>
  useMutation<string, ApiError, CreateCategoryI>(
    async (data) => {
      const response = await http.post('/item-category', data);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
      onSuccess: () => {
        Message.success('Category added successfully!');
      },
    },
  );

export const useEditCategory = () =>
  useMutation<string, ApiError, EditCategoryI>(
    async (data) => {
      const response = await http.put(`/item-category/${data.id}`, {
        name: data.name,
        nameDutch: data.nameDutch,
      });
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
      onSuccess: () => {
        Message.success('Category updated successfully!');
      },
    },
  );

export const useGetItemsForCategory = () =>
  useMutation<string[], ApiError, string>(
    async (data) => {
      const response = await http.get(`/item-category/existing-items/${data}`);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
    },
  );

export const useDeleteCategory = () =>
  useMutation<string, ApiError, string>(
    async (data) => {
      const response = await http.delete(`/item-category/${data}`);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
      onSuccess: () => {
        Message.success('Category deleted successfully!');
      },
    },
  );
