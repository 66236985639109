import styled from '@emotion/styled';
import { CSSProperties } from 'react';

import { space, SpaceProps, border, BorderProps } from 'styled-system';

type ImageProps = React.HTMLAttributes<HTMLDivElement> & {
  cursor?: CSSProperties['cursor'];
} & SpaceProps &
  BorderProps;

export const Image = styled.img<ImageProps>`
  cursor: ${(props) => props.cursor && props.cursor};
  ${space}
  ${border}
`;
