import styled from '@emotion/styled';
import { Menu } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Flex, H } from '../../components';
import AddDropdown from '../../components/dropdown/AddDropdown';
import theme from '../../theme';
import {
  CleaningProductI,
  CleaningProductsI,
} from '../../types/cleaningProducts.types';
import AddEquAndCheModal from './AddEquAndCheModal';
import GenerateRowsAndCols from './GenerateRowsAndCols';
import { useWindowSize } from '../../utils/screenSize';

const MenuItem = styled(Menu.Item)`
  border-top: 0.5px solid ${theme.gray300};
  .ant-dropdown-menu-title-content {
    padding: 7px 54px 7px 4px;
    font-family: SF Pro Display;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
`;

interface CleaningProductsProps {
  handleCleaningProducts: (handleCleaningProducts: string[]) => void;
  initialValues?: string[] | CleaningProductsI[] | undefined;
}

interface Product {
  id: string;
  name: {
    en: string;
    nl: string;
  };
  code: string;
  type: string;
  manageType: string;
  image: string;
  status: string;
}

export const AddCleaningProducts: FC<CleaningProductsProps> = ({
  initialValues,
  handleCleaningProducts,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [isEquipment, setIsEquipment] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<CleaningProductI[]>([]);

  const handleModalSubmit = (prod: CleaningProductI) => {
    setVisible(false);
    setProducts((prev) => [...prev, prod]);
  };

  const handleLoadingStateChange = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  useEffect(() => {
    if (initialValues && initialValues.length > 0) {
      setProducts(initialValues as CleaningProductI[]);
    }
  }, [initialValues]);

  const updateProductsList = (updateProductsList: Product[]) => {
    const cleaningProducts = updateProductsList.map((product) => product.id);
    setProducts(updateProductsList as CleaningProductI[]);
    handleCleaningProducts(cleaningProducts);
  };

  const { isMobile } = useWindowSize();

  const columnsPerRow = isMobile ? 1 : 2;

  return (
    <>
      <Flex display={'flex'} alignItems={'center'} flexDirection={'row'}>
        <H type="headlineBold" mr={'12px'}>
          Cleaning Products
        </H>
        <AddDropdown
          dropdownRender={() => (
            <Menu>
              <MenuItem
                key="Equipment"
                onClick={() => {
                  setIsEquipment(true);
                  setVisible(true);
                }}>
                Equipment
              </MenuItem>
              <MenuItem
                key="Chemical"
                onClick={() => {
                  setIsEquipment(false);
                  setVisible(true);
                }}>
                Chemical
              </MenuItem>
            </Menu>
          )}
        />
      </Flex>

      {visible && (
        <AddEquAndCheModal
          isEquipment={isEquipment}
          visible={visible}
          onCancel={() => setVisible(false)}
          onSubmit={handleModalSubmit}
          onLoadingStateChange={handleLoadingStateChange}
          loading={loading}
          currentProducts={products}
        />
      )}

      {products.length > 0 ? (
        <GenerateRowsAndCols
          products={products}
          columnsPerRow={columnsPerRow}
          updateProductsList={updateProductsList}
        />
      ) : null}
    </>
  );
};
