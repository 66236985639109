import React, { FC } from 'react';
import { Input as AntInput } from 'antd';
import { InputProps } from 'antd/lib/input';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Text } from '..';

type Props = SpaceProps &
  Omit<InputProps, 'size'> & {
    label?: string;
    hint?: string;
    flexDirection?: string;
    size?: 'small' | 'large';
    status?: 'error' | 'warning';
  };

const StyledInput = styled(AntInput)<Props>`
  ${(props) => props.flexDirection === 'row' && 'width: 335px;'}
  font-family: SF Pro Display;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  height: ${(props) => (props.size == 'small' ? '32px !important' : '40px')};
  padding: ${(props) => props.size === 'small' && '0 12px'};
  border-radius: 8px;
  border: 0.5px solid ${theme.gray400};

  &:focus {
    border-color: ${theme.gray400};
    box-shadow: unset;
  }
  &:hover {
    border-color: ${theme.gray600} !important;
    box-shadow: unset;
  }
  .ant-input[disabled] {
    background-color: ${theme.gray100};
  }

  ${space};
`;

export const Input: FC<Props> = (props) => {
  const {
    label,
    hint,
    flexDirection = 'column',
    size = 'large',
    status,
  } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {label && (
        <Text type="subheading-regular" color={theme.black} mb="6px">
          {label}
        </Text>
      )}
      <StyledInput {...props} size={size} flexDirection={flexDirection} />
      {hint && (
        <Text
          type={'caption2-regular'}
          color={status ? theme.red : theme.gray600}
          mt="6px">
          {hint}
        </Text>
      )}
    </div>
  );
};
