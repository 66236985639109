import { Checkbox } from 'antd';
import type { CheckboxProps } from 'antd';
import { SpaceProps, space } from 'styled-system';
import theme from '../../theme';
import styled from '@emotion/styled';

type Props = SpaceProps & CheckboxProps;

export const CheckBoxComp = styled(Checkbox)<Props>`
  .ant-checkbox .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 1px solid ${theme.gray400};
  }
  .ant-input[disabled] {
    background-color: ${theme.gray200};
  }
  .ant-checkbox-inner {
    border-color: ${theme.gray400};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${theme.brandCream};
    border-color: ${theme.brandDarkTeal};
  }
  .ant-checkbox:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    border-color: ${theme.brandDarkTeal};
    outline: none;
  }
  .ant-checkbox-wrapper:after {
    display: none !important;
  }
  .ant-checkbox-input {
    outline: none;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.brandDarkTeal};
  }
  ${space}
`;
