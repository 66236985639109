/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Form, Row, Select, Spin } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FC, useEffect, useState } from 'react';
import {
  useCreateProduct,
  useGetAllProducts,
} from '../../api/cleaningProductsHooks';
import { Nederlanden, UnitedStates } from '../../assets';
import {
  Flex,
  FormContainer,
  Image,
  Input,
  RadioButtons,
  RadioGroup,
  SelectComp,
  Text,
} from '../../components';
import DragAndDropFileUpload from '../../components/fileUpload/DragAndDropFileUpload';
import ModalComponent from '../../components/modals/ModalComponent';
import {
  CleaningProductFormI,
  CleaningProductI,
  CleaningProductTypeEnum,
  CleaningProductsI,
} from '../../types/cleaningProducts.types';
import { getPascalCaseUtil } from '../../utils/caseUtil';

interface Props {
  visible: boolean;
  isEquipment: boolean;
  onCancel: () => void;
  onSubmit: (prod: CleaningProductI) => void;
  onLoadingStateChange: (isLoading: boolean) => void;
  loading: boolean;
  currentProducts: CleaningProductI[];
}

export enum ExEquipmentEnum {
  CASH_REGISTER = 'CASH_REGISTER',
  ELECTRIC_BOILING_PAN = 'ELECTRIC_BOILING_PAN',
}

const AddEquAndCheModal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  onLoadingStateChange,
  loading,
  isEquipment,
  currentProducts,
}) => {
  const [form] = Form.useForm();
  const [isNewEquipment, setIsNewEquipment] = useState(true);
  const [isSelectExEquipment, setIsSelectExEquipment] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<CleaningProductsI>();
  const [selectedImage, setSelectedImage] = useState<string>();
  const [uniqueProducts, setUniqueProducts] = useState<CleaningProductI[]>();
  const [uniqueEquipments, setUniqueEquipments] =
    useState<CleaningProductI[]>();
  const [uniqueChemicals, setUniqueChemicals] = useState<CleaningProductI[]>();

  const {
    mutate: createProductMutation,
    data: createProductData,
    isSuccess: createProductIsSuccess,
    isLoading: createProductIsLoading,
  } = useCreateProduct();

  const { data: allProductsData, isLoading: allProductsIsLoading } =
    useGetAllProducts();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const onFinish = (formValues: CleaningProductFormI) => {
    formValues.type = isEquipment
      ? CleaningProductTypeEnum.EQUIPMENT
      : CleaningProductTypeEnum.CHEMICAL;

    if (uploadedFile) {
      formValues.image = uploadedFile;
    }

    if (isNewEquipment) {
      createProductMutation(formValues);
    } else if (selectedProduct) {
      onSubmit(selectedProduct);
    }
  };

  useEffect(() => {
    const uniqueProductsData = allProductsData?.filter(
      (data) => !currentProducts.includes(data),
    );
    const uniqueEquips = uniqueProductsData?.filter(
      (data) => data.type === CleaningProductTypeEnum.EQUIPMENT,
    );
    const uniqueChems = uniqueProductsData?.filter(
      (data) => data.type === CleaningProductTypeEnum.CHEMICAL,
    );
    setUniqueEquipments(uniqueEquips);
    setUniqueChemicals(uniqueChems);
    setUniqueProducts(uniqueProductsData);
  }, [allProductsData]);

  useEffect(() => {
    onLoadingStateChange(createProductIsLoading);
  }, [createProductIsLoading]);

  useEffect(() => {
    if (createProductData && createProductIsSuccess) {
      onSubmit(createProductData);
    }
  }, [createProductData, createProductIsSuccess]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (selectedProduct && isSelectExEquipment) {
      form.setFieldsValue({
        code: selectedProduct.code,
        name: {
          en: selectedProduct.name.en,
          nl: selectedProduct.name.nl,
        },
      });
    }
  }, [selectedProduct]);

  const onOk = () => {
    form && form.submit();
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    setIsNewEquipment(e.target.value === 'true');
    setIsSelectExEquipment(false);
  };

  useEffect(() => {
    if (isNewEquipment) {
      form.resetFields();
      setUploadedFile(null);
    }
  }, [isNewEquipment]);

  const handleExistingEquipmentChange = (value: any) => {
    setIsSelectExEquipment(true);
    const selectedProduct = allProductsData?.find(
      (product) => product.id === value,
    );
    setSelectedProduct(selectedProduct);
    setSelectedImage(selectedProduct?.image);
  };

  return (
    <ModalComponent
      onCancel={onCancel}
      closable={true}
      width={524}
      hideCancel={false}
      header={isEquipment ? 'Add Equipment' : 'Add Chemical'}
      visible={visible}
      onSubmit={onOk}
      loadingBtn={loading}>
      <Flex width={'100%'} display="">
        <FormContainer
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          onFinish={(v) => onFinish(v)}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="isNewEquipment">
                <RadioGroup onChange={handleRadioChange} defaultValue="true">
                  <RadioButtons
                    value="true"
                    label={isEquipment ? 'New Equipment' : 'New Chemical'}
                    mr={'40px'}
                  />
                  <RadioButtons
                    value="false"
                    label={
                      isEquipment ? 'Existing Equipment' : 'Existing Chemical'
                    }
                    mr={'40px'}
                  />
                </RadioGroup>
              </Form.Item>
            </Col>
          </Row>

          {!isNewEquipment && (
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item label="Choose Existing Equipment" name="exEquipment">
                  <Spin spinning={allProductsIsLoading}>
                    <SelectComp
                      placeholder="Select"
                      disabled={
                        (isEquipment && uniqueEquipments?.length === 0) ||
                        (!isEquipment && uniqueChemicals?.length === 0)
                      }
                      onChange={handleExistingEquipmentChange}>
                      {uniqueProducts &&
                        uniqueProducts.length > 0 &&
                        uniqueProducts
                          .filter((item) =>
                            isEquipment
                              ? item.type === CleaningProductTypeEnum.EQUIPMENT
                              : item.type === CleaningProductTypeEnum.CHEMICAL,
                          )
                          .map((product) => (
                            <Select.Option key={product.id} value={product.id}>
                              {getPascalCaseUtil(product.name.en)}
                            </Select.Option>
                          ))}
                    </SelectComp>
                  </Spin>
                </Form.Item>
              </Col>
            </Row>
          )}

          {(isNewEquipment || isSelectExEquipment) && (
            <>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item label="Code *" name="code">
                    <Input
                      placeholder="Enter code"
                      disabled={isSelectExEquipment}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label={
                      <Flex display="flex">
                        <Image src={UnitedStates} borderRadius={'24px'} />
                        <Text type="subheading-regular" ml="6px">
                          {isEquipment ? 'Equipment Name *' : 'Chemical Name *'}
                        </Text>
                      </Flex>
                    }
                    rules={[
                      {
                        required: true,
                        message: isEquipment
                          ? 'Enter equipment name'
                          : 'Enter chemical name',
                      },
                    ]}
                    name={['name', 'en']}>
                    <Input
                      placeholder="Enter name"
                      disabled={isSelectExEquipment}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label={
                      <Flex display="flex">
                        <Image src={Nederlanden} />
                        <Text type="subheading-regular" ml="6px">
                          {isEquipment
                            ? 'Naam van apparatuur *'
                            : 'Chemische naam *'}
                        </Text>
                      </Flex>
                    }
                    rules={[
                      {
                        required: true,
                        message: isEquipment
                          ? 'Voer de naam van de apparatuur in'
                          : 'Voer de chemische naam in',
                      },
                    ]}
                    name={['name', 'nl']}>
                    <Input
                      placeholder="Voer naam in"
                      disabled={isSelectExEquipment}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    name="image"
                    label={'Upload Image*'}
                    rules={[
                      {
                        required: !uploadedFile && !selectedProduct,
                        message: 'Upload product image',
                      },
                    ]}>
                    <DragAndDropFileUpload
                      minSize={1024}
                      maxSize={10072000}
                      accept={['image/jpeg', 'image/png']}
                      setFormImage={(file) => {
                        setUploadedFile(file);
                      }}
                      defaultImage={selectedImage}
                      disabled={isSelectExEquipment}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </FormContainer>
      </Flex>
    </ModalComponent>
  );
};
export default AddEquAndCheModal;
