import { FC } from 'react';
import { Input as AntInput } from 'antd';
import { InputProps } from 'antd/lib/input';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../../theme';

import { Search } from '../../assets';

type Props = SpaceProps &
  Omit<InputProps, 'size' | 'status' | 'addonBefore'> & {
    size?: 'small' | 'large';
  };

const StyledInput = styled(AntInput)<Props>`
  width: 224px;
  font-family: SF Pro Display;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding: ${(props) => props.size === 'small' && '0 12px'};
  height: ${(props) => (props.size == 'small' ? '32px !important' : '40px')};
  border-radius: 8px;
  border: 0.5px solid ${theme.brandDarkTeal};

  &:focus,
  &:focus-within {
    border-color: ${theme.brandDarkTeal};
    box-shadow: unset;
  }
  &:hover {
    border-color: ${theme.brandDarkTeal};
    box-shadow: unset;
  }

  @media (max-width: 760px) {
    width: 100%;
  }

  ${space};
`;

export const SearchInput: FC<Props> = (props) => {
  const { size = 'large' } = props;

  return <StyledInput {...props} size={size} suffix={<img src={Search} />} />;
};
