import { FC } from 'react';
import ModalComponent from './ModalComponent';
import { Text } from '../typography/Text';

interface PropsI {
  modalVisible: boolean;
  handleCancel: () => void;
  handleProceed: () => void;
  message?: string;
}

export const BlockRouteLeaveModal: FC<PropsI> = ({
  modalVisible,
  handleCancel,
  handleProceed,
  message = 'There are some unsaved changes. Do you still want to continue?',
}) => {
  return (
    <>
      <ModalComponent
        maskClosable={false}
        hideCancel={false}
        header="Are you sure you want to leave"
        visible={modalVisible}
        onSubmit={handleCancel}
        onCancel={handleProceed}
        secondaryBtnSize="middle"
        primaryBtnSize="middle"
        secondaryBtnLabel="Leave this page"
        primaryBtnLabel="Stay on this page">
        <Text type={'subheading-regular'} pb={'24px'}>
          {message}
        </Text>
      </ModalComponent>
    </>
  );
};
