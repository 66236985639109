import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Flex } from '..';
import { SpaceProps, space } from 'styled-system';
import theme from '../../theme';
import { Tag } from 'antd';

interface Props {
  text: string;
  backgroundColor: string;
  noBullet?: boolean;
  bulletColor: string;
}
const CustomTag = styled(Tag)`
  height: 28px;
  padding: 2px 12px;
  font-family: SF Pro Display;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  border-radius: 8px;
  ${space};
`;
const Paragraph = styled.p`
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: ${theme.black};
  opacity: 0.6;
`;

const Span = styled.span<
  { tagColor: string; borderColor?: string } & SpaceProps
>`
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.tagColor};
  border-radius: 50%;
  display: inline-block;
  border: ${(props) => props.borderColor || ''};
  cursor: pointer;
  ${space}
`;

export const Tags: FC<Props> = ({
  noBullet,
  backgroundColor,
  text,
  bulletColor,
}) => {
  return (
    <CustomTag color={backgroundColor}>
      <Flex display="flex">
        {!noBullet && (
          <Span
            tagColor={bulletColor}
            borderColor={bulletColor}
            mt="6px"
            mr="5px"
          />
        )}
        <Paragraph>{text}</Paragraph>
      </Flex>
    </CustomTag>
  );
};
