/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import type { TableProps } from 'antd';
import { Flex as AntdFlex, Table } from 'antd';
import { FC, ReactNode } from 'react';
import { Flex, H, PrimaryButton } from '..';
import { CirclePlus, EmptySVG } from '../../assets';
import theme from '../../theme';

interface Props<T extends object = any> extends TableProps<T> {
  withBorders?: boolean;
  rowSize?: number;
  headerFontSize?: number;
  onClick?: () => void;
  emptyInfoText?: string | ReactNode;
  height?: string;
}
const StyledTable = styled(Table)<Props>`
  border-radius: 0;
  height: ${(props) => (props.height ? props.height : '80vh')};
  ${(props) =>
    props.withBorders &&
    `
      border:  1px solid ${theme.gray300};
      border-bottom:unset;
      border-radius: 0;
    `}
  .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
    background: ${theme.gray200};
    padding: 8px 0px 8px 24px;
    border-radius: 0 !important;
  }

  .ant-table-tbody > tr > td {
    padding-top: 8px;
    border-bottom: 0.5px solid ${theme.gray400} !important;
    padding-bottom: 8px;
    padding-left: 24px !important;
    padding-right: 0px !important;
  }
  .ant-table-column-sorter-inner {
    display: none !important;
  }
  .ant-table-wrapper .ant-table-expanded-row-fixed {
    padding: 0px;
  }
`;

const TableComponent: FC<Props> = ({
  withBorders = false,
  onClick,
  emptyInfoText,
  height,
  ...props
}) => {
  const locale = {
    emptyText: (
      <Flex margin={`80px 0px`}>
        <img src={EmptySVG} />
        {emptyInfoText ? (
          <H type="headlineBold" color={theme.brandDarkTeal}>
            No items added yet. Click the
            <br /> button below to add your first item.
          </H>
        ) : (
          <H type="headlineBold" color={theme.brandDarkTeal}>
            No items added yet.
          </H>
        )}
        <AntdFlex justify="center">
          {onClick && (
            <PrimaryButton
              marginTop="16px"
              size="middle"
              icon={<CirclePlus />}
              onClick={() => onClick()}>
              Add Item
            </PrimaryButton>
          )}
        </AntdFlex>
      </Flex>
    ),
  };

  return (
    <>
      <StyledTable
        {...props}
        height={height}
        pagination={false}
        withBorders={withBorders}
        locale={locale}
      />
    </>
  );
};

export default TableComponent;
