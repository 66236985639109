import React, { FC } from 'react';
import { DatePicker } from 'antd';
import theme from '../../theme';
import { Flex } from '../flex/Flex';
import styled from '@emotion/styled';
import { Text } from '../typography/Text';

export interface DatePickerCompProps {
  label?: string;
  size?: 'large' | 'middle' | 'small';
  [propName: string]: any;
}

const Label = styled(Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.black};
  padding-bottom: 4px;
`;
const DatePickerCustom = styled(DatePicker)`
  border: 0.5px solid ${theme.gray400} !important;
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  box-shadow: none !important;

  &:focus {
    border-color: ${theme.gray400} !important;
  }

  &:hover {
    border-color: ${theme.gray600} !important;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: ${theme.brandTeal} !important;
  }
`;

const DatePickerComp: FC<DatePickerCompProps> = (props) => {
  const { label, size = 'large', ...rest } = props;
  return (
    <Flex display="flex" flexDirection="column">
      {label && <Label type={'body-bold'}>{label}</Label>}

      <DatePickerCustom {...rest} size={size} color={theme.brandTeal} />
    </Flex>
  );
};

export default DatePickerComp;
