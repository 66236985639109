import React, { FC, useState } from 'react';
import { Button, Modal } from 'antd';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Text } from '../typography/Text';
import { Flex } from '../flex/Flex';
import { Trash } from '../../assets';
import { DeleteButton } from '../buttons/DeleteButton';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px !important;
    padding: 0px;
  }

  .ant-modal-header {
    padding: 16px 16px 0px !important;
    border-radius: 8px 8px 0px 0px !important;
    border-bottom: none !important;
    margin: 0px !important;
  }

  .ant-modal-footer {
    padding: 12px 16px;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid ${theme.gray300};
    margin: 0px;
  }

  @media screen and (max-width: 768px) {
    .ant-modal-body {
      padding: 24px;
    }
  }
`;

const CancelButton = styled(Button)`
  background-color: ${theme.white} !important;
  border-color: ${theme.brandDarkTeal} !important;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid ${theme.brandDarkTeal};
  height: 32px;
  color: ${theme.brandDarkTeal};
  &:hover {
    color: ${theme.brandDarkTeal} !important;
  }
`;

const ModalBody = styled(Flex)`
  padding: 8px 16px 24px 16px !important;
`;

interface Props {
  content: string;
  title: string;
  visible: boolean;
  buttonLabel: string;
  handleCancel: () => void;
  onClickCancel?: () => void;
}
export const DeleteModal: FC<Props> = ({
  content,
  title,
  buttonLabel,
  onClickCancel,
  handleCancel,
  visible,
}) => {
  const [disabled, setDisabled] = useState(true);

  const handleDelete = () => {
    onClickCancel && onClickCancel();
    handleCancel();
  };

  return (
    <>
      <StyledModal
        open={visible}
        width="400px"
        closable={false}
        title={
          <div
            style={{
              width: '75%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => setDisabled(true)}>
            <Flex backgroundColor={theme.red} width={48} height={48} mb={'8px'}>
              <Trash />
            </Flex>

            <Text type="body-bold" color={theme.black}>
              {title}
            </Text>
          </div>
        }
        onCancel={handleCancel}
        footer={[
          <>
            <CancelButton
              key="submit"
              id="delete-button"
              onClick={handleCancel}
              color={theme.brandDarkTeal}>
              Cancel
            </CancelButton>
            <DeleteButton
              size="middle"
              key="submit"
              id="delete-button"
              onClick={() => handleDelete()}>
              {buttonLabel}
            </DeleteButton>
          </>,
        ]}>
        <ModalBody>
          <Text type="subheading-regular" color={theme.gray800}>
            {content}
          </Text>
        </ModalBody>
      </StyledModal>
    </>
  );
};
