import { Col, Form, Row } from 'antd';
import { FC, useEffect } from 'react';
import ModalComponent from '../../../components/modals/ModalComponent';
import { Flex, FormContainer, Input } from '../../../components';
import { CategoryI, CreateCategoryI } from '../../../types/category.types';
import { Nederlanden, UnitedStates } from '../../../assets';
import { Image } from '../../../components';
import styled from '@emotion/styled';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (formValues: CreateCategoryI) => void;
  loading: boolean;
  data?: CategoryI;
}
const StyledLabelImage = styled(Image)`
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
`;

const AddEditCategoryModal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  loading,
  data,
}) => {
  const [form] = Form.useForm();
  const isEditing = data ? true : false;

  const onFinish = (formValues: CreateCategoryI) => {
    onSubmit(formValues);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [data, form]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const onOk = () => {
    form.submit();
  };

  return (
    <ModalComponent
      onCancel={onCancel}
      closable={true}
      width={524}
      hideCancel={false}
      header={isEditing ? 'Edit Category' : 'Add Category'}
      visible={visible}
      onSubmit={onOk}
      loadingBtn={loading}
      primaryBtnLabel={isEditing ? 'Save' : 'Add'}>
      <Flex width={'100%'} display="">
        <FormContainer
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          onFinish={(v) => onFinish(v)}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <Flex display="flex" alignItems="center">
                    <StyledLabelImage src={UnitedStates} />
                    Category name*
                  </Flex>
                }
                name="name"
                rules={[{ required: true, message: 'Enter category name' }]}>
                <Input placeholder="Enter category name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <Flex display="flex" alignItems="center">
                    <StyledLabelImage src={Nederlanden} />
                    Categorie naam*
                  </Flex>
                }
                name="nameDutch"
                rules={[
                  { required: true, message: 'Voer de categorienaam in' },
                ]}>
                <Input placeholder="Voer de categorienaam in" />
              </Form.Item>
            </Col>
          </Row>
        </FormContainer>
      </Flex>
    </ModalComponent>
  );
};
export default AddEditCategoryModal;
