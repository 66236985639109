import { useMutation } from 'react-query';
import {
  SubmitChangePasswordI,
  ForgotPasswordFormI,
  ResetPasswordI,
  UserLoginI,
  ValidateAuthTokenI,
  ValidateAuthTokenResponseI,
  AuthPayload,
  ResetTempPasswordI,
} from '../types/auth.types';
import { http } from './_apiService';
import { ApiError } from './_types';

export const useLoginUser = () =>
  useMutation<AuthPayload, ApiError, UserLoginI>(async (data: UserLoginI) => {
    const response = await http.post('/auth/super-admin/login', data);
    return response.data;
  });

export const useLogoutUser = () =>
  useMutation<string, ApiError>(async () => {
    const response = await http.post('/auth/logout');
    return response.data;
  });

export const useChangePassword = () =>
  useMutation<string, ApiError, SubmitChangePasswordI>(
    async (data: SubmitChangePasswordI) => {
      const response = await http.post('/auth/change-password', data);
      return response.data;
    },
  );

export const useValidateAuthToken = () =>
  useMutation<ValidateAuthTokenResponseI, ApiError, ValidateAuthTokenI>(
    async (data: ValidateAuthTokenI) => {
      const response = await http.post(
        '/auth/validate-reset-token-admin',
        data,
      );
      return response.data;
    },
  );

export const useResetPassword = () =>
  useMutation<string, ApiError, ResetPasswordI>(
    async (data: ResetPasswordI) => {
      const response = await http.post('/auth/reset-password-admin', data);
      return response.data;
    },
  );

export const useResetTempPassword = () =>
  useMutation<ResetTempPasswordI, ApiError, ResetPasswordI>(
    async (data: ResetPasswordI) => {
      const response = await http.post('/auth/reset-temp-password-admin', data);
      return response.data;
    },
  );

export const useSubmitForgotPassword = () =>
  useMutation<string, ApiError, ForgotPasswordFormI>(
    async (data: ForgotPasswordFormI) => {
      const response = await http.post('/auth/forgot-password-admin', data);
      return response.data;
    },
  );
