import { FC, ReactNode } from 'react';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { CirclePlus, WhiteDropdown, WhitePlus } from '../../assets';
import { PrimaryButton } from '../buttons/PrimaryButton';
import styled from '@emotion/styled';
import { SpaceProps, space } from 'styled-system';
import { icons } from 'antd/es/image/PreviewGroup';

type Props = SpaceProps & {
  menu?: MenuProps;
  dropdownRender?: (menus: ReactNode) => ReactNode;
};

const DropdownButton = styled(PrimaryButton)<SpaceProps>`
  ${space}

  &.btn-small {
    padding: 4px 4px;
  }
`;

const PlusIcon = styled(WhitePlus)`
  width: 15px;
  height: 15px;
`;

const AddDropdown: FC<Props> = ({ menu, dropdownRender }) => (
  <Dropdown menu={menu} dropdownRender={dropdownRender}>
    <DropdownButton size="small" icon={<PlusIcon />} />
  </Dropdown>
);

export default AddDropdown;
