import { Col, Form, Row, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import ModalComponent from '../../../components/modals/ModalComponent';
import {
  AlertMessage,
  Flex,
  FormContainer,
  SelectComp,
} from '../../../components';
import SelectWeekDaysComponent from '../../../components/daySelect/selectDaysComp';
import { OrgVenuesI, VenueFormI } from '../../../types/venue.types';
import { getPascalCaseUtil } from '../../../utils/caseUtil';
import {
  getDaysFromCategory,
  getMonthlyCleaningDays,
  getOpenDayCategories,
  getOpenDaysCategory,
  getWorkingDays,
} from '../../../utils/venueUtil';
import { OpenDayCategories } from '../../enums/Venue.enum';
import dayjs from 'dayjs';
import { DayEnum } from '../../enums/Day.enum';
import DatePickerComp from '../../../components/datePicker/DatepickerComp';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (formValues: VenueFormI) => void;
  loading: boolean;
  data?: OrgVenuesI;
}

const EditWorkingHoursModal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  loading,
  data,
}) => {
  const [form] = Form.useForm();
  const [selectedDays, setSelectedDays] = useState<DayEnum[]>([]);

  const onFinish = (formValues: VenueFormI) => {
    const annualCleaningDay = dayjs(formValues.annualCleaningDay).format(
      'DD-MM',
    );
    if (data) {
      formValues.id = data.id;
      formValues.openDays = selectedDays;
      formValues.annualCleaningDay = annualCleaningDay;
      onSubmit(formValues);

      onCancel();
    }
  };

  useEffect(() => {
    const selectDays = data?.openDays;
    const currentYear = dayjs().year();
    const annualCleaningDay = dayjs(
      `${data?.annualCleaningDay}-${currentYear}`,
      'DD-MM-YYYY',
    );

    const formData = {
      id: data?.id,
      selectDays,
      openDays: getOpenDaysCategory(data?.openDays as string[]),
      weeklyCleaningDay: data?.weeklyCleaningDay,
      monthlyCleaningDay: data?.monthlyCleaningDay,
      annualCleaningDay,
    };

    if (data) {
      setSelectedDays(data.openDays);
    }

    form.setFieldsValue(formData);
  }, [data]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (selectedDays) {
      const openDayCategory = getOpenDaysCategory(selectedDays as string[]);
      form.setFieldValue('openDays', openDayCategory);
    }
  }, [selectedDays]);

  const onOk = () => {
    form && form.submit();
  };

  const openDayCategories = getOpenDayCategories();
  const weeklyCleaningDays = getWorkingDays();
  const monthlyCleaningDays = getMonthlyCleaningDays();

  const handleOpenDaysChange = (value: unknown) => {
    const openDayCategory = value as OpenDayCategories;
    switch (openDayCategory) {
      case OpenDayCategories.CUSTOM:
        break;
      case OpenDayCategories.EVERYDAY:
        setSelectedDays(getDaysFromCategory(openDayCategory));
        break;
      case OpenDayCategories.WORK_WEEK:
        setSelectedDays(getDaysFromCategory(openDayCategory));
        break;
    }
  };

  return (
    <>
      {data && (
        <ModalComponent
          onCancel={onCancel}
          width={524}
          hideCancel={false}
          primaryBtnLabel="Update"
          header="Edit Business Hours"
          visible={visible}
          onSubmit={onOk}
          loadingBtn={loading}>
          <Flex width={'100%'} display="">
            <FormContainer
              layout="vertical"
              form={form}
              validateTrigger="onBlur"
              onFinish={(v) => onFinish(v)}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Open Days *" name="openDays">
                    <SelectComp onChange={handleOpenDaysChange}>
                      {openDayCategories?.map((val, index) => (
                        <Select.Option key={index} value={val}>
                          {getPascalCaseUtil(val)}
                        </Select.Option>
                      ))}
                    </SelectComp>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Select Days *">
                    <SelectWeekDaysComponent
                      selectedDays={selectedDays}
                      setSelectedDays={setSelectedDays}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label="Weekly Cleaning Day *"
                    name="weeklyCleaningDay">
                    <SelectComp>
                      {weeklyCleaningDays.map((day, index) => (
                        <Select.Option key={index} value={day}>
                          {getPascalCaseUtil(day)}
                        </Select.Option>
                      ))}
                    </SelectComp>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label="Monthly Cleaning Day *"
                    name="monthlyCleaningDay">
                    <SelectComp>
                      {monthlyCleaningDays.map((day) => (
                        <Select.Option key={day}>{day}</Select.Option>
                      ))}
                    </SelectComp>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label="Annual Cleaning Day *"
                    name="annualCleaningDay">
                    <DatePickerComp
                      style={{ width: '100%' }}
                      size="large"
                      format="MMMM DD"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormContainer>
            <div style={{ marginBottom: '24px' }}>
              <AlertMessage
                text="Your updates will be reflected starting tomorrow."
                type="success"
              />
            </div>
          </Flex>
        </ModalComponent>
      )}
    </>
  );
};

export default EditWorkingHoursModal;
