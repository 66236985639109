import { ComponentProps, FC, useEffect, useRef } from 'react';
import Select, { RefSelectProps } from 'antd/lib/select';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Text } from '../';
import { BlackDropdown } from '../../assets';

interface SelectProps {
  label?: string;
  hint?: string;
  forceClose?: boolean;
  flexDirection?: string;
  status?: 'error' | 'warning';
  overruleWidthPresent?: number | undefined;
}

type Props = ComponentProps<typeof Select> & SelectProps;

const SelectInput = styled(Select)<Props>`
  font-family: SF Pro Display;
  font-size: 15px !important;
  width: 100%;
  .ant-select-selector {
    box-sizing: border-box !important;
    box-shadow: none !important;
    border: 0.5px solid ${theme.gray400} !important;
    border: ${(props) =>
      props.status
        ? `1px solid ${theme.red} !important`
        : `0.5px solid ${theme.gray400} !important`};
  }
  &:hover .ant-select-selector {
    border: ${(props) =>
      props.status
        ? `0.5px solid ${theme.red} !important`
        : `0.5px solid ${theme.gray400} !important`};
  }
`;

export const SelectComp: FC<Props> = (props) => {
  const {
    label,
    hint,
    size = 'large',
    status,
    flexDirection = 'column',
    overruleWidthPresent = undefined,
    forceClose = false,
    ...rest
  } = props;

  const selectRef = useRef<RefSelectProps | null>(null);

  useEffect(() => {
    if (forceClose) {
      selectRef.current?.blur();
    }
  }, [forceClose]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {label && (
        <Text type="subheading-regular" color={theme.black} mb="6px">
          {label}
        </Text>
      )}
      <SelectInput
        status={status}
        suffixIcon={<BlackDropdown />}
        flexDirection={flexDirection}
        overruleWidthPresent={overruleWidthPresent}
        ref={(node) => (selectRef.current = node)}
        size={size}
        {...rest}
      />
      {hint && (
        <Text
          type={'caption2-regular'}
          color={status ? theme.red : theme.gray600}
          mt="6px">
          {hint}
        </Text>
      )}
    </div>
  );
};
