import React, { FC } from 'react';
import { RadioGroupProps } from 'antd/lib/radio';
import { Radio, RadioProps } from 'antd';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';

import theme from '../../theme';

type Props = SpaceProps & RadioProps & { label?: string };

const StyledRadio = styled(Radio)<Props>`
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: ${theme.white};
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${theme.brandTeal};
    background-color: unset;
  }
  .ant-radio-inner::after {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    background-color: ${theme.brandTeal};
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
    background-color: ${theme.white};
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${theme.brandTeal}!important;
}
  }
  .ant-radio-wrapper:hover .ant-radio-wrapper,
  .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: ${theme.brandTeal}!important;
}
  }
  ${space};
`;
export const RadioButtons: FC<Props> = (props) => {
  const { label } = props;

  return <StyledRadio {...props}>{label}</StyledRadio>;
};

export const RadioGroup: FC<RadioGroupProps> = (props) => {
  return <Radio.Group {...props}>{props.children}</Radio.Group>;
};
