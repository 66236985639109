import React, { FC } from 'react';

import { SpaceProps, Tag } from 'antd';
import styled from '@emotion/styled';
import { space } from 'styled-system';
import { Text } from '../';

type Props = SpaceProps & {
  tagColor: string;
  textColor: string;
  text: string;
  size?: 'small' | 'large';
};

const CustomTag = styled(Tag)<{ size: 'small' | 'large' }>`
  height: ${(props) => (props.size === 'small' ? '20px' : '28px')};

  padding: ${(props) =>
    props.size === 'small' ? '2px 6px 2px 6px' : '3px 12px'};
  font-family: SF Pro Display;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  border-radius: 8px;
  cursor: pointer;
  ${space};
`;

const NameTag: FC<Props> = ({ tagColor, textColor, text, size = 'large' }) => (
  <CustomTag color={tagColor} size={size}>
    {size === 'large' ? (
      <Text type="regular" color={textColor}>
        {text}
      </Text>
    ) : (
      <Text type="caption2-regular" color={textColor}>
        {text}
      </Text>
    )}
  </CustomTag>
);

export default NameTag;
