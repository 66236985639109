import { FC } from 'react';
import { Global, css } from '@emotion/react';
import theme from './theme';
import { NavigationRoutes } from './NavigationRoutes';

const App: FC = () => {
  return (
    <div className="App">
      <Global
        styles={css`
          * {
            font-family: 'SF Pro Display Display', 'Inter', sans-serif;
          }
          ::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
            background-color: ${theme.gray200} !important;
          }
          ::-webkit-scrollbar-thumb {
            background-color: ${theme.gray600}!important;
          }
        `}
      />
      <NavigationRoutes />
    </div>
  );
};

export default App;
