import React, { FC, MouseEvent, ReactNode } from 'react';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../../theme';

type Props = SpaceProps & {
  size?: 'small' | 'middle' | 'large';
  className?: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  children?: ReactNode;
  icon?: ReactNode;
};

const StyledText = styled.div<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${space}
`;

const IconWrapper = styled.span`
  padding-top: 6px !important;
  margin-right: 5px !important;
`;

export const LinkedButton: FC<Props> = ({ size = 'large', ...props }) => {
  let className = `btn-${size} ${props.className || ''}`;

  if (props.disabled) {
    className = 'btn-disabled ' + className;
  }

  return (
    <StyledText {...props} className={className} onClick={props.onClick}>
      {props.icon && <IconWrapper>{props.icon}</IconWrapper>}
      {props.children}
    </StyledText>
  );
};
