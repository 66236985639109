import NameTag from '../../components/tags/NameTag';
import theme from '../../theme';
import { ManageByEnum } from '../../types/cleaningProducts.types';

export const getManageByTag = (type: ManageByEnum) => {
  switch (type) {
    case ManageByEnum.CC_MANAGED:
      return (
        <NameTag
          tagColor={theme.lightPurple}
          textColor={theme.brandDarkTeal}
          text={'CC Manage'}
        />
      );

    case ManageByEnum.CUSTOM:
      return (
        <NameTag
          tagColor={theme.lightBlue}
          textColor={theme.brandDarkTeal}
          text={'Custom'}
        />
      );

    default:
      null;
  }
};
