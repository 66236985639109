import React, { FC, useEffect, useState } from 'react';
import { Input as AntInput } from 'antd';
import { InputProps } from 'antd/lib/input';
import { marginRight, space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Flex, Text } from '../';
import { BrandTealCheck, GrayCheck } from '../../assets';
import {
  PasswordStatusType,
  getPasswordStatus,
} from '../../utils/passwordStatus.util';

type Props = SpaceProps &
  Omit<InputProps, 'size'> & {
    label?: string;
    hint?: string;
    flexDirection?: string;
    size?: 'small' | 'large';
    status?: 'error' | 'warning';
    textMarginTop?: string;
    showPasswordPolicy: boolean;
    showRequiredError?: boolean;
  };

const StyledInput = styled(AntInput.Password)<Props>`
  ${(props) => props.flexDirection === 'row' && 'width: 335px;'}
  font-family: SF Pro Display;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  height: ${(props) => (props.size == 'small' ? '32px !important' : '40px')};
  padding: ${(props) => props.size === 'small' && '0 12px'};
  border-radius: 8px;
  border: 0.5px solid ${theme.gray400};
  .ant-input {
    font-family: SF Pro Display !important;
  }
  &:focus,
  &:focus-within {
    border-color: ${theme.gray400};
    box-shadow: unset;
  }
  &:hover {
    border-color: ${theme.gray600};
    box-shadow: unset;
  }

  ${space};
`;

const StyledFlex = styled(Flex)`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`;

const StyledBrandTealCheck = styled(BrandTealCheck)`
  margin-right: 8px;
  margin-bottom: 5px;
`;

const StyledGrayCheck = styled(GrayCheck)`
  margin-right: 8px;
  margin-bottom: 5px;
`;

const StyledErrorMessage = styled(Flex)`
  transition: all 0.6s;
  opacity: 0;
  transform: translateY(-5px);

  &.ant-form-item-explain-error {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
`;

export const PasswordInput: FC<Props> = (props) => {
  const [passwordStatus, setPasswordStatus] = useState<PasswordStatusType>();

  const {
    label,
    hint,
    flexDirection = 'column',
    size = 'large',
    status,
    value,
    textMarginTop,
    showPasswordPolicy,
    showRequiredError,
  } = props;

  useEffect(() => {
    if (value) {
      const currentPasswordStatus: PasswordStatusType = getPasswordStatus(
        value?.toString(),
      );
      setPasswordStatus(currentPasswordStatus);
    } else {
      setPasswordStatus({
        minCharacters: false,
        upperCaseCharacter: false,
        lowerCaseCharacter: false,
        numericCharacter: false,
        specialCharacter: false,
      });
    }
  }, [value]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {label && (
          <Text type="subheading-regular" color={theme.black} mb="6px">
            {label}
          </Text>
        )}
        <StyledInput {...props} size={size} flexDirection={flexDirection} />
        {hint && (
          <Text
            type={'caption2-regular'}
            color={status ? theme.red : theme.gray800}
            mt="6px">
            {hint}
          </Text>
        )}
      </div>
      {showRequiredError && (
        <StyledErrorMessage className="ant-form-item-explain-error">
          Please enter password
        </StyledErrorMessage>
      )}
      {showPasswordPolicy && (
        <Flex mt={textMarginTop ? textMarginTop : '5px'}>
          <Text type={'footnote-regular'} mb={'8px'}>
            Create a strong password:
          </Text>

          <StyledFlex>
            {passwordStatus?.minCharacters ? (
              <StyledBrandTealCheck />
            ) : (
              <StyledGrayCheck />
            )}
            <Text
              type={'footnote-regular'}
              color={
                passwordStatus?.minCharacters ? theme.brandTeal : theme.gray800
              }>
              Minimum 8 characters
            </Text>
          </StyledFlex>

          <StyledFlex>
            {passwordStatus?.upperCaseCharacter ? (
              <StyledBrandTealCheck />
            ) : (
              <StyledGrayCheck />
            )}
            <Text
              type={'footnote-regular'}
              color={
                passwordStatus?.upperCaseCharacter
                  ? theme.brandTeal
                  : theme.gray800
              }>
              At least 1 upper case letter (A...Z)
            </Text>
          </StyledFlex>
          <StyledFlex>
            {passwordStatus?.lowerCaseCharacter ? (
              <StyledBrandTealCheck />
            ) : (
              <StyledGrayCheck />
            )}
            <Text
              type={'footnote-regular'}
              color={
                passwordStatus?.lowerCaseCharacter
                  ? theme.brandTeal
                  : theme.gray800
              }>
              At least 1 lower case letter (a...z)
            </Text>
          </StyledFlex>
          <StyledFlex>
            {passwordStatus?.numericCharacter ? (
              <StyledBrandTealCheck />
            ) : (
              <StyledGrayCheck />
            )}
            <Text
              type={'footnote-regular'}
              color={
                passwordStatus?.numericCharacter
                  ? theme.brandTeal
                  : theme.gray800
              }>
              At least 1 number (0...9)
            </Text>
          </StyledFlex>
          <StyledFlex>
            {passwordStatus?.specialCharacter ? (
              <StyledBrandTealCheck />
            ) : (
              <StyledGrayCheck />
            )}
            <Text
              type={'footnote-regular'}
              color={
                passwordStatus?.specialCharacter
                  ? theme.brandTeal
                  : theme.gray800
              }>
              At least 1 special character (ex: @,#,$)
            </Text>
          </StyledFlex>
        </Flex>
      )}
    </>
  );
};
