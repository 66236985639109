import styled from '@emotion/styled';
import {
  Col,
  Dropdown,
  Menu,
  Row,
  Spin,
  TableColumnsType,
  Tooltip,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAdminArchive,
  useBusinessOwnerArchive,
  useGetBusinessOwnerItemList,
  useGetCCAdminItemList,
} from '../../api/itemHooks';
import {
  CirclePlus,
  MenuThreeDots,
  Sort,
  DisabledPlusCircle,
} from '../../assets';
import {
  AlertMessage,
  Flex,
  H,
  Image,
  PrimaryButton,
  Text,
} from '../../components';
import { MainCard } from '../../components/MainCard';
import { SearchInput } from '../../components/inputs/SearchInput';
import { Message } from '../../components/message/Message';
import { DeleteModal } from '../../components/modals/DeleteModal';
import TableComponent from '../../components/table/TableComponent';
import { getCodeTag } from '../../components/tags/CodeTag';
import { getStatusTag } from '../../components/tags/StatusTag';
import { useAuth } from '../../providers/auth-context';
import theme from '../../theme';
import { ManageByEnum } from '../../types/cleaningProducts.types';
import {
  AreaTypeFilterEnum,
  ItemsI,
  StatusEnum,
} from '../../types/equipmentsItems.types';
import { UserRoleIdEnum } from '../../types/user.types';
import { getPascalCaseUtil } from '../../utils/caseUtil';
import { useWindowSize } from '../../utils/screenSize';
import { tableSorter } from '../../utils/tableSorter';
import { addDotsForLongText } from '../../utils/textUtil';
import { isBusinessOwner, isSuperAdmin } from '../../utils/userRoleUtil';
import { getManageByTag } from '../cleaning-products/ManageBy';
import AreaSelector from './AreaSelector';
import VenuesSelector from './VenuesSelector';

type param = {
  venueIdParam: string;
};

const MenuItem = styled(Menu.Item)`
  border-top: 0.5px solid ${theme.gray300};
  .ant-dropdown-menu-title-content {
    padding: 7px 54px 7px 4px;
    font-family: SF Pro Display;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const PreviewImage = styled(Image)`
  .ant-image .ant-image-img {
    border-radius: 4px;
  }
`;

const Card = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media screen and (max-width: 760px) {
    display: inherit;
  }
`;

const SearchDiv = styled(Flex)`
  @media screen and (max-width: 760px) {
    margin: 8px 0px;
  }
`;

const ItemList: FC = () => {
  const { isTablet, isDesktop } = useWindowSize();
  const { currentUser, isLoading } = useAuth();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [venueId, setVenueId] = useState<string | null>(null);
  const [itemId, setItemId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filter, setFilter] = useState<AreaTypeFilterEnum>(
    AreaTypeFilterEnum.ALL_AREA,
  );

  const { venueIdParam } = useParams<param>();

  const {
    data = [],
    isLoading: itemListLoading,
    mutate: getCCAdminItemList,
  } = useGetCCAdminItemList();

  const {
    data: BusinessOwnerItem = [],
    isLoading: BusinessOwnerItemLoading,
    mutate: getBusinessOwnerItemList,
  } = useGetBusinessOwnerItemList();

  const {
    data: adminArchive,
    mutate: putAdminArchive,
    isLoading: adminArchiveLoading,
    error: adminArchiveError,
  } = useAdminArchive();

  const {
    data: businessOwnerArchive,
    mutate: putBusinessOwnerArchive,
    isLoading: businessOwnerArchiveLoading,
    error: businessOwnerArchiveError,
  } = useBusinessOwnerArchive();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filterProducts = (item: ItemsI, filter: string, searchTerm: string) => {
    const searchTermLower = searchTerm.toLowerCase();
    const nameMatch =
      item.name &&
      item.name.en &&
      item.name.en.toLowerCase().includes(searchTermLower);
    const categoryMatch =
      item.category && item.category.toLowerCase().includes(searchTermLower);
    const codeMatch =
      item.colorCode &&
      item.colorCode.name &&
      item.colorCode.name.toLowerCase().includes(searchTermLower);

    switch (filter) {
      case 'BUILDING':
        return (
          (nameMatch || codeMatch || categoryMatch) && item.area === 'BUILDING'
        );
      case 'KITCHEN':
        return (
          (nameMatch || codeMatch || categoryMatch) && item.area === 'KITCHEN'
        );
      case 'SANITARY':
        return (
          (nameMatch || codeMatch || categoryMatch) && item.area === 'SANITARY'
        );
      default:
        return nameMatch || codeMatch || categoryMatch;
    }
  };

  const filteredData = (data: ItemsI[]) => {
    return data?.filter((item) => filterProducts(item, filter, searchTerm));
  };

  const handleEdit = (id: string) => {
    if (currentUser && isBusinessOwner(currentUser?.role.id)) {
      navigate(`/items/edit-item/${venueId}/${id}`);
    } else {
      navigate(`/items/edit-item/${id}`);
    }
  };

  const handleDuplicateEdit = (id: string) => {
    if (currentUser && isBusinessOwner(currentUser?.role.id)) {
      navigate(`/items/duplicate-item/${venueId}/${id}`);
    } else {
      navigate(`/items/duplicate-item/${id}`);
    }
  };

  const columns: TableColumnsType<ItemsI> = [
    {
      title: (
        <Flex display="flex" alignItems="center">
          Item Name <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'name',
      width: 300,
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.name.en, b.name.en),
      },
      render: (value, data) => (
        <Flex display="flex">
          <PreviewImage width={32} src={data.icon} />

          <Text
            type="subheading-regular"
            color={theme.gray800}
            ml="6px"
            mt="6px">
            {value.en}
          </Text>
        </Flex>
      ),
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Status <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'status',
      width: 150,
      render: (value) => getStatusTag(value),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.status, b.status),
      },
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Code <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'colorCode',
      width: 220,
      render: (value) =>
        value.name ? (
          value.name.length > 23 ? (
            <Tooltip title={value.name}>
              {getCodeTag(addDotsForLongText(value.name, 23), value.code)}
            </Tooltip>
          ) : (
            getCodeTag(value.name, value.code)
          )
        ) : (
          '-'
        ),
      sorter: {
        compare: (a, b) =>
          tableSorter.defaultSort(a.colorCode.name, b.colorCode.name),
      },
    },
    ...(currentUser && isBusinessOwner(currentUser?.role.id)
      ? [
          {
            title: (
              <Flex display="flex" alignItems="center">
                Manage By <Sort style={{ marginLeft: '8px' }} />
              </Flex>
            ),
            dataIndex: 'type',
            width: 150,
            render: (record: ManageByEnum) => {
              return <>{record ? getManageByTag(record) : '-'}</>;
            },
            sorter: {
              compare: (a: ItemsI, b: ItemsI) =>
                tableSorter.defaultSort(a.type, b.type),
            },
          },
        ]
      : []),
    {
      title: (
        <Flex display="flex" alignItems="center">
          Category <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'category',
      width: 150,
      render: (value) => (value ? getPascalCaseUtil(value) : '-'),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.category, b.category),
      },
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Area Type <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'area',
      width: 150,
      render: (value) => (value ? getPascalCaseUtil(value) : '-'),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.area, b.area),
      },
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Schedule <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'schedule',
      width: 150,
      render: (value) => (
        <Tooltip
          title={
            <span style={{ color: theme.black }}>
              {getPascalCaseUtil(value.toString().split(',').join(', '))}
            </span>
          }
          color={theme.white}>
          {}
          {addDotsForLongText(
            getPascalCaseUtil(value.toString().split(',').join(', ')),
            14,
          )}
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.schedule, b.schedule),
      },
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Action
        </Flex>
      ),
      dataIndex: 'id',
      width: 110,
      fixed: 'right',
      render: (value, data: ItemsI) => (
        <>
          <Dropdown
            dropdownRender={() => (
              <Menu>
                {currentUser &&
                isBusinessOwner(currentUser?.role.id) &&
                data.type === ManageByEnum.CUSTOM &&
                data.status === StatusEnum.ACTIVE ? (
                  <MenuItem key="edit" onClick={() => handleEdit(data.id)}>
                    Edit
                  </MenuItem>
                ) : (
                  currentUser &&
                  isSuperAdmin(currentUser?.role.id) &&
                  data.status === StatusEnum.ACTIVE && (
                    <MenuItem key="edit" onClick={() => handleEdit(data.id)}>
                      Edit
                    </MenuItem>
                  )
                )}
                {data.status === StatusEnum.ACTIVE && (
                  <MenuItem
                    key="duplicate_&_edit"
                    onClick={() => handleDuplicateEdit(data.id)}>
                    Duplicate & Edit
                  </MenuItem>
                )}
                {data.status === StatusEnum.ACTIVE ? (
                  <MenuItem
                    key="archive"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setItemId(value);
                    }}>
                    Archive
                  </MenuItem>
                ) : (
                  <MenuItem
                    key="archive"
                    onClick={() => {
                      if (currentUser && isBusinessOwner(currentUser.role.id)) {
                        putBusinessOwnerArchive(value);
                      } else {
                        putAdminArchive(value);
                      }
                    }}>
                    Active
                  </MenuItem>
                )}
              </Menu>
            )}>
            <img src={MenuThreeDots} />
          </Dropdown>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (currentUser && isSuperAdmin(currentUser.role.id)) {
      getCCAdminItemList();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && isBusinessOwner(currentUser.role.id) && venueId) {
      getBusinessOwnerItemList(venueId);
    }
  }, [currentUser, venueId]);

  useEffect(() => {
    adminArchiveError && Message.error(adminArchiveError.msg);
    businessOwnerArchiveError && Message.error(businessOwnerArchiveError.msg);
  }, [adminArchiveError, businessOwnerArchiveError]);

  useEffect(() => {
    if (adminArchive && !adminArchiveError) {
      Message.success(adminArchive);
      getCCAdminItemList();
    }
  }, [adminArchive]);

  useEffect(() => {
    if (businessOwnerArchive && !businessOwnerArchiveError && venueId) {
      Message.success(businessOwnerArchive);
      getBusinessOwnerItemList(venueId);
    }
  }, [businessOwnerArchive]);

  return (
    <Spin
      spinning={
        itemListLoading ||
        isLoading ||
        BusinessOwnerItemLoading ||
        adminArchiveLoading ||
        businessOwnerArchiveLoading
      }>
      {currentUser &&
        isBusinessOwner(currentUser?.role.id) &&
        venueId === null && (
          <Flex mb="16px">
            <AlertMessage
              text="You haven't added any venues yet. Please add a venue to add cleaning items here."
              textColor={theme.black}
            />
          </Flex>
        )}
      <MainCard>
        <Flex m="0px 24px 16px">
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <H type="title3Bold">Items</H>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card>
                <SearchDiv>
                  <SearchInput
                    size="small"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e)}
                  />
                </SearchDiv>

                {(currentUser?.role.id == UserRoleIdEnum.BUSINESS_OWNER ||
                  currentUser?.role.id == UserRoleIdEnum.ADMIN) && (
                  <VenuesSelector
                    defaultVenueId={venueIdParam}
                    onChange={(value: string | null) => setVenueId(value)}
                  />
                )}
                <Flex display="flex">
                  <AreaSelector
                    onChange={(value: AreaTypeFilterEnum) => {
                      setFilter(value);
                    }}
                  />
                  <PrimaryButton
                    onClick={() => navigate('/items/add-items')}
                    size="middle"
                    icon={
                      currentUser &&
                      isBusinessOwner(currentUser?.role.id) &&
                      venueId === null ? (
                        <DisabledPlusCircle />
                      ) : (
                        <CirclePlus />
                      )
                    }
                    disabled={
                      currentUser &&
                      isBusinessOwner(currentUser?.role.id) &&
                      venueId === null
                        ? true
                        : false
                    }>
                    Add Item
                  </PrimaryButton>
                </Flex>
              </Card>
            </Col>
          </Row>
        </Flex>

        <Flex pb="20px">
          <TableComponent
            loading={isLoading}
            columns={columns}
            dataSource={
              currentUser && isBusinessOwner(currentUser?.role.id)
                ? filteredData(BusinessOwnerItem)
                : filteredData(data)
            }
            scroll={{
              x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
              y: window.innerHeight - 200,
            }}
          />
        </Flex>
        {showDeleteModal && itemId !== null && (
          <DeleteModal
            visible={showDeleteModal}
            onClickCancel={() => {
              if (currentUser && isBusinessOwner(currentUser?.role.id)) {
                putBusinessOwnerArchive(itemId);
              } else {
                putAdminArchive(itemId);
              }
            }}
            content={`Are you sure you want to Archive?`}
            title="Confirm Archive"
            handleCancel={() => {
              setShowDeleteModal(false);
              setItemId(itemId);
            }}
            buttonLabel="Remove"
          />
        )}
      </MainCard>
    </Spin>
  );
};

export default ItemList;
