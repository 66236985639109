import styled from '@emotion/styled';
import { Form, Spin } from 'antd';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useResetPassword,
  useResetTempPassword,
  useValidateAuthToken,
} from '../../api/authHooks';
import {
  Flex,
  FormContainer,
  H,
  PasswordInput,
  PrimaryButton,
  Text,
} from '../../components';
import { Message } from '../../components/message/Message';
import { useAuth } from '../../providers/auth-context';
import theme from '../../theme';
import { ResetPasswordI } from '../../types/auth.types';
import { checkPasswordStrengthWarningMsg } from '../../utils/checkPasswordStrengthWarningMsgUtil';
import AuthLayout from './shared/AuthLayout';
import { useDeleteAccount } from '../../api/userHooks';
import { DeleteAccountI } from '../../types/user.types';

interface ResetPasswordFormI {
  password: string;
  confirmPassword: string;
}

interface Props {
  isDeleteAccount?: boolean;
}

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;
const ResetPassword: FC<Props> = ({ isDeleteAccount }) => {
  const [form] = Form.useForm();
  const { token, tempPassword } = useParams();
  const { setToken, setPermission } = useAuth();
  const navigate = useNavigate();
  const { mutate: deleteAccount, data: deleteAccountData } = useDeleteAccount();

  const {
    mutate: validateAuthToken,
    data: validatedAuthToken,
    isLoading: validateAuthTokenLoading,
    error: validateAuthTokenError,
  } = useValidateAuthToken();

  const {
    mutate: postResetPassword,
    data: resetPasswordData,
    isError: isErrorResetPassword,
  } = useResetPassword();

  const {
    mutate: postResetTempPassword,
    data: resetTempPassword,
    isError: isErrorResetTempPassword,
  } = useResetTempPassword();

  useEffect(() => {
    if (!isDeleteAccount) {
      if (token) {
        validateAuthToken({ token: token });
      } else {
        navigate('/404');
      }
    }
  }, [token]);

  useEffect(() => {
    if (validateAuthTokenError) {
      Message.error(validateAuthTokenError?.msg);
    }
  }, [validateAuthTokenError]);

  useEffect(() => {
    if (resetPasswordData && !isErrorResetPassword) {
      navigate('/login', {
        state: { message: 'Password changed successfully!' },
      });
    }
  }, [resetPasswordData]);

  useEffect(() => {
    if (deleteAccountData) {
      navigate('/login');
    }
  }, [deleteAccountData]);

  useEffect(() => {
    if (resetTempPassword && !isErrorResetTempPassword) {
      setToken(resetTempPassword.access_token);
      const { role, ...rest } = resetTempPassword;
      const permissionObject = {
        role: role,
        ...rest,
      };
      setPermission(permissionObject);
      navigate('/', {
        state: { message: 'Password is set successfully!' },
      });
    }
  }, [resetTempPassword]);

  useEffect(() => {
    if (!isDeleteAccount) {
      if (token && validatedAuthToken && validatedAuthToken.expired) {
        navigate(`/404/${token}`);
      } else if (token && validatedAuthToken && !validatedAuthToken.token) {
        navigate('/404');
      }
    }
  }, [validatedAuthToken]);

  const resetPassword = (formValues: ResetPasswordFormI) => {
    const resetPasswordData: ResetPasswordI = {
      password: formValues.password,
      resetToken: validatedAuthToken?.token,
    };
    if (tempPassword) {
      postResetTempPassword(resetPasswordData);
    } else {
      postResetPassword(resetPasswordData);
    }
  };

  return (
    <>
      {token && (
        <AuthLayout>
          <Spin spinning={validateAuthTokenLoading}>
            {(validatedAuthToken || isDeleteAccount) && (
              <div>
                <Flex width={'358px'}>
                  <H type="largeTitle" color={theme.brandDarkTeal} mb="16px">
                    {isDeleteAccount ? 'Delete Account' : 'Reset Password'}
                  </H>
                  {isDeleteAccount ? (
                    <Text
                      type="subheading-regular"
                      color={theme.gray800}
                      mb="40px">
                      Enter your password, to confirm delete your account.
                    </Text>
                  ) : (
                    <Text
                      type="subheading-regular"
                      color={theme.gray800}
                      mb="40px">
                      Create a new password to log in and access your
                      <br /> account.
                    </Text>
                  )}
                </Flex>
                <FormContainer
                  form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    if (isDeleteAccount) {
                      const deleteAccountFormData: DeleteAccountI = {
                        token: token,
                      };
                      deleteAccount(deleteAccountFormData);
                    } else {
                      resetPassword(values);
                    }
                  }}>
                  <Form.Item
                    label={isDeleteAccount ? 'Password' : 'New Password'}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: `${isDeleteAccount ? 'Password is required' : ' '}`,
                      },
                      () => ({
                        validator(_, value, callback) {
                          try {
                            if (!isDeleteAccount) {
                              checkPasswordStrengthWarningMsg(value);
                            }
                            callback();
                          } catch (error) {
                            if (error instanceof Error) {
                              callback(' ');
                            }
                          }
                        },
                      }),
                    ]}>
                    <PasswordInput
                      placeholder="Enter password"
                      textMarginTop="16px"
                      showPasswordPolicy={isDeleteAccount ? false : true}
                    />
                  </Form.Item>
                  {!isDeleteAccount && (
                    <FormItem
                      label="Confirm New Password"
                      name="confirmPassword"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Enter your password again',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value, callback) {
                            if (!value || getFieldValue('password') === value) {
                              return callback();
                            }
                            return callback(
                              'The confirm password that you entered do not match',
                            );
                          },
                        }),
                      ]}>
                      <PasswordInput
                        placeholder="Enter password"
                        showPasswordPolicy={false}
                      />
                    </FormItem>
                  )}

                  <Form.Item>
                    <PrimaryButton
                      block
                      mt="40px"
                      onClick={() => form.submit()}>
                      {isDeleteAccount
                        ? 'Confirm Delete'
                        : 'Create New Password'}
                    </PrimaryButton>
                  </Form.Item>
                </FormContainer>
              </div>
            )}
          </Spin>
        </AuthLayout>
      )}
    </>
  );
};

export default ResetPassword;
