export type PasswordStatusType = {
  minCharacters: boolean;
  upperCaseCharacter: boolean;
  lowerCaseCharacter: boolean;
  numericCharacter: boolean;
  specialCharacter: boolean;
};

export const getPasswordStatus = (password: string): PasswordStatusType => {
  const status: PasswordStatusType = {
    minCharacters: false,
    upperCaseCharacter: false,
    lowerCaseCharacter: false,
    numericCharacter: false,
    specialCharacter: false,
  };
  if (password.length >= 8) {
    status.minCharacters = true;
  }
  if (/[A-Z]/.test(password)) {
    status.upperCaseCharacter = true;
  }
  if (/[a-z]/.test(password)) {
    status.lowerCaseCharacter = true;
  }
  if (/\d/.test(password)) {
    status.numericCharacter = true;
  }
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    status.specialCharacter = true;
  }
  return status;
};
