/* eslint-disable no-console */
import { UpOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Checkbox, Col, Collapse, Form, Row, Select, Spin, Upload } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { useGetCategories } from '../../api/categoryHooks';
import { useGetCodes } from '../../api/codeHook';
import {
  useAdminGetItem,
  useBusinessOwnerGetItem,
  useCreateItem,
  useCreateOwnerItem,
  useUpdateBusinessOwnerItem,
  useUpdateItem,
} from '../../api/itemHooks';
import {
  FileUpload,
  Nederlanden,
  UnitedStates,
  emptyImage,
} from '../../assets';
import {
  AlertMessage,
  CheckBoxComp,
  Flex,
  FormContainer,
  H,
  Image,
  Input,
  PrimaryButton,
  SecondaryButton,
  SelectComp,
  Text,
} from '../../components';
import { Message } from '../../components/message/Message';
import { getCodeTag } from '../../components/tags/CodeTag';
import { useAuth } from '../../providers/auth-context';
import theme from '../../theme';
import {
  AddItemsScheduleI,
  AreaTypeEnum,
  HandleContentChange,
  ItemsI,
  PostItemsI,
  ScheduleContentKeys,
  ScheduleDetailsI,
  ScheduleEnum,
} from '../../types/equipmentsItems.types';
import { UserRoleIdEnum } from '../../types/user.types';
import { getPascalCaseUtil } from '../../utils/caseUtil';
import { isBusinessOwner, isSuperAdmin } from '../../utils/userRoleUtil';
import EmptySchedule from './EmptySchedule';
import { ScheduleLayout } from './ScheduleLayout';
import {
  scrollToFirstErrorField,
  getHeight,
  getMediaHeight,
  isFormDirty,
} from '../../utils/utils';
import { BlockRouteLeaveModal } from '../../components/modals/BlockRouteLeaveModal';
import { useGetVenues } from '../../api/venueHooks';

type param = {
  venueId: string;
  id: string;
  duplicateId: string;
};

const CusTopPanel = styled(Flex)`
  border: 1px solid ${theme.gray400};
  border-radius: 8px;
  background: ${theme.white};
  padding: 12px;
  margin-bottom: 16px;
  height: 82px;
  display: flex;
  align-items: center;
`;

const CusSidePanel = styled(Flex)`
  border: 1px solid ${theme.gray400};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${theme.white};
  margin-right: 16px;
  padding: 24px;
  height: 700px;

  @media (max-width: 820px) {
    margin-right: 8px !important;
    margin-bottom: 8px;
  }

  @media (max-width: 1023px) {
    margin-right: 8px !important;
    margin-bottom: 8px;
  }
`;

const Typography = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
`;

const UploadIcon = styled(Image)`
  border-radius: 8px;
  width: 80px;
  height: 80px;
`;

const StyledPanelHeading = styled(H)`
  margin-left: 12px;
`;

const Footer = styled(Flex)`
  position: fixed;
  width: 100%;
  background: ${theme.white};
  display: flex;
  flex-direction: row-reverse;
  height: 72px;
  bottom: 0px;
  z-index: 1;
  padding: 16px 24px;
  margin-left: -24px;
  box-shadow: 0px 0px 8px ${theme.gray400};
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    height: 636px !important;
    overflow-y: scroll;
    margin-bottom: 16px !important;
  }
`;

interface BodyProps {
  selectedCheckboxes: number;
}

const StyledBody = styled(Flex)<BodyProps>`
  height: ${(props) => getHeight(props.selectedCheckboxes)} !important;

  @media (max-width: 1100px) {
    height: ${(props) =>
      getMediaHeight(props.selectedCheckboxes, 1100)} !important;
  }
  @media (max-width: 995px) {
    height: ${(props) =>
      getMediaHeight(props.selectedCheckboxes, 995)} !important;
  }
  @media (max-width: 480px) {
    height: ${(props) =>
      getMediaHeight(props.selectedCheckboxes, 480)} !important;
  }
`;

const getItems: (
  panelStyle: CSSProperties,
  selectedCheckboxes: string[],
  handleContentChange: (v: HandleContentChange) => void,
  editingItem?: ItemsI,
) => CollapseProps['items'] = (
  panelStyle,
  selectedCheckboxes,
  handleContentChange,
  editingItem,
) => {
  const itemLabels = [
    ScheduleEnum.DAILY,
    ScheduleEnum.MONTHLY,
    ScheduleEnum.ANNUALLY,
    ScheduleEnum.WEEKLY,
  ];
  const items: CollapseProps['items'] = [];
  itemLabels.forEach((label) => {
    if (selectedCheckboxes.includes(label)) {
      const initialContent = editingItem?.scheduleDetails.find(
        (s) => s.schedule === label,
      );

      items.push({
        key: label,
        label: (
          <StyledPanelHeading type="headlineBold">
            {getPascalCaseUtil(label)}
          </StyledPanelHeading>
        ),
        children: (
          <ScheduleLayout
            handleContentChange={handleContentChange}
            initialValue={initialContent}
            label={label}
          />
        ),
        style: panelStyle,
      });
    }
  });

  return items;
};
const AddItemsMainLayout: FC = () => {
  const initialState: ScheduleDetailsI = {
    schedule: '',
    title: {
      en: '',
      nl: '',
    },
    cleaningInstructions: {
      en: '',
      nl: '',
    },
    cleaningProducts: [],
    precautions: {
      en: '',
      nl: '',
    },
    tips: {
      en: '',
      nl: '',
    },
  };

  const [form] = Form.useForm();
  const { currentUser, isLoading } = useAuth();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [activeKey, setActiveKey] = useState<string | string[]>('');
  const [uploadIcon, setUploadIcon] = useState<File | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingItem, setEditingItem] = useState<ItemsI>();
  const [dirtyForm, setDirtyForm] = useState<boolean>(false);

  const [dailyContent, setDailyContent] =
    useState<ScheduleDetailsI>(initialState);
  const [monthlyContent, setMonthlyContent] =
    useState<ScheduleDetailsI>(initialState);
  const [weeklyContent, setWeeklyContent] =
    useState<ScheduleDetailsI>(initialState);
  const [annuallyContent, setAnnuallyContent] =
    useState<ScheduleDetailsI>(initialState);

  const navigate = useNavigate();

  const { id, duplicateId, venueId } = useParams<param>();
  const { data = [] } = useGetVenues();
  const {
    data: adminItemData,
    isLoading: isLoadingItemData,
    mutate: mutateAdminItemData,
  } = useAdminGetItem();

  const {
    data: businessOwnerItemData,
    isLoading: isLoadingBusinessOwnerItemData,
    mutate: mutateBusinessOwnerItemData,
  } = useBusinessOwnerGetItem();

  const {
    mutate: content,
    isLoading: contentLoading,
    isSuccess: isSuccessContent,
    error: isContentError,
  } = useCreateItem();

  const {
    mutate: createOwnerItem,
    isLoading: createOwnerItemLoading,
    isSuccess: isSuccessCreateOwnerItem,
    error: isCreateOwnerItemError,
  } = useCreateOwnerItem();

  const {
    mutate: updateContent,
    isLoading: updateContentLoading,
    isSuccess: isSuccessUpdateContent,
    error: isUpdateContentError,
  } = useUpdateItem();

  const {
    mutate: updateBusinessOwnerItem,
    isLoading: updateBusinessOwnerItemLoading,
    isSuccess: isSuccessBusinessOwnerItem,
    error: isUpdateBusinessOwnerItem,
  } = useUpdateBusinessOwnerItem();

  const panelStyle: React.CSSProperties = {
    marginBottom: selectedCheckboxes.length > 1 ? 16 : 0,
    background: `${theme.white}`,
    borderRadius: '8px',
    border: `1px solid ${theme.gray400}`,
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }): boolean =>
      dirtyForm &&
      currentLocation.pathname !== nextLocation.pathname &&
      nextLocation.pathname !== '/login',
  );

  useEffect(() => {
    if (
      isSuccessContent ||
      isSuccessUpdateContent ||
      isSuccessCreateOwnerItem ||
      isSuccessBusinessOwnerItem
    ) {
      setDirtyForm(false);
      navigate(venueId ? `/items/${venueId}` : '/items');
    }
  }, [
    isSuccessContent,
    isSuccessUpdateContent,
    isSuccessCreateOwnerItem,
    isSuccessBusinessOwnerItem,
  ]);

  useEffect(() => {
    isUpdateContentError && Message.error(isUpdateContentError.msg);
    isContentError && Message.error(isContentError.msg);
    isCreateOwnerItemError && Message.error(isCreateOwnerItemError.msg);
    isUpdateBusinessOwnerItem && Message.error(isUpdateBusinessOwnerItem.msg);
  }, [
    isUpdateContentError,
    isContentError,
    isCreateOwnerItemError,
    isUpdateBusinessOwnerItem,
  ]);

  useEffect(() => {
    if (id) {
      setIsEditing(true);
      if (isSuperAdmin(currentUser?.role.id as UserRoleIdEnum)) {
        mutateAdminItemData(id);
      } else if (isBusinessOwner(currentUser?.role.id as UserRoleIdEnum)) {
        mutateBusinessOwnerItemData(id);
      }
    } else if (duplicateId) {
      setIsEditing(false);
      if (isSuperAdmin(currentUser?.role.id as UserRoleIdEnum)) {
        mutateAdminItemData(duplicateId);
      } else if (isBusinessOwner(currentUser?.role.id as UserRoleIdEnum)) {
        mutateBusinessOwnerItemData(duplicateId);
      }
    } else {
      setIsEditing(false);
      setEditingItem(undefined);
    }
  }, [id, currentUser]);

  useEffect(() => {
    const source = isSuperAdmin(currentUser?.role.id as UserRoleIdEnum)
      ? adminItemData
      : businessOwnerItemData;

    setEditingItem(source);

    if (duplicateId) {
      form.setFieldsValue({
        titleEn: undefined,
        titleNl: undefined,
        category: source?.category,
        code: source?.colorCode.id,
        areaType: source?.area,
        schedule: source?.schedule,
        venueId: venueId,
      });
    } else {
      form.setFieldsValue({
        titleEn: source?.name.en,
        titleNl: source?.name.nl,
        category: source?.category,
        code: source?.colorCode.id,
        areaType: source?.area,
        schedule: source?.schedule,
        venueId: venueId,
      });
    }

    //FIXME Please remove this. Not the best approach. send the id array from BE instead of the objects and load them from FE .
    const processScheduleDetails = (input: ScheduleDetailsI) => {
      return {
        ...input,
        cleaningProducts: input?.cleaningProducts?.map((product) =>
          typeof product === 'object' && 'id' in product ? product.id : product,
        ) as string[],
      };
    };
    source?.scheduleDetails.forEach((item) => {
      const setContent = {
        [ScheduleEnum.DAILY]: setDailyContent,
        [ScheduleEnum.WEEKLY]: setWeeklyContent,
        [ScheduleEnum.MONTHLY]: setMonthlyContent,
        [ScheduleEnum.ANNUALLY]: setAnnuallyContent,
      }[item.schedule];

      if (setContent) {
        setContent(processScheduleDetails(item));
      } else {
        console.error('Invalid schedule type');
      }
    });

    handleCheckboxChange(source?.schedule.map((schedule) => schedule) || []);
  }, [adminItemData, businessOwnerItemData, currentUser]);

  const handleCheckboxChange = (checkedValues: string[]) => {
    setSelectedCheckboxes(checkedValues);
    if (checkedValues.length > 0) {
      setActiveKey(checkedValues[0]);
    } else {
      setActiveKey('');
    }
  };

  const { data: categories, refetch: refetchCategories } = useGetCategories();
  const { data: colorCodes, refetch: refetchColorCodes } = useGetCodes();

  const handleReturnValue = (
    data: HandleContentChange,
    prevState: ScheduleDetailsI,
  ) => {
    const updatedTitle = data?.title;
    const updatedLanguage = data?.language;
    if (data.title && data.language && !data.cleaningProducts)
      return {
        ...prevState,
        schedule: data.schedule || prevState.schedule,
        [updatedTitle as string]: {
          ...prevState[data.title as ScheduleContentKeys],
          [updatedLanguage as string]: data.content,
        },
      };
    else
      return {
        ...prevState,
        schedule: data.schedule || prevState.schedule,
        cleaningProducts: data.cleaningProducts || prevState.cleaningProducts,
      };
  };

  const handleContentChange = (data: HandleContentChange) => {
    setDirtyForm(isFormDirty(data));
    switch (data.schedule) {
      case ScheduleEnum.DAILY:
        setDailyContent((prevState: ScheduleDetailsI) =>
          handleReturnValue(data, prevState),
        );
        break;
      case ScheduleEnum.WEEKLY:
        setWeeklyContent((prevState) => handleReturnValue(data, prevState));
        break;
      case ScheduleEnum.MONTHLY:
        setMonthlyContent((prevState) => handleReturnValue(data, prevState));
        break;
      case ScheduleEnum.ANNUALLY:
        setAnnuallyContent((prevState) => handleReturnValue(data, prevState));
        break;
      default:
        console.error('Invalid schedule type');
    }
  };

  const onFinish = (formValues: AddItemsScheduleI) => {
    if (formValues.titleEn && formValues.titleNl) {
      const scheduleArray: ScheduleDetailsI[] = [];
      formValues?.schedule?.forEach((schedule) => {
        if (schedule === ScheduleEnum.ANNUALLY) {
          scheduleArray.push(annuallyContent);
        }
        if (schedule === ScheduleEnum.MONTHLY) {
          scheduleArray.push(monthlyContent);
        }
        if (schedule === ScheduleEnum.WEEKLY) {
          scheduleArray.push(weeklyContent);
        }
        if (schedule === ScheduleEnum.DAILY) {
          scheduleArray.push(dailyContent);
        }
      });
      const isTitleEmpty = scheduleArray.some((scheduleDetail) => {
        if (scheduleDetail.title.en === '' || scheduleDetail.title.nl === '') {
          Message.error('Task title should not be empty.');
          return true;
        } else {
          return false;
        }
      });
      const data: PostItemsI = {
        name: {
          en: formValues.titleEn,
          nl: formValues.titleNl,
        },
        category: formValues.category,
        area: formValues.areaType,
        colorCode: formValues.code,
        scheduleDetails: scheduleArray,
        icon: uploadIcon,
        schedule: formValues.schedule,
        venueId: formValues.venueId,
      };

      if (!isTitleEmpty) {
        if (currentUser && isSuperAdmin(currentUser?.role.id)) {
          isEditing ? updateContent({ id: id, ...data }) : content(data);
        } else {
          isEditing
            ? updateBusinessOwnerItem({ id: id, ...data, venueId: venueId })
            : createOwnerItem(data);
        }
      }
    }
  };

  useEffect(() => {
    refetchCategories();
    refetchColorCodes();
  }, []);

  const beforeUpload = (file: File) => {
    const isSvg = file.type === 'image/svg+xml';
    if (!isSvg) {
      Message.error('You can only upload SVG files!');
    }

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      Message.error('Image must be smaller than 5MB!');
    }

    if (isSvg && isLt5M) {
      setUploadIcon(file);
    }
    return isSvg && isLt5M;
  };

  const checkForFormDirty = (values: { [key: string]: string }) => {
    if (isEditing) {
      const originalData = editingItem;

      if (originalData) {
        for (const key in values) {
          const currentValue = values[key];
          let originalValue = originalData[key as keyof ItemsI];

          if (key === 'titleEn') {
            originalValue = originalData.name.en;
          } else if (key === 'titleNl') {
            originalValue = originalData.name.nl;
          }
          if (originalValue === currentValue && !isFormDirty) {
            setDirtyForm(false);
          } else if (originalValue !== currentValue) {
            setDirtyForm(true);
          }
        }
      }
    } else {
      const formValues = form.getFieldsValue();
      const isFormDirty = Object.values(formValues).some((value) => {
        return value !== '' && value !== null && value !== undefined;
      });
      setDirtyForm(isFormDirty);
    }
  };

  return (
    <>
      <Spin
        spinning={
          isLoading || isLoadingItemData || isLoadingBusinessOwnerItemData
        }>
        {duplicateId && (
          <Flex mb="16px">
            <AlertMessage
              text="Please use a different icon for duplicate items to distinguish them from other items."
              textColor={theme.black}
            />
          </Flex>
        )}
        <StyledBody selectedCheckboxes={selectedCheckboxes.length}>
          <FormContainer
            layout="vertical"
            form={form}
            validateTrigger="onBlur"
            onValuesChange={checkForFormDirty}
            onFinishFailed={scrollToFirstErrorField}
            onFinish={(v) => onFinish(v)}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <CusTopPanel>
                  <H type="headlineBold">Item Name:</H>
                  <Flex m="20px 20px 0px">
                    <Form.Item
                      name="titleEn"
                      rules={[
                        {
                          required: true,
                          message: 'Enter item english name',
                        },
                      ]}>
                      <Input
                        placeholder="Enter item english name"
                        prefix={
                          <Image src={UnitedStates} borderRadius={'24px'} />
                        }
                      />
                    </Form.Item>
                  </Flex>
                  <Flex m="20px 20px 0px">
                    <Form.Item
                      name="titleNl"
                      rules={[
                        {
                          required: true,
                          message: 'Enter item dutch name',
                        },
                      ]}>
                      <Input
                        placeholder="Enter item dutch name"
                        prefix={
                          <Image src={Nederlanden} borderRadius={'24px'} />
                        }
                      />
                    </Form.Item>
                  </Flex>
                </CusTopPanel>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
                <CusSidePanel>
                  <Row gutter={[16, 16]}>
                    <Col span={4}>
                      <UploadIcon
                        src={
                          uploadIcon
                            ? URL.createObjectURL(uploadIcon)
                            : isEditing && adminItemData?.icon
                              ? adminItemData.icon
                              : isEditing && businessOwnerItemData?.icon
                                ? businessOwnerItemData?.icon
                                : emptyImage
                        }
                      />
                    </Col>

                    <Col span={20}>
                      <Flex mb={'48px'} ml="42px">
                        <Upload
                          action={''}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          accept="image/svg+xml">
                          <Flex display="flex" flexDirection="row" mt="24px">
                            <Image src={FileUpload} cursor="pointer" />
                            <Typography
                              cursor="pointer"
                              ml="8px"
                              type={'subheading-bold'}
                              color={theme.brandDarkTeal}>
                              Upload Icon
                            </Typography>
                          </Flex>
                        </Upload>

                        <Text type={'subheading-regular'} color={theme.gray800}>
                          Supported file type: (SVG)
                        </Text>
                      </Flex>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Category *"
                        name="category"
                        rules={[
                          {
                            required: true,
                            message: 'Select category',
                          },
                        ]}>
                        <SelectComp placeholder="Select category">
                          {categories &&
                            categories.map((category) => (
                              <Select.Option
                                key={category.id}
                                value={category.id}>
                                {getPascalCaseUtil(category.name)}
                              </Select.Option>
                            ))}
                        </SelectComp>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Code *"
                        name="code"
                        rules={[
                          {
                            required: true,
                            message: 'Select code',
                          },
                        ]}>
                        <SelectComp placeholder="Select code">
                          {colorCodes &&
                            colorCodes.map((code) => (
                              <Select.Option key={code.id} value={code.id}>
                                {getCodeTag(code.codeName, code.color)}
                              </Select.Option>
                            ))}
                        </SelectComp>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Area Type *"
                        name="areaType"
                        rules={[
                          {
                            required: true,
                            message: 'Select area type',
                          },
                        ]}>
                        <SelectComp placeholder="Select location">
                          {Object.values(AreaTypeEnum).map((area) => (
                            <Select.Option key={area} value={area}>
                              {getPascalCaseUtil(area)}
                            </Select.Option>
                          ))}
                        </SelectComp>
                      </Form.Item>
                    </Col>
                  </Row>
                  {currentUser && isBusinessOwner(currentUser?.role.id) && (
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Venue *"
                          name="venueId"
                          rules={[
                            {
                              required: true,
                              message: 'Select Venue',
                            },
                          ]}>
                          <SelectComp
                            placeholder="Select Venue"
                            disabled={venueId !== undefined}>
                            {data &&
                              data.map((venue) => (
                                <Select.Option key={venue.id} value={venue.id}>
                                  {venue.name}
                                </Select.Option>
                              ))}
                          </SelectComp>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Schedule *"
                        name="schedule"
                        rules={[
                          {
                            required: true,
                            message: 'Select schedule',
                          },
                        ]}>
                        <Checkbox.Group
                          style={{ width: '100%' }}
                          onChange={handleCheckboxChange}>
                          <Row>
                            <Col span={12}>
                              <Flex marginBottom={'24px'}>
                                <CheckBoxComp value={ScheduleEnum.DAILY}>
                                  Daily
                                </CheckBoxComp>
                              </Flex>
                            </Col>
                            <Col span={12}>
                              <Flex marginBottom={'24px'}>
                                <CheckBoxComp value={ScheduleEnum.MONTHLY}>
                                  Monthly
                                </CheckBoxComp>
                              </Flex>
                            </Col>
                            <Col span={12}>
                              <Flex marginBottom={'24px'}>
                                <CheckBoxComp value={ScheduleEnum.WEEKLY}>
                                  Weekly
                                </CheckBoxComp>
                              </Flex>
                            </Col>
                            <Col span={12}>
                              <Flex marginBottom={'24px'}>
                                <CheckBoxComp value={ScheduleEnum.ANNUALLY}>
                                  Annually
                                </CheckBoxComp>
                              </Flex>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </CusSidePanel>
              </Col>

              <Col xs={24} sm={24} md={24} lg={17} xl={17} xxl={17}>
                {selectedCheckboxes.length > 0 ? (
                  <Flex height={'600px'} paddingBottom={'250px'}>
                    <StyledCollapse
                      defaultActiveKey={activeKey}
                      accordion
                      expandIcon={({ isActive }) => (
                        <UpOutlined rotate={isActive ? 0 : 180} />
                      )}
                      expandIconPosition="end"
                      style={{
                        background: 'transparent',
                        border: `0.5px solid ${theme.gray200}`,
                      }}
                      items={getItems(
                        panelStyle,
                        selectedCheckboxes,
                        handleContentChange,
                        editingItem,
                      )}
                    />
                  </Flex>
                ) : (
                  <EmptySchedule />
                )}
              </Col>
            </Row>
          </FormContainer>
        </StyledBody>
      </Spin>
      <Footer>
        <PrimaryButton
          onClick={() => {
            setDirtyForm(false);
            form.submit();
          }}
          loading={
            contentLoading ||
            updateContentLoading ||
            createOwnerItemLoading ||
            updateBusinessOwnerItemLoading
          }>
          Save
        </PrimaryButton>
        <SecondaryButton
          mr={'24px'}
          onClick={() => {
            navigate(venueId ? `/items/${venueId}` : '/items');
          }}>
          Cancel
        </SecondaryButton>
      </Footer>
      {blocker.state === 'blocked' && (
        <BlockRouteLeaveModal
          modalVisible={blocker.state === 'blocked'}
          handleCancel={() => blocker.reset()}
          handleProceed={() => blocker.proceed()}
        />
      )}
    </>
  );
};
export default AddItemsMainLayout;
