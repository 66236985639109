import safe from 'safe-regex';

export const checkPasswordStrengthWarningMsg = (password: string) => {
  const regexText = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
  );
  if (!password) {
    return false;
  } else if (!safe(password)) {
    throw new Error('Invalid Password');
  } else if (!regexText.test(password) && password.length < 8) {
    throw new Error('Password should have a minimum of 8 characters');
  } else if (regexText.test(password) !== true && password.length >= 8) {
    throw new Error(
      `Password should have at least one letter, number and symbol`,
    );
  }

  return true;
};
