/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useEffect } from 'react';
import { Flex, H, Text, Image, PrimaryButton } from '../../components';
import { Col, Dropdown, Menu, Popover, Row, Spin } from 'antd';
import { SearchInput } from '../../components/inputs/SearchInput';
import type { TableColumnsType } from 'antd';
import { SecondaryButton } from '../../components';
import { LinkedButton } from '../../components/buttons/LinkedButton';
import { CirclePlus } from '../../assets';
import { GreenPlusSVG } from '../../assets';
import AddEditLabelsModal from './layouts/AddEditLabelsModal';
import { useDeleteLabel } from '../../api/labelsHooks';
import { usePostLabel } from '../../api/labelsHooks';
import TableComponent from '../../components/table/TableComponent';
import theme from '../../theme';
import { MenuThreeDots, Sort } from '../../assets';
import styled from '@emotion/styled';
import { useWindowSize } from '../../utils/screenSize';
import { tableSorter } from '../../utils/tableSorter';
import TranslationSelector from './utils/TranslationSelector';
import { useGetAllLabels, useEditLabel } from '../../api/labelsHooks';
import { ILabelModalData } from '../../types/TranslationTypes';
import { DeleteModal } from '../../components/modals/DeleteModal';
import { useEditNewLabel } from '../../api/labelsHooks';
import { Nederlanden } from '../../assets';
import { UnitedStates } from '../../assets';
import { UserMeResponseI } from '../../types/user.types';
import { useAuth } from '../../providers/auth-context';
import { Message } from '../../components/message/Message';
import { MainCard } from '../../components/MainCard';
import ThreeDotPopover from '../../components/ThreeDotPopover/ThreeDotPopover';

const MenuItem = styled(Menu.Item)`
  border-top: 0.5px solid ${theme.gray300};
  .ant-dropdown-menu-title-content {
    padding: 7px 54px 7px 4px;
    font-family: SF Pro Display;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
`;
const StyledImage = styled(Image)`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const HeaderButtonFlex = styled(Flex)`
  @media (max-width: 768px) {
    display: flex;
    margin-right: 0px !important;
    padding: 0px 12px 12px 12px;
  }
`;

const StyledSecondaryButton = styled(LinkedButton)`
  border: none;
`;

const ButtonText = styled.span`
  color: ${theme.brandTeal};
`;

interface ILabelModalDataExtended extends ILabelModalData {
  originalKey?: string | null;
}

const Label: FC = () => {
  const { isTablet, isDesktop, isDesktopLarge } = useWindowSize();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLabel, setSelectedLabel] =
    useState<ILabelModalDataExtended | null>(null);
  const [filter, setFilter] = useState('');

  const { currentUser: userData, isLoading: authLoading } = useAuth();

  const {
    mutate: deleteLabel,
    data: labelsForData,
    error: labelDeleteError,
    isLoading: isLoadingDeleteLabel,
  } = useDeleteLabel();

  const {
    mutate: editLabel,
    isSuccess: isSuccessEditLabel,
    isLoading: isLoadingEditLabel,
    error: editNewLabelError,
  } = useEditNewLabel();

  const {
    mutate: editLabelValues,
    isSuccess: isSuccessEditValueLabel,
    isLoading: isLoadingEditValue,
    error: editLabelError,
  } = useEditLabel();

  const handleEdit = (record: ILabelModalData) => {
    setSelectedLabel({ ...record, originalKey: record.key });
    setVisible(true);
  };

  const handleDelete = (record: ILabelModalData) => {
    setSelectedLabel({ ...record, originalKey: record.key });
    setVisibleDeleteModal(true);
  };

  const {
    mutate: postLabel,
    isLoading: postDataLoading,
    isSuccess: isSuccessPostLabel,
    data: postLabelResponse,
    error: isLabelPostError,
  } = usePostLabel();

  const {
    data: allLabelsData,
    isLoading: allLabelsDataIsLoading,
    refetch: refetchAllLabelData,
  } = useGetAllLabels();

  useEffect(() => {
    if (isLabelPostError) {
      isLabelPostError && Message.error(isLabelPostError?.msg);
    }
  }, [isLabelPostError]);

  useEffect(() => {
    if (labelDeleteError) {
      labelDeleteError && Message.error(labelDeleteError?.msg);
    }
  }, [labelDeleteError]);

  useEffect(() => {
    if (editNewLabelError) {
      editNewLabelError && Message.error(editNewLabelError?.msg);
    }
  }, [editNewLabelError]);

  useEffect(() => {
    if (editLabelError) {
      editLabelError && Message.error(editLabelError?.msg);
    }
  }, [editLabelError]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = allLabelsData?.filter((item) => {
    if (filter == 'Pending Translations') {
      return (
        !item.labelNl &&
        item.key && // Check if item.key exists
        item.key.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      return (
        item.key && // Check if item.key exists
        item.key.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  });

  useEffect(() => {
    if (
      labelsForData ||
      isSuccessPostLabel ||
      postLabelResponse ||
      isSuccessEditLabel ||
      isSuccessEditValueLabel
    ) {
      refetchAllLabelData();
    }
  }, [
    allLabelsData,
    labelsForData,
    isSuccessPostLabel,
    postLabelResponse,
    isSuccessEditLabel,
    isSuccessEditValueLabel,
  ]);

  const columns: TableColumnsType<ILabelModalData> = [
    {
      title: (
        <Flex display="flex" alignItems="center">
          Key <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'key',
      width: 150,
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.key, b.key),
      },
      render: (text: string) => <ThreeDotPopover text={text} maxLength={40} />,
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          <StyledImage src={UnitedStates} />
          US-Eng
        </Flex>
      ),
      dataIndex: 'labelEn',
      width: 150,
      render: (text, record) => {
        if (text) {
          return <ThreeDotPopover text={text} maxLength={30} />;
        } else {
          return (
            <StyledSecondaryButton
              icon={<GreenPlusSVG />}
              onClick={() => handleEdit(record)}>
              <ButtonText>Add Translation</ButtonText>
            </StyledSecondaryButton>
          );
        }
      },
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          <StyledImage src={Nederlanden} />
          NL-Dutch
        </Flex>
      ),
      dataIndex: 'labelNl',
      width: 150,
      render: (text, record) => {
        if (text) {
          return <ThreeDotPopover text={text} maxLength={30} />;
        } else {
          return (
            <StyledSecondaryButton
              icon={<GreenPlusSVG />}
              onClick={() => handleEdit(record)}>
              <ButtonText>Add Translation</ButtonText>
            </StyledSecondaryButton>
          );
        }
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 150,
      render: (text: string) =>
        <ThreeDotPopover text={text} maxLength={40} /> || '-',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      width: 100,
      render: (_value, data) => (
        <Dropdown
          dropdownRender={() => (
            <Menu>
              <MenuItem key="edit" onClick={() => handleEdit(data)}>
                Edit
              </MenuItem>
              {userData?.isLabelAdmin && (
                <MenuItem key="delete" onClick={() => handleDelete(data)}>
                  <Text type="regular" color={theme.red}>
                    Delete
                  </Text>
                </MenuItem>
              )}
            </Menu>
          )}>
          <img src={MenuThreeDots} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Spin
      spinning={
        authLoading ||
        allLabelsDataIsLoading ||
        postDataLoading ||
        allLabelsDataIsLoading ||
        isLoadingDeleteLabel ||
        isLoadingEditLabel ||
        isLoadingEditValue
      }>
      <MainCard>
        <Row>
          <Col xs={24} sm={16} md={11} lg={11} xl={11} xxl={11}>
            <H type="title3Bold" mb="17px" ml="24px">
              Labels
            </H>
          </Col>
          <Col xs={24} sm={12} md={13} lg={13} xl={13} xxl={13}>
            <HeaderButtonFlex
              justifyContent="end"
              display="flex"
              flexDirection="row"
              mr="24px">
              <SearchInput
                size="small"
                placeholder="Search"
                onChange={handleSearch}
              />
              <TranslationSelector
                onChange={(value: string) => {
                  setFilter(value);
                }}
              />
              {userData?.isLabelAdmin && (
                <PrimaryButton
                  icon={<CirclePlus />}
                  size="middle"
                  ml={16}
                  onClick={() => setVisible(true)}>
                  New Key
                </PrimaryButton>
              )}
            </HeaderButtonFlex>
          </Col>
          {visible && (
            <AddEditLabelsModal
              visible={visible}
              onCancel={() => {
                setVisible(false);
                setSelectedLabel(null);
              }}
              onSubmit={(formValues: ILabelModalData) => {
                const orderedFormValues = {
                  key: formValues.key,
                  labelEn: formValues.labelEn,
                  labelNl: formValues.labelNl,
                  description: formValues.description,
                };
                if (selectedLabel === null) {
                  postLabel(orderedFormValues);
                } else {
                  if (selectedLabel.originalKey === orderedFormValues.key) {
                    editLabelValues({
                      key: orderedFormValues.key,
                      data: orderedFormValues,
                    });
                  } else {
                    editLabel({
                      oldKey: selectedLabel.originalKey ?? '',
                      newKey: orderedFormValues.key,
                      data: orderedFormValues,
                    });
                  }
                }
                setSelectedLabel(null);
              }}
              loading={false}
              refetchData={refetchAllLabelData}
              selectedLabel={selectedLabel}
            />
          )}
          {visibleDeleteModal && selectedLabel && (
            <DeleteModal
              visible={visibleDeleteModal}
              title="Confirm Remove"
              buttonLabel="Delete"
              content="Are you sure you want to Delete? This action cannot be undone"
              onClickCancel={() => deleteLabel(selectedLabel.key)}
              handleCancel={() => {
                setVisibleDeleteModal(false);
                setSelectedLabel(null);
              }}
            />
          )}
        </Row>
        <Flex>
          <TableComponent
            loading={allLabelsDataIsLoading}
            columns={columns}
            dataSource={filteredData}
            scroll={{
              x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
              y: window.innerHeight - 200,
            }}
          />
        </Flex>{' '}
      </MainCard>
    </Spin>
  );
};
export default Label;
