import styled from '@emotion/styled';
import { Tag } from 'antd';
import { FC } from 'react';
import { SpaceProps, space } from 'styled-system';
import theme from '../../theme';
import { getPascalCaseUtil } from '../../utils/caseUtil';
import { getTagColorWithOpacity } from '../../utils/colorUtil';

type Props = SpaceProps & {
  tagColor: string;
  text: string;
  dotColor: string;
  size?: 'small' | 'large';
};

const CustomTag = styled(Tag)<{ size?: 'small' | 'large'; color: string }>`
  height: ${(props) => (props.size === 'small' ? '20px' : '28px')};
  padding: ${(props) => (props.size === 'small' ? '0px 4px' : '4px 12px')};
  text-align: left;
  font-family: SF Pro Display;
  font-size: ${(props) => (props.size === 'small' ? '11px' : '14px')};
  font-weight: ${(props) => (props.size === 'small' ? 590 : 400)};
  line-height: ${(props) => (props.size === 'small' ? '13px' : '20px')};
  border-radius: 8px;
  color: ${theme.brandDarkTeal}!important;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  ${space};
`;

export const CodeTag: FC<Props> = ({
  tagColor,
  text,
  dotColor,
  size = 'large',
}) => (
  <CustomTag color={tagColor} size={size}>
    <span
      style={{
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: dotColor,
        marginRight: '4px',
        opacity: 1,
      }}
    />
    {text}
  </CustomTag>
);

export const getCodeTag = (
  codeName: string,
  color: string,
  size?: 'small' | 'large',
) => {
  return (
    <CodeTag
      size={size}
      tagColor={getTagColorWithOpacity(color, 0.15)}
      dotColor={color}
      text={getPascalCaseUtil(codeName)}
    />
  );
};
