export enum CleaningProductTypeEnum {
  ALL = 'ALL',
  CHEMICAL = 'CHEMICAL',
  EQUIPMENT = 'EQUIPMENT',
}
export enum ProductTypeEnum {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export enum ManageByEnum {
  CC_MANAGED = 'CC_MANAGED',
  CUSTOM = 'CUSTOM',
}
export enum CleaningProductsStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
}

export interface ICleaningProdName {
  en: string;
  nl: string;
}

export interface CleaningProductsI {
  id: string;
  code: string;
  name: ICleaningProdName;
  type: CleaningProductTypeEnum;
  image: string;
  status: CleaningProductsStatusEnum;
  manageType: ManageByEnum;
}
export interface CleaningProductI {
  id: string;
  code: string;
  name: {
    en: string;
    nl: string;
  };
  type: CleaningProductTypeEnum;
  image: string;
  status: CleaningProductsStatusEnum;
  manageType: ManageByEnum;
}

export interface CleaningProductFormI {
  id?: string;
  type: CleaningProductTypeEnum;
  code: string;
  image?: File;
  oldImage?: string;
  name: {
    en: string;
    nl: string;
  };
}
