import { Col, Form, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Flex, FormContainer, Input } from '../../../components';
import ColorPickerComp from '../../../components/colorPicker/ColorPickerComp';
import ModalComponent from '../../../components/modals/ModalComponent';
import theme from '../../../theme';
import { ColorCodeI } from '../../../types/colorCode.types';
import { Nederlanden, UnitedStates } from '../../../assets';
import { Image } from '../../../components';
import styled from '@emotion/styled';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (formValues: ColorCodeI) => void;
  loading: boolean;
  data?: ColorCodeI;
  rolesToCreate?: number[];
}

const StyledLabelImage = styled(Image)`
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
`;

const CodeModal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  loading,
  data,
}) => {
  const [form] = Form.useForm();
  const [colorCode, setColorCode] = useState<string>(
    data?.color ? data.color : theme.brandTeal,
  );

  const onFinish = (formValues: ColorCodeI) => {
    const values = {
      ...formValues,
      color: colorCode,
    };
    onSubmit(values);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [data, form]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const onOk = () => {
    form && form.submit();
  };

  return (
    <ModalComponent
      onCancel={onCancel}
      closable={true}
      width={524}
      hideCancel={false}
      header={data ? 'Edit Code' : 'Add Code'}
      visible={visible}
      onSubmit={onOk}
      loadingBtn={loading}
      primaryBtnLabel={data ? 'Save' : 'Add'}>
      <Flex width={'100%'}>
        <FormContainer
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          onFinish={(v) => onFinish(v)}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <Flex display="flex" alignItems="center">
                    <StyledLabelImage src={UnitedStates} />
                    Code Name*
                  </Flex>
                }
                name="codeName"
                rules={[{ required: true, message: 'Enter code name' }]}>
                <Input placeholder="Enter code name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <Flex display="flex" alignItems="center">
                    <StyledLabelImage src={Nederlanden} />
                    Codenaam*
                  </Flex>
                }
                name="codeNameDutch"
                rules={[{ required: true, message: 'Voer de codenaam in' }]}>
                <Input placeholder="Voer de codenaam in" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Code Color *" name="color">
                <ColorPickerComp
                  size="large"
                  setColor={data && data.color}
                  onChange={(value) => setColorCode(value.toHexString())}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormContainer>
      </Flex>
    </ModalComponent>
  );
};
export default CodeModal;
