export const addDotsForLongText = (
  title: string,
  maxLength: number,
): string => {
  let letters: string;
  if (title?.length > maxLength) {
    letters = title.substring(0, maxLength);
    return letters + '...';
  } else {
    return title;
  }
};

export const isHaveThreeDots = (title: string, maxLength: number) => {
  if (title?.length > maxLength) {
    return true;
  } else {
    return false;
  }
};
