import styled from '@emotion/styled';
import { Flex as AntFlex, Dropdown, Menu, Spin, TableColumnsType } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import { FC, useEffect, useState } from 'react';
import {
  useCreateCode,
  useDeleteCode,
  useGetCodes,
  useGetItemsForCode,
  useUpdateCode,
} from '../../../api/codeHook';
import { CirclePlus, MenuThreeDots, Sort } from '../../../assets';
import { Flex, Image, PrimaryButton, Text } from '../../../components';
import TableComponent from '../../../components/table/TableComponent';
import { getCodeTag } from '../../../components/tags/CodeTag';
import { useAuth } from '../../../providers/auth-context';
import theme from '../../../theme';
import { ColorCodeI } from '../../../types/colorCode.types';
import { getManageByTag } from '../../cleaning-products/ManageBy';
import SettingsLayout from '../settingsLayout/SettingsLayout';
import CodeModal from './CodeModal';
import { isSuperAdmin } from '../../../utils/userRoleUtil';
import { tableSorter } from '../../../utils/tableSorter';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { RemoveCategoriesModal } from '../../../components/modals/RemoveCategoriesModal';
import { hideThreeDotsInCodeTable } from '../../../utils/codeUtil';
import { Nederlanden, UnitedStates } from '../../../assets';

const FlexComp = styled(AntFlex)`
  border-bottom: 1px solid var(--Button-Fill, ${theme.gray400});
  padding: 24px 24px 16px 24px;
`;

const HeadingText = styled(Text)`
  @media screen and (max-width: 768px) {
    display: none !important;
  }
`;

const StyledImage = styled(Image)`
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
`;

const CodeList: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [visibleCantRemove, setVisibleCantRemove] = useState<boolean>(false);
  const [rowData, setRowData] = useState<ColorCodeI>();

  const { currentUser, isLoading } = useAuth();

  const {
    data: codes,
    isLoading: isLoadingCodeData,
    refetch: getCodes,
  } = useGetCodes();

  const {
    mutate: createCode,
    data: createdCodeData,
    isSuccess: successCodeData,
    isLoading: isLoadingCreateCode,
  } = useCreateCode();

  const {
    mutate: updateCode,
    data: updatedCodeData,
    isSuccess: isUpdateCodeSuccess,
  } = useUpdateCode();

  const { mutate: getItemsForCode, data: itemsForCode } = useGetItemsForCode();
  const { mutate: deleteCode, data: deletedCodeData } = useDeleteCode();

  useEffect(() => {
    getCodes();
  }, [createdCodeData, updatedCodeData, deletedCodeData]);

  useEffect(() => {
    if (successCodeData || isUpdateCodeSuccess) {
      setVisible(false);
    }
  }, [successCodeData, isUpdateCodeSuccess]);

  const columns: TableColumnsType<ColorCodeI> = [
    {
      title: (
        <Flex display="flex" alignItems="center">
          <StyledImage src={UnitedStates} />
          Name
        </Flex>
      ),
      dataIndex: 'codeName',
      width: 250,
      render: (_value, data) => getCodeTag(data.codeName, data.color),
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          <StyledImage src={Nederlanden} />
          Naam
        </Flex>
      ),
      dataIndex: 'codeNameDutch',
      width: 250,
      render: (_value, data) =>
        getCodeTag(data.codeNameDutch ? data.codeNameDutch : '', data.color),
    },
  ];

  if (currentUser && !isSuperAdmin(Number(currentUser?.role.id))) {
    columns.push({
      title: (
        <Flex display="flex" alignItems="center">
          <Text type={'footnote-regular'}>Manage by</Text>
          <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'type',
      width: 200,
      render: (value) => getManageByTag(value),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.type, b.type),
      },
    });
  }

  columns.push({
    title: '',
    dataIndex: 'id',
    width: 130,
    render: (_value, data) =>
      currentUser &&
      hideThreeDotsInCodeTable(data.type, currentUser.role.id) ? null : (
        <Flex display="flex" justifyContent="flex-end" paddingRight="34px">
          <Dropdown
            dropdownRender={() => (
              <Menu>
                <MenuItem
                  key="edit"
                  onClick={() => {
                    setVisible(true);
                    setRowData(data);
                  }}>
                  Edit
                </MenuItem>
                <MenuItem
                  key="remove"
                  onClick={() => {
                    if (itemsForCode && itemsForCode.length > 0) {
                      setVisibleCantRemove(true);
                    } else {
                      setVisibleDeleteModal(true);
                      setRowData(data);
                    }
                  }}>
                  <Text color={theme.red} type="subheading-regular">
                    Remove
                  </Text>
                </MenuItem>
              </Menu>
            )}>
            <Image
              src={MenuThreeDots}
              marginLeft="20px"
              onMouseOver={() => getItemsForCode(data.id)}
            />
          </Dropdown>
        </Flex>
      ),
  });

  return (
    <>
      <SettingsLayout>
        <FlexComp>
          <HeadingText type="body-bold">Code</HeadingText>
          <Flex marginLeft="auto">
            <PrimaryButton
              icon={<CirclePlus />}
              size="middle"
              onClick={() => {
                setRowData(undefined);
                setVisible(true);
              }}>
              Add Code
            </PrimaryButton>
          </Flex>
        </FlexComp>
        <Spin size="large" spinning={isLoading || isLoadingCreateCode}>
          <Flex overflow="auto">
            <TableComponent
              loading={isLoadingCodeData}
              columns={columns}
              dataSource={codes}
              scroll={{
                y: window.innerHeight - 300,
              }}
              withBorders={true}
            />
          </Flex>
        </Spin>
      </SettingsLayout>
      {visible && (
        <CodeModal
          visible={visible}
          data={rowData}
          onCancel={() => setVisible(false)}
          loading={isLoadingCreateCode}
          onSubmit={(formValues: ColorCodeI) => {
            if (!rowData) {
              createCode(formValues);
            } else {
              updateCode({
                id: rowData.id,
                data: formValues,
              });
            }
          }}
        />
      )}
      {visibleDeleteModal && rowData && (
        <DeleteModal
          visible={visibleDeleteModal}
          onClickCancel={() => {
            if (rowData && rowData.id) {
              deleteCode(rowData.id);
            }
          }}
          content={`Are you sure you would like to remove ${rowData.codeName} code from the database? This action cannot be undone.`}
          title="Confirm Remove"
          buttonLabel="Remove"
          handleCancel={() => {
            setVisibleDeleteModal(false);
            setRowData(undefined);
          }}
        />
      )}
      {visibleCantRemove && itemsForCode && itemsForCode.length > 0 && (
        <RemoveCategoriesModal
          open={visibleCantRemove}
          content={`Code have been recorded for these items; hence they cannot be removed.`}
          title="Cannot be removed"
          items={itemsForCode ? itemsForCode.map((item) => item) : []}
          onCancel={() => setVisibleCantRemove(false)}
        />
      )}
    </>
  );
};
export default CodeList;
