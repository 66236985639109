import React, { FC } from 'react';
import { Avatar } from 'antd';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import styled from '@emotion/styled';
import theme from '../../theme';

const AvatarComp = styled(Avatar)<
  SpaceProps & TypographyProps & BorderProps & ColorProps
>`
  color: ${theme.white};
  background-color: ${theme.brandTeal};
  border:${(props) => (props.src ? 'unset' : ` 1px solid ${theme.brandTeal}`)};
  font-family: SF Pro Display;
  ant-avatar-string
  font-size: 22px;
  ${space}
  ${typography}
  ${border}
  ${color}
`;

type Props = SpaceProps &
  TypographyProps &
  BorderProps &
  ColorProps & {
    size?: number;
    shape?: 'circle' | 'square';
    title?: string;
    src?: React.ReactNode;
  };

const getChars = (text: string): string => {
  let i;
  const frags = text.split(' ');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase();
  }
  return frags.join('');
};

export const AvatarComponent: FC<Props> = ({
  size,
  shape,
  title,
  src,
  ...otherProps
}) => {
  return (
    <>
      <AvatarComp src={src} size={size} shape={shape} {...otherProps}>
        {title ? getChars(title) : null}
      </AvatarComp>
    </>
  );
};
