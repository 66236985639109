import { Menu_items } from '../configs/menuConfigs';
import { isBusinessOwner } from './userRoleUtil';

export const useGetMenuItems = (userRoleId?: number) => {
  if (userRoleId && isBusinessOwner(userRoleId)) {
    return Menu_items.filter((item) => item.displayName !== 'Labels');
  } else {
    return Menu_items;
  }
};
