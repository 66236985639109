import styled from '@emotion/styled';
import { Layout as AntdLayout } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { brokenLink } from '../../assets';
import { Flex, PrimaryButton, SecondaryButton, Text } from '../../components';
import MinimalNavBar from '../../components/TopMenu/MinimalNavBar';
import theme from '../../theme';

const { Content } = AntdLayout;

const ContentLayout = styled(Content)`
  height: 100vh;
  min-height: 95vh;
  background-color: ${theme.gray200};
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  width: 420px;
  background: ${theme.white};
  display: flex;
  justify-content: center;
  border: 1px solid ${theme.gray400};
  box-shadow: 0px 2px 6px 0px rgba(210, 210, 210, 0.5);
  border-radius: 8px;

  @media screen and (max-width: 480px) {
    width: 350px;
  }
`;

const PageNotFound: FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  return (
    <AntdLayout>
      <MinimalNavBar />
      <ContentLayout>
        <Card>
          {token ? (
            <Flex width={'340px'} textAlign={'center'} margin={'40px'}>
              <Flex mb={16}>
                <img src={brokenLink} width={'40px'} height={'40px'} />
              </Flex>
              <Text type="body-bold" mb={'8px'}>
                Link Expired!
              </Text>
              <Text type="subheading-regular" color={theme.gray800}>
                Either the link had already expired or this link was already
                used to change the password.
              </Text>
              <PrimaryButton
                block
                mt={'24px'}
                onClick={() => navigate('/forgot-password')}>
                Send me a new link
              </PrimaryButton>
              <SecondaryButton
                block
                mt={'8px'}
                onClick={() => navigate('/login')}>
                Back to Sign In
              </SecondaryButton>
            </Flex>
          ) : (
            <Flex textAlign={'center'} width={'340px'} margin={'40px'}>
              <Text
                type="body-bold"
                color={theme.brandTeal}
                fontSize={'64px'}
                lineHeight={'76.38px'}>
                404
              </Text>
              <Text type="body-bold" mb={'8px'}>
                Whoops! Page Not Found
              </Text>
              <Text type="subheading-regular" color={theme.gray800}>
                The page you requested was not found. Don&apos;t worry, you can
                try searching for it or head back to the homepage.
              </Text>
              <SecondaryButton
                block
                mt={'24px'}
                onClick={() => navigate('/items')}>
                Go Back
              </SecondaryButton>
            </Flex>
          )}
        </Card>
      </ContentLayout>
    </AntdLayout>
  );
};

export default PageNotFound;
