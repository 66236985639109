import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import RootProvider from './providers/RootProvider';
import reportWebVitals from './reportWebVitals';
import { BASE_URL } from './configs/appConfig';
import axios from 'axios';
import React from 'react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = 30000;

root.render(
  <RootProvider>
    <ConfigProvider theme={{ cssVar: false, hashed: false }}>
      <App />
    </ConfigProvider>
  </RootProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
