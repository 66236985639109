/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Flex as AntFlex, Col, Form, Row, Spin, Upload } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import { FC, useEffect, useState } from 'react';
import { usePutUsers } from '../../../api/userHooks';
import { FileUpload } from '../../../assets';
import {
  AvatarComponent,
  Flex,
  FormContainer,
  H,
  Image,
  Input,
  PhoneNumberInput,
  PrimaryButton,
  Text,
} from '../../../components';
import NameTag from '../../../components/tags/NameTag';
import { useAuth } from '../../../providers/auth-context';
import theme from '../../../theme';
import { PutUserI } from '../../../types/user.types';
import { getPascalCaseUtil } from '../../../utils/caseUtil';
import SettingsLayout from '../settingsLayout/SettingsLayout';
import { Message } from '../../../components/message/Message';

const ProfilePicture = styled(AvatarComponent)`
  object-fit: cover;
  .ant-avatar-string {
    font-size: 40px;
  }
`;
const Typography = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
`;
const FlexComp = styled(AntFlex)`
  border-bottom: 1px solid ${theme.gray300};
  padding: 24px 24px 16px 24px;

  @media screen and (max-width: 760px) {
    display: none;
  }
`;

const ProfileSetting: FC = () => {
  const [form] = Form.useForm();
  const { currentUser, isLoading, fetchUser } = useAuth();
  const [profilePictureFile, setProfilePictureFile] = useState<File>();

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      Message.error('You can only upload JPG or PNG file!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      Message.error('Image must be smaller than 5MB!');
    } else {
      setProfilePictureFile(file);
    }
    return isJpgOrPng && isLt5M;
  };

  const {
    data,
    mutate: putUsers,
    isLoading: putUsersLoading,
    error,
  } = usePutUsers();

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    error && Message.error(error.msg);
  }, [error]);

  useEffect(() => {
    fetchUser();
    setProfilePictureFile(undefined);
  }, [data]);

  const onFinish = (formValues: PutUserI) => {
    const value = {
      ...formValues,
      profilePicture: profilePictureFile,
    };
    putUsers(value);
  };

  return (
    <>
      <Spin size="large" spinning={isLoading || putUsersLoading}>
        <SettingsLayout>
          <FlexComp>
            <H type="title3Bold" color={theme.brandDarkTeal}>
              Profile
            </H>
          </FlexComp>
          <Flex p="32px 24px" overflow="auto" height={`60vh`}>
            <FormContainer
              form={form}
              layout="vertical"
              onFinish={(value) => onFinish(value)}>
              <Flex display="flex" mb="32px">
                <ProfilePicture
                  size={80}
                  shape="circle"
                  title={currentUser?.firstName + ' ' + currentUser?.lastName}
                  src={currentUser?.profilePicture}
                />
                <Flex>
                  <Upload
                    action={''}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    accept="image/png, image/jpg, image/jpeg">
                    <Flex ml="32px" display="flex" mt="24px">
                      <Image src={FileUpload} cursor="pointer" />
                      <Typography
                        cursor="pointer"
                        ml="8px"
                        type={'subheading-bold'}
                        color={theme.brandDarkTeal}>
                        Replace Profile Picture
                      </Typography>
                    </Flex>
                  </Upload>
                  <Text
                    type={'subheading-regular'}
                    color={theme.gray800}
                    ml="32px">
                    Supported file type: (JPG & PNG)
                  </Text>
                  {profilePictureFile && (
                    <Text
                      type={'subheading-regular'}
                      color={theme.gray800}
                      ml="32px">
                      {profilePictureFile?.name}
                    </Text>
                  )}
                </Flex>
              </Flex>
              {currentUser?.role.role && (
                <Flex display="flex" mb="24px" alignItems="center">
                  <Text type="subheading-regular" mr="8px">
                    Role
                  </Text>
                  <NameTag
                    tagColor={theme.brandDarkTeal}
                    textColor={theme.white}
                    text={getPascalCaseUtil(currentUser?.role.role)}
                  />
                </Flex>
              )}
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                  <Form.Item label="First Name *" name="firstName">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                  <Form.Item label="Last Name *" name="lastName">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                  <Form.Item label="Contact number" name="phoneNumber">
                    <PhoneNumberInput disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                  <Form.Item label="Email" name="email">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </FormContainer>
          </Flex>
          <Flex
            display="flex"
            flexDirection="row-reverse"
            p={'16px 32px'}
            borderTop={`1px solid var(--Button-Fill, ${theme.gray400})`}>
            <PrimaryButton onClick={() => form.submit()} htmlType="submit">
              Save
            </PrimaryButton>
          </Flex>
        </SettingsLayout>
      </Spin>
    </>
  );
};
export default ProfileSetting;
