import { UserRoleFormattedI, UserRoleIdEnum } from '../types/user.types';

export const getCreatableRoles = (roleId: number): number[] => {
  const creatableRoles: number[] = [];
  switch (roleId) {
    case UserRoleIdEnum.SUPER_ADMIN:
      creatableRoles.push(
        UserRoleIdEnum.SUPER_ADMIN_MEMBER,
        UserRoleIdEnum.SUPER_ADMIN,
      );
      break;
    case UserRoleIdEnum.BUSINESS_OWNER:
      creatableRoles.push(UserRoleIdEnum.BUSINESS_OWNER, UserRoleIdEnum.ADMIN);
      break;
    case UserRoleIdEnum.ADMIN:
      creatableRoles.push(UserRoleIdEnum.ADMIN);
      break;
    case UserRoleIdEnum.SUPER_ADMIN_MEMBER:
      creatableRoles.push();
      break;
  }
  return creatableRoles;
};

export const getRolesWithId = (roleIds: number[]): UserRoleFormattedI[] => {
  const roles: UserRoleFormattedI[] = [];

  roleIds.forEach((roleId) => {
    if (roleId && UserRoleIdEnum[roleId]) {
      const formattedRole = UserRoleIdEnum[roleId]
        .split('_')
        .map((word) => word.toLowerCase())
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      roles.push({ id: roleId, role: formattedRole });
    }
  });
  return roles;
};

export const isBusinessOwner = (roleId: number): boolean => {
  switch (roleId) {
    case UserRoleIdEnum.BUSINESS_OWNER:
      return true;
    case UserRoleIdEnum.ADMIN:
      return true;
    default:
      return false;
  }
};

export const isSuperAdmin = (roleId: number): boolean => {
  switch (roleId) {
    case UserRoleIdEnum.SUPER_ADMIN:
      return true;
    case UserRoleIdEnum.SUPER_ADMIN_MEMBER:
      return true;
    default:
      return false;
  }
};

export const getEditableDeletableRoles = (roleId: number): number[] => {
  const editableDeletableRoles: number[] = [];
  switch (roleId) {
    case UserRoleIdEnum.SUPER_ADMIN:
      editableDeletableRoles.push(
        UserRoleIdEnum.SUPER_ADMIN_MEMBER,
        UserRoleIdEnum.SUPER_ADMIN,
      );
      break;
    case UserRoleIdEnum.BUSINESS_OWNER:
      editableDeletableRoles.push(
        UserRoleIdEnum.ADMIN,
        UserRoleIdEnum.BUSINESS_OWNER,
      );

      break;

    case UserRoleIdEnum.ADMIN:
      editableDeletableRoles.push(UserRoleIdEnum.ADMIN);

      break;
    case UserRoleIdEnum.SUPER_ADMIN_MEMBER:
      editableDeletableRoles.push();
      break;
  }
  return editableDeletableRoles;
};
