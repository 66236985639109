import { Form, FormProps } from 'antd';

import styled from '@emotion/styled';
import theme from '../../theme';

export const FormContainer = styled(Form)<FormProps>`
  .ant-form-item-label > label {
    font-family: SF Pro Display;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: ${theme.black};
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 6px !important;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
`;
