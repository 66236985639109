import { useMutation, useQuery } from 'react-query';
import {
  DeleteAccountEmailI,
  DeleteAccountI,
  PutUserI,
  UserDeleteAccountI,
  UserMeResponseI,
} from '../types/user.types';
import { getApiError, http } from './_apiService';
import { ApiError } from './_types';
import { UserI } from '../types/settings.types';
import { Message } from '../components/message/Message';

export const useGetMe = () =>
  useQuery<UserMeResponseI, ApiError>(
    'me',
    async () => {
      const response = await http.get('/users/me');
      return response.data;
    },
    {
      enabled: false,
    },
  );

export const usePutUsers = () =>
  useMutation<string, ApiError, PutUserI>(
    async (data) => {
      const formData = new FormData();
      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      {
        data?.profilePicture &&
          formData.append('profilePicture', data?.profilePicture);
      }
      const response = await http.put(`/users`, formData);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(getApiError(error));
      },
      onSuccess: () => {
        Message.success('updated successfully');
      },
    },
  );

export const useGetAdmins = () =>
  useQuery<UserI[], ApiError>(
    'getAdmins',
    async () => {
      const response = await http.get('/users/admins');
      return response.data;
    },
    {
      enabled: false,
    },
  );

export const useCreateAdmin = () =>
  useMutation<string, ApiError, UserI>(async (data: UserI) => {
    const { role, ...rest } = data;
    const finalData = {
      roleId: role,
      ...rest,
    };
    const response = await http.post('/users/admins', finalData);
    return response.data;
  });

export const useUpdateAdmin = () =>
  useMutation<string, ApiError, UserI>(async (data: UserI) => {
    const { role, ...rest } = data;
    const finalData = {
      roleId: role,
      ...rest,
    };
    const response = await http.put('/users/admins', finalData);
    return response.data;
  });

export const useArchiveAdmin = () => {
  return useMutation<string, ApiError, string>(async (id: string) => {
    const response = await http.delete(`/users/admins/${id}`);
    return response.data;
  });
};

export const useSubmitDeleteAccountEmail = () =>
  useMutation<string, ApiError, DeleteAccountEmailI>(
    async (data: DeleteAccountEmailI) => {
      const response = await http.post('/users/delete-request', data);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Delete account request sent!');
      },
      onError: (error) => {
        Message.error(error.msg);
      },
    },
  );

export const useDeleteAccount = () =>
  useMutation<string, ApiError, DeleteAccountI>(
    async (data: DeleteAccountI) => {
      const response = await http.put(`/users/delete-request/${data.token}`);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Account deleted successfully!');
      },
      onError: (error) => {
        Message.error(error.msg);
      },
    },
  );

export const useValidateDeleteToken = () =>
  useMutation<UserDeleteAccountI, ApiError, string>(
    async (deleteToken: string) => {
      const response = await http.post(
        `/users/validate-delete-token/${deleteToken}`,
      );
      return response.data;
    },
  );
