import styled from '@emotion/styled';
import { Col, Image, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { DeleteSVG } from '../../assets';
import { Flex, Text } from '../../components';
import { DeleteModal } from '../../components/modals/DeleteModal';
import { getLanguageByTag } from '../../components/tags/LanguageTag';
import theme from '../../theme';
import { LanguageEnum } from '../../types/equipmentsItems.types';

const EditorCol = styled(Col)`
  padding: 24px 0px 24px 0px;

  @media (max-width: 768px) {
    padding: 0px !important;
  }
`;

const ProductImage = styled.img`
  border-radius: 8px;
  border: 1px solid ${theme.gray400};
  width: 106px;
  height: 106px;
  object-fit: contain;

  @media (max-width: 768px) {
    margin-top: 24px !important;
    margin-bottom: 8px !important;
  }
`;

const DeleteImage = styled.div`
  cursor: pointer;
`;

interface Product {
  id: string;
  name: {
    en: string;
    nl: string;
  };
  code: string;
  type: string;
  manageType: string;
  image: string;
  status: string;
}

interface Props {
  products: Product[];
  columnsPerRow: number;
  updateProductsList: (updatedProductsList: Product[]) => void;
}

const GenerateRowsAndCols: FC<Props> = ({
  products,
  columnsPerRow,
  updateProductsList,
}) => {
  const [productsList, setProductsList] = useState<Product[]>(products);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteProductIndex, setDeleteProductIndex] = useState<number>();

  useEffect(() => {
    setProductsList(products);
  }, [products]);

  useEffect(() => {
    setProductsList(productsList);
    updateProductsList(productsList);
  }, [productsList]);

  const numRows = Math.ceil(productsList.length / columnsPerRow);
  const rows = [];

  const removeProduct = (j: number) => {
    setDeleteProductIndex(j);
    setShowDeleteModal(true);
  };

  const handleDeleteProduct = () => {
    if (deleteProductIndex !== undefined) {
      const updatedProductsList = productsList.filter(
        (_, index) => index !== deleteProductIndex,
      );
      setProductsList(updatedProductsList);
      setDeleteProductIndex(undefined);
      setShowDeleteModal(false);
    }
  };

  for (let i = 0; i < numRows; i++) {
    const startIdx = i * columnsPerRow;
    const endIdx = Math.min(startIdx + columnsPerRow, productsList.length);
    const cols = [];
    for (let j = startIdx; j < endIdx; j++) {
      cols.push(
        <Col span={24 / columnsPerRow} key={j}>
          <Row>
            <EditorCol xs={24} sm={24} md={24} lg={24} xl={8} xxl={5}>
              <ProductImage src={productsList[j].image} />
            </EditorCol>

            <EditorCol xs={24} sm={24} md={24} lg={24} xl={16} xxl={19}>
              <Flex
                display={'flex'}
                flexDirection={'column'}
                border={`1px solid ${theme.gray400}`}
                borderRadius="8px"
                height={'106px'}
                marginRight={'12px'}
                padding={'12px'}
                justifyContent="space-between">
                <Flex
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  {/* {getCategoryTag(products[j].type, 'small')} */}
                  <Text type="footnote-regular">{productsList[j].code}</Text>
                  <DeleteImage>
                    <Image
                      src={DeleteSVG}
                      width={'15px'}
                      height={'16px'}
                      preview={false}
                      onClick={() => removeProduct(j)}
                    />
                  </DeleteImage>
                </Flex>
                <Flex
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}>
                  <Text type="footnote-regular">{productsList[j].name.en}</Text>
                  <Flex ml={'12px'} mr={'12px'}>
                    {getLanguageByTag(LanguageEnum.ENGLISH)}
                  </Flex>
                </Flex>
                <Flex
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}>
                  <Text type="footnote-regular">{productsList[j].name.nl}</Text>
                  <Flex ml={'12px'} mr={'12px'}>
                    {getLanguageByTag(LanguageEnum.DUTCH)}
                  </Flex>
                </Flex>
              </Flex>
            </EditorCol>
          </Row>
        </Col>,
      );
    }
    rows.push(<Row key={i}>{cols}</Row>);
  }

  return (
    <div>
      {rows}{' '}
      {showDeleteModal && (
        <DeleteModal
          visible={showDeleteModal}
          onClickCancel={() => {
            handleDeleteProduct();
          }}
          content={`Are you sure you would like to remove this product.`}
          title="Confirm Remove"
          handleCancel={() => {
            setDeleteProductIndex(undefined);
            setShowDeleteModal(false);
          }}
          buttonLabel="Remove"
        />
      )}
    </div>
  );
};

export default GenerateRowsAndCols;
