import { useMutation, useQuery } from 'react-query';
import {
  CleaningProductFormI,
  CleaningProductsI,
} from '../types/cleaningProducts.types';
import { http } from './_apiService';
import { ApiError } from './_types';
import { Message } from '../components/message/Message';

export const useGetAllProducts = () => {
  return useQuery<CleaningProductsI[], ApiError>(
    'allProducts',
    async () => {
      const response = await http.get(`/cleaning-products`);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
    },
  );
};

export const useCreateProduct = () => {
  return useMutation<CleaningProductsI, ApiError, CleaningProductFormI>(
    async (data) => {
      const formData = new FormData();
      formData.append('code', data.code);
      formData.append('name[en]', data.name.en);
      formData.append('name[nl]', data.name.nl);
      formData.append('type', data.type);

      if (data.image) {
        formData.append('image', data.image);
      }

      const response = await http.post(`/cleaning-products`, formData);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
      onSuccess: () => {
        Message.success('Product created successfully');
      },
    },
  );
};

export const useToggleProductStatus = () => {
  return useMutation<string, ApiError, string>(
    async (id) => {
      const response = await http.put(`/cleaning-products/toggle/${id}`);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
      onSuccess: () => {
        Message.success('Product status updated successfully');
      },
    },
  );
};

export const useUpdateProduct = () => {
  return useMutation<CleaningProductFormI, ApiError, CleaningProductFormI>(
    async (data) => {
      const formData = new FormData();
      formData.append('code', data.code);
      formData.append('name[en]', data.name.en);
      formData.append('name[nl]', data.name.nl);
      formData.append('type', data.type);

      if (data.image) {
        formData.append('image', data.image);
      }

      const response = await http.put(
        `/cleaning-products/${data.id}`,
        formData,
      );
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
      onSuccess: () => {
        Message.success('Product updated successfully');
      },
    },
  );
};
