import { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import theme from '../../theme';
import {
  Flex,
  FormContainer,
  H,
  PrimaryButton,
  Text,
  Input,
} from '../../components';
import { useSubmitDeleteAccountEmail } from '../../api/userHooks';
import styled from '@emotion/styled';
import { ForgotPasswordFormI } from '../../types/auth.types';
import ResentLinkSent from './ResentLinkSent';
import AuthLayout from './shared/AuthLayout';

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const DeleteAccountPage: FC = () => {
  const [form] = Form.useForm();
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);

  const {
    mutate: submitDeleteUserEmail,
    data: deleteAccountData,
    isError: isErrorSubmitDeleteUserEmail,
    isLoading: isLoadingSubmitDeleteUserEmail,
  } = useSubmitDeleteAccountEmail();

  const submitAccountDeleteRequest = (values: ForgotPasswordFormI) => {
    if (!emailSubmitted) {
      submitDeleteUserEmail(values);
    }
  };

  useEffect(() => {
    if (!isErrorSubmitDeleteUserEmail && deleteAccountData) {
      setEmailSubmitted(true);
    }
  }, [deleteAccountData]);

  return (
    <AuthLayout>
      <div>
        {!emailSubmitted ? (
          <>
            <Flex width={'358px'}>
              <H type="largeTitle" color={theme.brandDarkTeal} mb="16px">
                Delete Account
              </H>
              <Text type="subheading-regular" color={theme.gray800} mb="40px">
                If there is an account linked to these credentials, we&apos;ll
                <br /> send you a link to delete the account.
              </Text>
            </Flex>

            <FormContainer
              form={form}
              layout="vertical"
              onFinish={(values) => {
                submitAccountDeleteRequest(values);
              }}>
              <FormItem
                label="Email ID *"
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  {
                    type: 'email',
                    message: 'Please input a valid email!',
                  },
                ]}>
                <Input placeholder="Enter email id" />
              </FormItem>
              <Form.Item>
                <PrimaryButton
                  htmlType="submit"
                  block
                  mt="40px"
                  loading={isLoadingSubmitDeleteUserEmail}
                  disabled={isLoadingSubmitDeleteUserEmail || emailSubmitted}>
                  Request Account Deletion
                </PrimaryButton>
              </Form.Item>
            </FormContainer>
          </>
        ) : (
          <ResentLinkSent form={form} isDeleteAccount={true} />
        )}
      </div>
    </AuthLayout>
  );
};

export default DeleteAccountPage;
