import { FC, useEffect, useState } from 'react';
import { Dropdown, Layout, Menu } from 'antd';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Setting_Menu } from '../../../configs/menuConfigs';
import { SettingsMenuI } from '../../../types/menu.types';
import { UserRoleIdEnum } from '../../../types/user.types';
import { useAuth } from '../../../providers/auth-context';
import { isSuperAdmin } from '../../../utils/userRoleUtil';
import { useWindowSize } from '../../../utils/screenSize';
import { Flex } from '../../../components';
import { Text } from '../../../components';
import { DropdownBlack } from '../../../assets';

const { Content, Sider } = Layout;

const LayoutProfile = styled(Layout)`
  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border: unset !important;
    border-radius: 8px 8px 7px 7px;
  }
`;
const MainContainer = styled(Content)`
  height: 70%;
  min-height: calc(100vh - 230px);
  .ant-layout-sider-children {
    background-color: transparent;
  }
  min-height: 40px !important;
`;
const MenuLink = styled(Link)`
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.4000000059604645px;
`;
const MenuItem = styled(Menu.Item)`
  height: 40px !important;
  padding-left: 0px !important;
  &:hover {
    padding-left: 16px !important;
  }
  .ant-menu-light .ant-menu-item-selected {
    background-color: #66ccb03d;
  }

  @media (max-width: 1024px) {
    width: 180px !important;
  }
  @media (max-width: 850px) {
    width: 150px !important;
  }

  @media (max-width: 768px) {
    background-color: transparent !important;
    width: fit-content !important;
  }
`;

const StyledMenu = styled(Menu)`
  overflow-y: hidden;
  width: 221px;
  @media screen and (max-width: 768px) {
    background-color: transparent !important;
  }
`;

const OnlyMobileFlex = styled(Flex)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const OnlyDesktopFlex = styled(Flex)`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const SettingsMenu: FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const { isMobile } = useWindowSize();

  const [userMenuItems, setUserMenuItems] =
    useState<SettingsMenuI[]>(Setting_Menu);
  const [activeMenu, setActiveMenu] = useState('');

  useEffect(() => {
    setActiveMenu(getCurrentActiveMenu());
  }, [userMenuItems, location]);

  useEffect(() => {
    if (
      userMenuItems &&
      currentUser &&
      currentUser.role.id === UserRoleIdEnum.SUPER_ADMIN_MEMBER
    ) {
      const menuItems = userMenuItems.filter((val) => val.key !== 'users');
      setUserMenuItems(menuItems);
    }
  }, []);

  useEffect(() => {
    if (userMenuItems && currentUser) {
      let menuItems;
      if (currentUser.role.id === UserRoleIdEnum.SUPER_ADMIN_MEMBER) {
        menuItems = userMenuItems.filter(
          (val) => val.key !== 'users' && val.key !== 'venues&BusinessHours',
        );
        setUserMenuItems(menuItems);
      }
      if (
        isSuperAdmin(currentUser.role.id) &&
        currentUser.role.id !== UserRoleIdEnum.SUPER_ADMIN_MEMBER
      ) {
        menuItems = userMenuItems.filter(
          (val) => val.key !== 'venues&BusinessHours',
        );
        setUserMenuItems(menuItems);
      }
    }
  }, [currentUser]);

  const getCurrentActiveMenu = () => {
    const currentPath = userMenuItems.find(
      (item) => `${item.path}` === location.pathname,
    );

    return currentPath?.displayName || '';
  };

  const handleMenuClick = (key: string) => {
    setActiveMenu(key);
  };

  const items = userMenuItems.map((item, index) => {
    return {
      key: index,
      label: (
        <MenuItem key={item.displayName}>
          <MenuLink to={`${item.path}`}>{item.displayName}</MenuLink>
        </MenuItem>
      ),
    };
  });

  return (
    <MainContainer>
      <LayoutProfile className="site-layout-background">
        <Sider className="site-layout-background" width="100%">
          <StyledMenu
            mode={isMobile ? 'horizontal' : 'inline'}
            theme={'light'}
            onClick={(event) => handleMenuClick(event.key as string)}
            selectedKeys={[activeMenu]}>
            <OnlyMobileFlex>
              <Dropdown menu={{ items }}>
                <Text type="body-bold">
                  {activeMenu} <img src={DropdownBlack} />
                </Text>
              </Dropdown>
            </OnlyMobileFlex>
            <OnlyDesktopFlex>
              {userMenuItems.map((menuItem) => {
                return (
                  <MenuItem key={menuItem.displayName}>
                    <MenuLink to={`${menuItem.path}`}>
                      {menuItem.displayName}
                    </MenuLink>
                  </MenuItem>
                );
              })}
            </OnlyDesktopFlex>
          </StyledMenu>
        </Sider>
      </LayoutProfile>
    </MainContainer>
  );
};
export default SettingsMenu;
