export const addDotsForLongText = (title: string, maxLength: number) => {
  let letters: string;
  if (title.length > maxLength) {
    letters = title.substring(0, maxLength);
    return letters + '...';
  } else {
    return title;
  }
};

export const removeHtmlTags = (text: string): string => {
  const noTags = text.replace(/<[^>]*>/g, '');
  const spaceAfterFullStop = noTags.replace(/\.(\S)/g, '. $1');
  return spaceAfterFullStop;
};
