import { DayEnum } from '../containers/enums/Day.enum';
import { OpenDayCategories } from '../containers/enums/Venue.enum';

export const getOpenDaysCategory = (openDays: string[]): string => {
  const allDays = Object.values(DayEnum);
  const workDays = [
    DayEnum.MONDAY,
    DayEnum.TUESDAY,
    DayEnum.WEDNESDAY,
    DayEnum.THURSDAY,
    DayEnum.FRIDAY,
  ];

  if (
    openDays.length === allDays.length &&
    allDays.every((day) => openDays.includes(day))
  ) {
    return OpenDayCategories.EVERYDAY;
  } else if (
    openDays.length === workDays.length &&
    workDays.every((day) => openDays.includes(day))
  ) {
    return OpenDayCategories.WORK_WEEK;
  } else {
    return OpenDayCategories.CUSTOM;
  }
};

export const getOpenDayCategories = (): string[] => {
  return Object.values(OpenDayCategories);
};
export const getWorkingDays = (): string[] => {
  return Object.values(DayEnum);
};

export const getMonthlyCleaningDays = (): string[] => {
  const monthlyDaysForCleaning: string[] = [];
  for (let i = 1; i <= 28; i++) {
    monthlyDaysForCleaning.push(i.toString().padStart(2, '0'));
  }
  return monthlyDaysForCleaning;
};

export const getDaysFromCategory = (category: OpenDayCategories): DayEnum[] => {
  const days: DayEnum[] = [];
  switch (category) {
    case OpenDayCategories.WORK_WEEK:
      days.push(DayEnum.MONDAY);
      days.push(DayEnum.TUESDAY);
      days.push(DayEnum.WEDNESDAY);
      days.push(DayEnum.THURSDAY);
      days.push(DayEnum.FRIDAY);
      break;
    case OpenDayCategories.EVERYDAY:
      days.push(DayEnum.MONDAY);
      days.push(DayEnum.TUESDAY);
      days.push(DayEnum.WEDNESDAY);
      days.push(DayEnum.THURSDAY);
      days.push(DayEnum.FRIDAY);
      days.push(DayEnum.SATURDAY);
      days.push(DayEnum.SUNDAY);
      break;
  }
  return days;
};
