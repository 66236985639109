export const validateEmail = (
  selectedOption: string,
  value: string,
): Promise<void> => {
  if (
    selectedOption === 'email' &&
    value &&
    !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)
  ) {
    return Promise.reject(new Error('Invalid email!'));
  } else {
    return Promise.resolve();
  }
};
