import { DayEnum, DaysI } from '../containers/enums/Day.enum';

export const DAYS: DaysI[] = [
  {
    key: DayEnum.MONDAY,
    label: 'Mo',
  },
  {
    key: DayEnum.TUESDAY,
    label: 'Tu',
  },
  {
    key: DayEnum.WEDNESDAY,
    label: 'We',
  },
  {
    key: DayEnum.THURSDAY,
    label: 'Th',
  },
  {
    key: DayEnum.FRIDAY,
    label: 'Fr',
  },
  {
    key: DayEnum.SATURDAY,
    label: 'Sa',
  },
  {
    key: DayEnum.SUNDAY,
    label: 'Su',
  },
];
