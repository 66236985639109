import { Col, Form, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Nederlanden, UnitedStates } from '../../assets';
import { Flex, FormContainer, Image, Input, Text } from '../../components';
import DragAndDropFileUpload from '../../components/fileUpload/DragAndDropFileUpload';
import ModalComponent from '../../components/modals/ModalComponent';
import {
  CleaningProductFormI,
  CleaningProductTypeEnum,
} from '../../types/cleaningProducts.types';

interface Props {
  visible: boolean;
  isEquipment: boolean;
  onCancel: () => void;
  onSubmit: (formValues: CleaningProductFormI) => void;
  loading: boolean;
  initialValues?: CleaningProductFormI | null;
}

const EquipmentModal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  loading,
  isEquipment,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const onFinish = (formValues: CleaningProductFormI) => {
    formValues.type = isEquipment
      ? CleaningProductTypeEnum.EQUIPMENT
      : CleaningProductTypeEnum.CHEMICAL;

    if (uploadedFile) {
      formValues.image = uploadedFile;
    }
    if (initialValues) {
      formValues.id = initialValues.id;
    }
    onSubmit(formValues);
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [visible, initialValues]);

  const onOk = () => {
    form && form.submit();
  };

  return (
    <ModalComponent
      onCancel={onCancel}
      closable={true}
      width={524}
      hideCancel={false}
      header={
        isEquipment
          ? initialValues
            ? 'Edit Equipment'
            : 'Add Equipment'
          : initialValues
            ? 'Edit Chemical'
            : 'Add Chemical'
      }
      visible={visible}
      onSubmit={onOk}
      loadingBtn={loading}>
      <Flex width={'100%'} display="">
        <FormContainer
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          onFinish={(v) => onFinish(v)}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                label="Code *"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Enter code',
                  },
                ]}>
                <Input placeholder="Enter code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                label={
                  <Flex display="flex">
                    <Image src={UnitedStates} borderRadius={'24px'} />
                    <Text type="subheading-regular" ml="6px">
                      {isEquipment ? 'Equipment Name *' : 'Chemical Name *'}
                    </Text>
                  </Flex>
                }
                rules={[
                  {
                    required: true,
                    message: isEquipment
                      ? 'Enter equipment name'
                      : 'Enter chemical name',
                  },
                ]}
                name={['name', 'en']}>
                <Input placeholder="Enter name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                label={
                  <Flex display="flex">
                    <Image src={Nederlanden} />
                    <Text type="subheading-regular" ml="6px">
                      {isEquipment
                        ? 'Naam van apparatuur *'
                        : 'Chemische naam *'}
                    </Text>
                  </Flex>
                }
                rules={[
                  {
                    required: true,
                    message: isEquipment
                      ? 'Voer de naam van de apparatuur in'
                      : 'Voer de chemische naam in',
                  },
                ]}
                name={['name', 'nl']}>
                <Input placeholder="Voer naam in" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="image"
                label={'Upload Image*'}
                rules={[
                  {
                    required: !initialValues && !uploadedFile,
                    message: 'Upload product image',
                  },
                ]}>
                <DragAndDropFileUpload
                  minSize={1024}
                  maxSize={10072000}
                  setFormImage={(file) => {
                    setUploadedFile(file);
                  }}
                  defaultImage={initialValues?.oldImage}
                  accept={['image/jpeg', 'image/png']}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormContainer>
      </Flex>
    </ModalComponent>
  );
};
export default EquipmentModal;
