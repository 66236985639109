import { useMutation } from 'react-query';
import { Message } from '../components/message/Message';
import { ItemsI, PostItemsI, PutItemsI } from '../types/equipmentsItems.types';
import { http } from './_apiService';
import { ApiError } from './_types';

export const useGetCCAdminItemList = () => {
  return useMutation<ItemsI[], ApiError>(async () => {
    const response = await http.get(`/items`);
    return response.data;
  });
};

export const useGetBusinessOwnerItemList = () => {
  return useMutation<ItemsI[], ApiError, string>(async (venueId) => {
    const response = await http.get(`/venue-items/${venueId}`);
    return response.data;
  });
};

export const useAdminArchive = () => {
  return useMutation<string, ApiError, string>('adminItem', async (id) => {
    const response = await http.put(`/items/toggle/${id}`);
    return response.data;
  });
};

export const useBusinessOwnerArchive = () => {
  return useMutation<string, ApiError, string>(
    'businessOwner',
    async (id: string) => {
      const response = await http.put(`/venue-items/toggle/${id}`);
      return response.data;
    },
  );
};

export const useAdminGetItem = () => {
  return useMutation<ItemsI, ApiError, string>(async (id) => {
    const response = await http.get(`/items/${id}`);
    return response.data;
  });
};

export const useBusinessOwnerGetItem = () => {
  return useMutation<ItemsI, ApiError, string>(async (id) => {
    const response = await http.get(`/venue-items/item/${id}`);
    return response.data;
  });
};

export const useCreateItem = () => {
  return useMutation<string, ApiError, PostItemsI>(
    async (data: PostItemsI) => {
      const formData = new FormData();
      formData.append('name', JSON.stringify(data.name));
      formData.append('category', data.category);
      formData.append('area', data.area);
      formData.append('colorCode', data.colorCode);
      data?.icon && formData.append('icon', data.icon);
      formData.append('scheduleDetails', JSON.stringify(data.scheduleDetails));

      const response = await http.post(`/items`, formData);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Item added successfully');
      },
    },
  );
};

export const useUpdateItem = () => {
  return useMutation<string, ApiError, PutItemsI>(
    async (data: PutItemsI) => {
      const formData = new FormData();
      formData.append('name', JSON.stringify(data.name));
      formData.append('category', data.category);
      formData.append('area', data.area);
      formData.append('colorCode', data.colorCode);
      data?.icon && formData.append('icon', data.icon);
      formData.append('scheduleDetails', JSON.stringify(data.scheduleDetails));
      const response = await http.put(`/items/${data.id}`, formData);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Item updated successfully');
      },
    },
  );
};

export const useCreateOwnerItem = () => {
  return useMutation<string, ApiError, PostItemsI>(
    async (data: PostItemsI) => {
      const formData = new FormData();
      formData.append('name', JSON.stringify(data.name));
      formData.append('category', data.category);
      formData.append('area', data.area);
      formData.append('colorCode', data.colorCode);
      data?.icon && formData.append('icon', data.icon);
      formData.append('scheduleDetails', JSON.stringify(data.scheduleDetails));
      data.venueId && formData.append('venueId', data.venueId);
      const response = await http.post(`/venue-items`, formData);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Venue Item added successfully');
      },
    },
  );
};

export const useUpdateBusinessOwnerItem = () => {
  return useMutation<string, ApiError, PutItemsI>(
    async (data: PutItemsI) => {
      const formData = new FormData();
      formData.append('name', JSON.stringify(data.name));
      formData.append('category', data.category);
      formData.append('area', data.area);
      formData.append('colorCode', data.colorCode);
      data?.icon && formData.append('icon', data.icon);
      formData.append('scheduleDetails', JSON.stringify(data.scheduleDetails));
      data.venueId && formData.append('venueId', data.venueId);
      const response = await http.put(`/venue-items/${data.id}`, formData);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Item updated successfully');
      },
    },
  );
};
