import { MenuItemType, SettingsMenuI } from '../types/menu.types';

export const Menu_items: MenuItemType[] = [
  {
    displayName: 'Items',
    path: '/items',
    icon: 'items-selected.svg',
    iconUnselected: 'items.svg',
    className: 'menu.items',
  },
  {
    displayName: 'Cleaning Products',
    path: '/cleaning-products',
    icon: 'cleaning-products-selected.svg',
    iconUnselected: 'cleaning-products.svg',
    className: 'menu.dashboard',
  },

  {
    displayName: 'Labels',
    path: '/labels',
    icon: 'translate-selected.svg',
    iconUnselected: 'translate.svg',
    className: 'menu.labels',
  },
];

export const Setting_Menu: SettingsMenuI[] = [
  {
    displayName: 'Profile',
    key: 'profile',
    path: '/settings/profile',
  },
  {
    displayName: 'Change Password ',
    key: 'resetPassword ',
    path: '/settings/reset-password',
  },
  {
    displayName: 'Users',
    key: 'users',
    path: '/settings/users',
  },
  {
    displayName: 'Venues & Business hours',
    key: 'venues&BusinessHours',
    path: '/settings/venues-business-hours',
  },
  {
    displayName: 'Category',
    key: 'category',
    path: '/settings/category',
  },
  {
    displayName: 'Code',
    key: 'code',
    path: '/settings/code',
  },
];
