import React, { FC } from 'react';

import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { color, ColorProps, space, SpaceProps } from 'styled-system';

import styled from '@emotion/styled';

import theme from '../../theme';

type Props = ColorProps &
  SpaceProps &
  Omit<ButtonProps, 'shape' | 'ghost' | 'danger' | 'type'> & {
    size?: 'small' | 'middle' | 'large';
  };

const StyledButton = styled(Button)<Props>`
  color: ${theme.brandCream};
  height: 40px;
  display: flex;
  border: 1px solid ${theme.brandDarkTeal};
  background-color: ${theme.brandDarkTeal};
  align-items: center;
  border-radius: 8px;
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 590;
  line-height: 21px;
  padding: 7px 24px;
  justify-content: center;
  &:hover {
    color: ${(props) =>
      props.disabled ? theme.gray600 : theme.brandCream} !important;
    border-color: ${(props) =>
      props.disabled ? theme.gray600 : theme.brandTeal} !important;
    background: ${(props) =>
      props.disabled ? theme.gray200 : theme.brandDarkTeal} !important;
  }
  &.btn-middle {
    height: 32px;
    padding: 2px 24px;
    font-size: 15px;
    font-weight: 590;
    line-height: 20px;
  }
  &.btn-small {
    height: 22px;
    padding: 2px 24px;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
  }
  .ant-btn-default:disabled {
    cursor: not-allowed;
    border-color: ${theme.gray400};
    color: ${theme.gray600};
    background: ${theme.gray400};
    box-shadow: none;
  }
  .ant-btn-lg.ant-btn-loading {
    opacity: unset !important;
  }
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: ${theme.brandCream};
    border-color: ${theme.brandDark};
    background: ${theme.brandDark};
  }
  .ant-btn-lg.ant-btn-icon-only {
    padding-inline-start: 8px;
  }
  .btn-small.ant-btn-icon-only {
    width: 40px;
    padding-inline: 8px;
  }
  .ant-btn-sm.ant-btn-loading {
    opacity: unset !important;
  }
  .anticon {
    margin-top: 4px;
  }

  ${space}
  ${color}
`;

export const PrimaryButton: FC<Props> = ({ size = 'large', ...props }) => {
  let icon: React.ReactNode | null = null;
  let className = `btn-${size} ${props.className || ''}`;
  if (props.icon) {
    icon = <span className="btn-custom-icon-left">{props.icon}</span>;
  }
  if (props.disabled) {
    className += 'btn-disabled' + className;
  }
  const disabled = props.disabled ? props.disabled : false;

  return (
    <StyledButton
      {...props}
      size={size}
      className={className}
      icon={icon}
      disabled={disabled}>
      {props.children}
    </StyledButton>
  );
};
