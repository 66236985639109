export enum TranslationTypeEnum {
  ALL = 'ALL',
  PENDING_TRANSLATIONS = 'PENDING_TRANSLATIONS',
}

export interface ILabelModalData {
  key: string;
  description: string;
  labelEn?: string;
  labelNl?: string;
}

export interface IEditLabel {
  key: string;
  description: string;
  labelEn?: string;
  labelNl?: string;
}

export interface IEditNewLabelParams {
  oldKey: string;
  newKey: string;
  data: IEditLabel;
}
