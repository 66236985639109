import { FC } from 'react';
import { Popover } from 'antd';
import { Text } from '../typography/Text';
import {
  addDotsForLongText,
  isHaveThreeDots,
} from '../../utils/threeDotPopoverUtil';

interface Props {
  text: string;
  maxLength: number;
}

const ThreeDotPopover: FC<Props> = ({ text, maxLength }) => {
  return isHaveThreeDots(text, maxLength) ? (
    <Popover
      trigger="hover"
      placement="topLeft"
      arrow={false}
      content={
        <Text mt={'5px'} padding={'12px 16px'} type="subheading-regular">
          {text}
        </Text>
      }>
      <Text type="subheading-regular">
        {addDotsForLongText(text, maxLength)}
      </Text>
    </Popover>
  ) : (
    <Text type="subheading-regular">{text ? text : '-'}</Text>
  );
};

export default ThreeDotPopover;
