import { FC } from 'react';
import { Button } from 'antd';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Flex } from '../flex/Flex';
import { DayEnum, DaysI } from '../../containers/enums/Day.enum';
import { DAYS } from '../../configs/daysConfig';

const StyledButton = styled(Button)<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${(props) => (props.selected ? '40px' : '20px')};
  height: 40px;
  margin-right: 16px;
  text-align: center;
  box-shadow: none;
  color: ${(props) =>
    props.selected ? theme.white : theme.gray800} !important;
  background-color: ${(props) =>
    props.selected ? theme.brandTeal : 'defaultColor'};
  border-color: ${(props) => (props.selected ? theme.brandTeal : theme.white)};

  &:hover {
    background-color: ${(props) =>
      props.selected ? theme.brandTeal : 'hoverColor'}!important;
    border-color: ${(props) =>
      props.selected ? theme.brandTeal : theme.white}!important;
  }

  &:focus {
    background-color: ${(props) =>
      props.selected ? theme.brandTeal : 'hoverColor'}!important;
    border-color: ${theme.white};
  }
`;
interface Props {
  selectedDays: DayEnum[];
  setSelectedDays: any;
}

const SelectWeekDaysComponent: FC<Props> = ({
  selectedDays,
  setSelectedDays,
}) => {
  const handleDayClick = (day: DaysI) => {
    const isAlreadySelected = selectedDays.includes(day.key);
    const newSelectedDays = [...selectedDays];

    if (!isAlreadySelected) {
      newSelectedDays.push(day.key);
    } else {
      newSelectedDays.splice(selectedDays.indexOf(day.key), 1);
    }

    setSelectedDays(newSelectedDays);
  };

  return (
    <Flex>
      <Flex display={'flex'}>
        {DAYS.map((day) => (
          <StyledButton
            key={day.key}
            selected={selectedDays.includes(day.key)}
            type={selectedDays.includes(day.key) ? 'primary' : 'default'}
            onClick={() => handleDayClick(day)}>
            {day.label}
          </StyledButton>
        ))}
      </Flex>
    </Flex>
  );
};

export default SelectWeekDaysComponent;
