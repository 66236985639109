import { message } from 'antd';
import Lottie from 'react-lottie';
import starLottie from '../../assets/Lottie/Star.json';
import doneLottie from '../../assets/Lottie/Done.json';
import errorLottie from '../../assets/Lottie/Error.json';
import warningLottie from '../../assets/Lottie/Warning.json';

enum MessageTypeEnum {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

const getLottieMessageContent = (type: MessageTypeEnum, text: string) => {
  let chosenLottie = null;

  switch (type) {
    case MessageTypeEnum.SUCCESS:
      chosenLottie = doneLottie;
      break;
    case MessageTypeEnum.ERROR:
      chosenLottie = errorLottie;
      break;
    case MessageTypeEnum.WARNING:
      chosenLottie = warningLottie;
      break;
    default:
      chosenLottie = starLottie;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: chosenLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Lottie options={defaultOptions} height={20} width={20} />
      <span style={{ marginLeft: 10 }}>{text}</span>
    </div>
  );
};

export const Message = {
  success: (text: string, duration = 3) => {
    message.success({
      icon: null,
      content: getLottieMessageContent(MessageTypeEnum.SUCCESS, text),
      duration: duration,
      className: 'custom-message',
    });
  },
  info: (text: string, duration = 3) => {
    message.info({
      icon: null,
      content: getLottieMessageContent(MessageTypeEnum.INFO, text),
      duration: duration,
      className: 'custom-message',
    });
  },
  error: (text: string, duration = 3) => {
    message.error({
      icon: null,
      content: getLottieMessageContent(MessageTypeEnum.ERROR, text),
      duration: duration,
      className: 'custom-message',
    });
  },
  warning: (text: string, duration = 3) => {
    message.warning({
      icon: null,
      content: getLottieMessageContent(MessageTypeEnum.WARNING, text),
      duration: duration,
      className: 'custom-message',
    });
  },
};
