import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import { AuthPayload } from '../types/auth.types';
import { HandleContentChange } from '../types/equipmentsItems.types';

export const isAllowed = (data: AuthPayload, permissions: string) => {
  if (data !== null && data.role?.permissions) {
    const isAllowed = data?.role && data.role.permissions.includes(permissions);
    return isAllowed;
  } else {
    return false;
  }
};

export const scrollToFirstErrorField = (errorInfo: ValidateErrorEntity) => {
  const { errorFields } = errorInfo;
  const errorInput: HTMLElement | null = document.getElementById(
    `${errorFields[0].name}`,
  );
  if (errorInput) {
    errorInput.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};

export const getHeight = (selectedCheckboxes: number): string => {
  switch (selectedCheckboxes) {
    case 1:
      return '900px';
    case 2:
      return '950px';
    case 3:
      return '1000px';
    case 4:
      return '1100px';
    default:
      return '0px';
  }
};

export const getMediaHeight = (
  selectedCheckboxes: number,
  maxWidth: number,
): string => {
  switch (maxWidth) {
    case 1100:
      switch (selectedCheckboxes) {
        case 1:
          return '900px';
        case 2:
          return '950px';
        case 3:
          return '1000px';
        case 4:
          return '1100px';
        default:
          return '0px';
      }
    case 995:
      switch (selectedCheckboxes) {
        case 1:
          return '1600px';
        case 2:
          return '1650px';
        case 3:
          return '1700px';
        case 4:
          return '1750px';
        default:
          return '0px';
      }
    case 480:
      switch (selectedCheckboxes) {
        case 1:
          return '1600px';
        case 2:
          return '1650px';
        case 3:
          return '1700px';
        case 4:
          return '1770px';
        default:
          return '0px';
      }
    default:
      return '0px';
  }
};

export const isFormDirty = (data: HandleContentChange) => {
  return data?.content && data?.content !== '' ? true : false;
};
