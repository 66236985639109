import * as React from 'react';
import { Pagination, PaginationProps } from 'antd';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Flex } from '../flex/Flex';
import { PaginationLeft, PaginationRight } from '../../assets';

interface IAppProps {
  current: number;
  total?: number;
  pageSize?: number;
  setPage?: (page: number) => void;
  setPageSize?: (size: number) => void;
}

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  padding: 12px;
  background: ${theme.white};
  border-radius: 0px 0px 8px 8px;
  border-top: 0.5px solid ${theme.gray400};
  align-items: center;
  .ant-pagination-item {
    border: none;
    min-width: unset;
    height: unset;
  }
  .ant-pagination-item {
    width: 32px;
    height: 32px;
    padding: 4px 4px 5px 4px;
    border-radius: 4px;
    border: 1px solid ${theme.gray400};
    background: transparent;
  }
  .ant-pagination-item a {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: ${theme.brandDarkTeal};
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    display: flex;
  }
  .ant-pagination-item-ellipsis {
    font-size: 10px;
    color: ${theme.gray700} !important;
    line-height: 40px;
  }
  .ant-pagination-item-ellipsis:hover {
    opacity: 1;
  }
  .ant-pagination-item-link-icon {
    opacity: 0 !important;
  }
`;

const PaginationComp: React.FunctionComponent<IAppProps> = ({
  current,
  setPage,
  setPageSize,
  total,
  pageSize,
}) => {
  const itemRender: PaginationProps['itemRender'] = (
    _,
    type,
    originalElement,
  ) => {
    if (type === 'prev') {
      return (
        <Flex alignItems="center" className="section">
          <PaginationLeft />
        </Flex>
      );
    }
    if (type === 'next') {
      return (
        <Flex alignItems="center" className="section">
          <PaginationRight />
        </Flex>
      );
    }
    return originalElement;
  };
  return (
    <StyledPagination
      current={current}
      defaultPageSize={pageSize}
      onChange={(page: number, pageSize: number) => {
        console.log('pageSize', pageSize);
      }}
      total={total}
      itemRender={itemRender}
      showSizeChanger={false}
    />
  );
};

export default PaginationComp;
