import { FC, ReactNode } from 'react';
import { Col, Flex, Row } from 'antd';
import styled from '@emotion/styled';

import { Image } from '../../../components';
import { LoginLayoutBackground, LogoTealSVGV2 } from '../../../assets';

const AuthLayoutForm = styled(Flex)`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 720px) {
    padding: 24px;
  }
`;
const Content = styled(Flex)`
  position: absolute;
  z-index: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100vh;
  position: absolute;
  object-fit: cover;
`;

interface Props {
  children: ReactNode;
}
const AuthLayout: FC<Props> = ({ children }) => (
  <div>
    <Row style={{ height: '100vh' }}>
      <Col xs={0} sm={0} md={0} lg={0} xl={10} xxl={10}>
        <Content justify={'center'} align={'center'}>
          <Image src={LogoTealSVGV2} mb="32px" />
        </Content>
        <BackgroundImage src={LoginLayoutBackground} />
      </Col>{' '}
      <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
        <AuthLayoutForm justify={'center'} align={'center'}>
          {children}
        </AuthLayoutForm>
      </Col>{' '}
    </Row>
  </div>
);

export default AuthLayout;
