import styled from '@emotion/styled';
import ReactQuill from 'react-quill';
import React, { FC, useState } from 'react';
import { Flex } from '../../components';
import 'react-quill/dist/quill.snow.css';
import theme from '../../theme';
import { Image, Text } from '../../components';
import { Nederlanden, UnitedStates } from '../../assets';
import { removeHtmlTags } from '../../utils/textUtil';

interface TextEditorProps {
  title: string;
  language: string;
  schedule: string;

  onContentChange?: (data: {
    title: string;
    language: string;
    schedule: string;
    content: string;
  }) => void;

  placeholder: string;
  initialValue?: string;
  height?: string;
  hideToolBar?: boolean;
  noRichText?: boolean;
}

interface EditorPropsI {
  height?: string;
  hideToolBar?: boolean;
}

const CusTitleBar = styled(Flex)`
  border-top: 1px solid ${theme.gray400};
  border-right: 1px solid ${theme.gray400};
  border-left: 1px solid ${theme.gray400};
  border-bottom-left-radius: 0px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: ${theme.white};
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
`;

const StyledImage = styled(Image)`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

const Editor = styled(ReactQuill)<EditorPropsI>`
  .ql-editor {
    height: ${(props) => (props.height ? props.height : '183px')} !important;
  }
  .ql-toolbar.ql-snow {
    display: ${(props) => (props.hideToolBar ? 'none' : 'block')} !important;
  }
  .ql-container.ql-snow {
    border-top: ${(props) =>
      props.hideToolBar ? `1px solid ${theme.gray400}` : 'none'} !important;
  }
  .ql-editor ol,
  .ql-editor ul {
    margin: 1em !important;
    padding-left: 0px !important;
  }

  .ql-container.ql-snow {
    border: 1px solid ${theme.gray400};
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  .ql-toolbar.ql-snow {
    border: 1px solid ${theme.gray400} !important;
  }
`;

export const QuillTextEditor: FC<TextEditorProps> = ({
  title,
  language,
  schedule,
  onContentChange,
  initialValue,
  placeholder,
  height,
  hideToolBar,
  noRichText,
}) => {
  const [value, setValue] = useState<string | undefined>(initialValue);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const modulesWithNoToolBar = {
    toolbar: [],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'video',
    'align',
  ];

  const handleOnBlur = () => {
    if (value && onContentChange) {
      let content = value;
      if (noRichText) {
        content = removeHtmlTags(value);
      }
      onContentChange({ title, language, content, schedule });
    }
  };

  return (
    <>
      <CusTitleBar>
        <Flex display={'flex'} flexDirection={'row'}>
          <StyledImage src={language == 'nl' ? Nederlanden : UnitedStates} />
          <Text type="footnote-regular">
            {language == 'nl' ? 'NL - Dutch' : 'US - Eng'}
          </Text>
        </Flex>
      </CusTitleBar>
      <Flex onBlur={handleOnBlur}>
        <Editor
          theme={'snow'}
          onChange={setValue}
          value={value}
          modules={hideToolBar ? modulesWithNoToolBar : modules}
          formats={hideToolBar ? [] : formats}
          placeholder={placeholder}
          height={height}
          hideToolBar={hideToolBar}
        />
      </Flex>
    </>
  );
};
