import { useMutation, useQuery } from 'react-query';
import { http } from './_apiService';
import { ApiError } from './_types';
import { AxiosError } from 'axios';
import { OrgVenuesI, VenueFormI } from '../types/venue.types';

export const useGetVenues = () =>
  useQuery<OrgVenuesI[], AxiosError>('getVenues', async () => {
    const response = await http.get('/users/owner-venues');
    return response.data;
  });

export const useUpdateBusinessHours = () =>
  useMutation<string, ApiError, VenueFormI>(async (data: VenueFormI) => {
    const { id, ...businessHourData } = data;
    const response = await http.put(
      `/venue/business-hours/${id}`,
      businessHourData,
    );
    return response.data;
  });
