export enum PermissionEnum {
  //SUPER ADMIN FEATURE
  SUPER_ADMIN_FEATURE = 'SUPER_ADMIN_FEATURE',

  //USER ME
  USER_ME = 'USER_ME',

  //LABELS
  MANAGE_LABELS = 'MANAGE_LABELS',

  //ITEMS
  MANAGE_CC_ITEMS = 'MANAGE_CC_ITEMS',
  MANAGE_BUSINESS_ITEMS = 'MANAGE_BUSINESS_ITEMS',

  //CLEANING PRODUCTS
  MANAGE_CLEANING_PRODUCTS = 'MANAGE_CLEANING_PRODUCTS',

  //USERS
  MANAGE_USERS = 'MANAGE_USERS',

  //BUSINESS HOURS
  EDIT_BUSINESS_HOURS = 'EDIT_BUSINESS_HOURS',

  //CATEGORY
  MANAGE_CATEGORY = 'MANAGE_CATEGORY',

  //CODE
  MANAGE_CODE = 'MANAGE_CODE',
}
