import React, { useEffect, useState } from 'react';
import TableComponent from '../../../components/table/TableComponent';
import {
  Dropdown,
  Flex as AntFlex,
  Menu,
  TableColumnsType,
  Popover,
  Divider,
  Spin,
} from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import { MenuThreeDots } from '../../../assets';
import EditWorkingHoursModal from './EditWorkingHoursModal';
import SettingsLayout from '../settingsLayout/SettingsLayout';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { Sort } from '../../../assets';
import { Flex, Text } from '../../../components';
import { tableSorter } from '../../../utils/tableSorter';
import { useGetVenues, useUpdateBusinessHours } from '../../../api/venueHooks';
import { OrgVenuesI, VenueFormI } from '../../../types/venue.types';
import { getOpenDaysCategory } from '../../../utils/venueUtil';
import { InfoCircleOutlinedSVG } from '../../../assets';
import { OpenDayCategories } from '../../enums/Venue.enum';
import { getPascalCaseUtil } from '../../../utils/caseUtil';
import { DayEnum } from '../../enums/Day.enum';
import dayjs from 'dayjs';
import { useAuth } from '../../../providers/auth-context';
import { StatusTag } from '../../../components/tags/StatusTag';
import { Message } from '../../../components/message/Message';

interface TextPropsI {
  disabled: boolean;
}

const FlexComp = styled(AntFlex)`
  border-bottom: 1px solid var(--Button-Fill, ${theme.gray400});
  padding: 24px 24px 16px 24px;
`;

const InfoImg = styled.img`
  height: 13px;
  width: 13px;
  margin-left: 8px;
`;

const OpenDaysData = styled(Flex)`
  display: flex;
  align-items: center;
  .ant-popover-content .ant-popover-inner {
    margin: 0px !important;
  }
`;

const StyledPopOverContent = styled.div`
  .ant-popover-inner {
    padding: 0px !important;
  }
  margin-top: 7px !important;
  width: 140px !important;
  padding: 0px !important;
`;

const StyledDivider = styled(Divider)`
  margin: 0px !important;
`;

const StyledText = styled(Text)<TextPropsI>`
  color: ${(props) => (props.disabled ? `${theme.gray800}` : `${theme.black}`)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const NameFlex = styled(Flex)`
  display: flex;
  align-items: center;
`;

const DisabledTagDiv = styled.div`
  margin-left: 8px;
`;

const VenuesAndWorkingHours: React.FC = () => {
  const [selectedVenue, setSelectedVenue] = useState<OrgVenuesI>();
  const [disabledVenues, setDisabledVenues] = useState<string[]>([]);
  const {
    refetch: getVenues,
    data: venues,
    isLoading: isLoadingGetVenues,
  } = useGetVenues();
  const currentYear = dayjs().year();

  const {
    mutate: updateBusinessHours,
    data: updatedBusinessHours,
    error: errorUpdatingBusinessHours,
    isSuccess: isSuccessUpdateBusinessHours,
  } = useUpdateBusinessHours();

  const { currentUser } = useAuth();

  useEffect(() => {
    getVenues();
  }, [updatedBusinessHours]);

  useEffect(() => {
    if (errorUpdatingBusinessHours) {
      Message.error(errorUpdatingBusinessHours.msg);
    }
  }, [errorUpdatingBusinessHours]);

  useEffect(() => {
    if (updatedBusinessHours && isSuccessUpdateBusinessHours) {
      Message.success('Business hours updated successfully!');
    }
  }, [updatedBusinessHours, isSuccessUpdateBusinessHours]);

  useEffect(() => {
    if (currentUser && venues) {
      const { venueLimit } = currentUser.organization;
      if (venues.length > venueLimit) {
        const disabledVenueIDs = venues
          .slice(venueLimit)
          .map((venue) => venue.id);
        setDisabledVenues(disabledVenueIDs);
      }
    }
  }, [currentUser]);

  const getPopOverContent = (value: DayEnum[]) => {
    return (
      <StyledPopOverContent>
        {value.map((val, index) => {
          return (
            <React.Fragment key={index}>
              <Text
                type="subheading-regular"
                key={index}
                pl={'16px'}
                pt={'12px'}
                pb={'12px'}>
                {getPascalCaseUtil(val)}
              </Text>
              <StyledDivider />
            </React.Fragment>
          );
        })}
      </StyledPopOverContent>
    );
  };

  const columns: TableColumnsType<VenueFormI> = [
    {
      title: (
        <Flex display="flex" alignItems="center">
          <Text type={'footnote-regular'}>Venue</Text>
          <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'name',
      width: 250,
      render: (value, data) => (
        <NameFlex>
          <StyledText
            disabled={disabledVenues.includes(data.id)}
            type="subheading-regular">
            {getPascalCaseUtil(value)}
          </StyledText>
          {disabledVenues.includes(data.id) && (
            <Popover
              arrow={false}
              placement="bottomLeft"
              content={
                <Text
                  mt={'5px'}
                  padding={'12px 16px'}
                  type="subheading-regular">
                  Upgrade your subscription package
                </Text>
              }>
              <DisabledTagDiv>
                {StatusTag({
                  tagColor: theme.gray300,
                  text: 'Disabled',
                  size: 'large',
                })}
              </DisabledTagDiv>
            </Popover>
          )}
        </NameFlex>
      ),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.name, b.name),
      },
    },
    {
      title: <Text type={'footnote-regular'}>Open Days</Text>,
      dataIndex: 'openDays',
      width: 100,
      render: (value, data) => (
        <>
          {(() => {
            const openDaysCategory = getOpenDaysCategory(value);
            if (openDaysCategory === OpenDayCategories.CUSTOM) {
              return (
                <OpenDaysData>
                  <Text type="subheading-regular">
                    {getPascalCaseUtil(openDaysCategory)}{' '}
                  </Text>
                  <Popover
                    arrow={false}
                    placement="bottomLeft"
                    content={getPopOverContent(value)}>
                    <InfoImg src={InfoCircleOutlinedSVG} />
                  </Popover>
                </OpenDaysData>
              );
            }
            return (
              <StyledText
                disabled={disabledVenues.includes(data.id)}
                type="subheading-regular">
                {getPascalCaseUtil(openDaysCategory)}
              </StyledText>
            );
          })()}
        </>
      ),
    },
    {
      title: <Text type={'footnote-regular'}>Weekly Cleaning Day</Text>,
      dataIndex: 'weeklyCleaningDay',
      width: 150,
      render: (value, data) => (
        <StyledText
          disabled={disabledVenues.includes(data.id)}
          type="subheading-regular">
          {getPascalCaseUtil(value)}
        </StyledText>
      ),
    },
    {
      title: <Text type={'footnote-regular'}>Monthly Cleaning Day</Text>,
      dataIndex: 'monthlyCleaningDay',
      width: 150,
      render: (value, data) => (
        <StyledText
          disabled={disabledVenues.includes(data.id)}
          type="subheading-regular">
          {value}
        </StyledText>
      ),
    },
    {
      title: <Text type={'footnote-regular'}>Annual Cleaning Day</Text>,
      dataIndex: 'annualCleaningDay',
      width: 150,
      render: (value, data) => (
        <StyledText
          disabled={disabledVenues.includes(data.id)}
          type="subheading-regular">
          {dayjs(`${value}-${currentYear}`, 'DD-MM-YYYY').format('MMMM DD')}
        </StyledText>
      ),
    },
    {
      title: ' ',
      dataIndex: 'id',
      width: 100,
      render: (value) =>
        disabledVenues.includes(value) ? null : (
          <Dropdown
            dropdownRender={() => (
              <Menu>
                <MenuItem
                  key="edit"
                  onClick={() => {
                    const selectedVal = venues?.find(
                      (venue) => venue.id === value,
                    );
                    setSelectedVenue(selectedVal);
                  }}>
                  Edit
                </MenuItem>
              </Menu>
            )}>
            <Flex textAlign={'center'}>
              <img src={MenuThreeDots} />
            </Flex>
          </Dropdown>
        ),
    },
  ];

  return (
    <>
      <SettingsLayout>
        <FlexComp>
          <Text type="body-bold">Venues and Working Hours</Text>
        </FlexComp>
        <Spin size="large" spinning={isLoadingGetVenues}>
          <Flex overflow="auto">
            <TableComponent
              columns={columns}
              dataSource={venues}
              scroll={{
                y: window.innerHeight - 300,
              }}
              withBorders={true}
            />
          </Flex>
        </Spin>
      </SettingsLayout>
      {selectedVenue && (
        <EditWorkingHoursModal
          visible={selectedVenue ? true : false}
          data={selectedVenue}
          onCancel={() => setSelectedVenue(undefined)}
          onSubmit={(formValues: VenueFormI) => updateBusinessHours(formValues)}
          loading={false}
        />
      )}
    </>
  );
};

export default VenuesAndWorkingHours;
