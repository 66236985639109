/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { getApiError } from './_apiUtils';
import { getStoredAuthToken } from '../utils/storageUtil';
import { BASE_URL } from '../configs/appConfig';

const getBearToken = (token?: string | null) =>
  token ? `Bearer ${token}` : null;

const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept-Language': 'nl',
  },
});
// In here, we handle the api error-----------
http.interceptors.response.use(undefined, (error) =>
  Promise.reject({
    msg: getApiError(error),
    status: error.response.status || 500,
  }),
);

http.interceptors.request.use((config: any) => {
  // (config as any).withCredentials = true;
  (config as any).headers.authorization = getBearToken(getStoredAuthToken());
  return config;
});

export { http, getApiError };
