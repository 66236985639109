import React, { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Text } from '../typography/Text';
import { Flex } from '../flex/Flex';
import { yellowInfoCircle } from '../../assets';

const StyledModal = styled(Modal)`
  width: 520px !important;
  .ant-modal-content {
    border-radius: 8px !important;
    padding: 0px;
  }
  .ant-modal-body {
    padding: 24px 16px !important;
  }

  .ant-modal-header {
    margin: 0px !important;
  }

  .ant-modal-title {
    padding: 16px !important;
    margin: 0px !important;
    border-bottom: 1px solid ${theme.gray300};
  }

  .ant-modal-footer {
    padding: 16px 16px;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid ${theme.gray300};
    margin: 0px;
  }

  @media screen and (max-width: 768px) {
    .ant-modal-body {
      padding: 24px;
    }
  }
`;

const CancelButton = styled(Button)`
  background-color: ${theme.white} !important;
  border-color: ${theme.brandDarkTeal} !important;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid ${theme.brandDarkTeal};
  height: 40px;
  width: 100px;
  color: ${theme.brandDarkTeal};
  &:hover {
    color: ${theme.brandDarkTeal} !important;
  }
`;

interface Props {
  content: string;
  title: string;
  items: string[];
  onCancel?: () => void;
  open: boolean;
}
export const RemoveCategoriesModal: FC<Props> = ({
  content,
  title,
  items,
  open,
  onCancel,
}) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  useEffect(() => {
    if (items && items.length <= 5) setShowAll(true);
  }, []);

  return (
    <StyledModal
      open={open}
      width="400px"
      closable={false}
      title={
        <div>
          <Text type="body-bold" color={theme.black}>
            {title}
          </Text>
        </div>
      }
      footer={[
        <>
          <CancelButton
            key="submit"
            id="delete-button"
            onClick={onCancel}
            color={theme.brandDarkTeal}>
            Cancel
          </CancelButton>
        </>,
      ]}>
      <Flex backgroundColor={theme.red} width={48} height={48} mb={16}>
        <img src={yellowInfoCircle} />
      </Flex>
      <Flex>
        <Text type="subheading-bold">{content}</Text>
      </Flex>
      <Flex mt={16}>
        {showAll && items.length >= 5 ? (
          <Flex height={'170px'} overflow={'auto'}>
            {items?.map((item, index) => {
              return (
                <Text type="subheading-regular" key={index} mb={'8px'}>
                  {item}
                </Text>
              );
            })}
          </Flex>
        ) : (
          <Flex>
            {items?.slice(0, 5).map((item, index) => (
              <Text type="subheading-regular" key={index} mb={'8px'}>
                {item}
              </Text>
            ))}
            {!showAll && (
              <Text
                type="subheading-regular"
                color={theme.brandTeal}
                onClick={() => setShowAll(true)}>
                See more
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </StyledModal>
  );
};
