import { Header } from 'antd/es/layout/layout';
import { LogoTealSVG_V1, LogoTealSVG_V3, brokenLink } from '../../assets';
import styled from '@emotion/styled';
import theme from '../../theme';
import { FC } from 'react';

const TopMenu = styled(Header)`
  display: flex;
  align-items: center;
  height: 68px;
  padding: 0 32px;
  position: fixed;
  right: 0;
  left: 0;
  transition: width 0.2s;
  z-index: 1000;
  top: 0;
  background: ${theme.brandDarkTeal} !important;
`;
const Logo = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
const MobileLogo = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

const MinimalNavBar: FC = () => {
  return (
    <>
      <TopMenu>
        <Logo className="logo">
          <img src={LogoTealSVG_V1} width={167} height={24} />
        </Logo>
        <MobileLogo className="mobile_logo">
          <img src={LogoTealSVG_V3} height={24} />
        </MobileLogo>
      </TopMenu>
      ;
    </>
  );
};

export default MinimalNavBar;
