import { FC, ReactNode } from 'react';
import { Modal, ModalFuncProps, ModalProps } from 'antd';
import { FormInstance } from 'antd/es/form';
import { SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import { Flex, PrimaryButton, SecondaryButton, H } from '../';
import theme from '../../theme';

type Props = SpaceProps &
  ModalProps & {
    onCancel: () => void;
    onSubmit?: () => void;
    visible?: boolean;
    header: string | React.ReactElement;
    children: ReactNode;
    width?: number;
    primaryBtnLabel?: string;
    secondaryBtnLabel?: string;
    hideCancel: boolean;
    form?: FormInstance;
    loadingBtn?: boolean;
    disabled?: boolean;
    primaryBtnSize?: 'small' | 'middle' | 'large';
    secondaryBtnSize?: 'small' | 'middle' | 'large';
    maskClosable?: boolean;
  };

const StyledModal = styled(Modal)<ModalProps | ModalFuncProps>`
  .ant-modal-body {
    padding: 24px 16px 0px;
  }
  .ant-modal-header {
    padding: 16px;
    margin-bottom: 0px;
    border-bottom: 1px solid ${theme.gray300};
  }
  .ant-modal-content {
    background: ${theme.white};
    box-shadow:
      0px 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 8px !important;
    padding: 0px !important;
  }
  .ant-modal-footer {
    padding: 16px;
    margin-top: 0px;
    border-top: 1px solid ${theme.gray300};
  }
`;

const ModalComponent: FC<Props> = ({
  onCancel,
  onSubmit,
  visible,
  header,
  children,
  width,
  primaryBtnLabel,
  hideCancel,
  secondaryBtnLabel,
  loadingBtn,
  form,
  disabled,
  primaryBtnSize,
  secondaryBtnSize,
  maskClosable,
}) => {
  const onOk = () => {
    form && form.submit();
  };

  return (
    <>
      <StyledModal
        footer={
          <Flex justifyContent="flex-end" display="flex">
            <SecondaryButton
              onClick={onCancel}
              size={secondaryBtnSize}
              hidden={hideCancel}
              marginRight="16px">
              {secondaryBtnLabel ? secondaryBtnLabel : 'Cancel'}
            </SecondaryButton>

            <PrimaryButton
              disabled={disabled}
              size={primaryBtnSize}
              loading={loadingBtn}
              htmlType="submit"
              onClick={onSubmit || onOk}>
              {primaryBtnLabel ? primaryBtnLabel : 'Save'}
            </PrimaryButton>
          </Flex>
        }
        title={<H type="headlineBold">{header}</H>}
        open={visible}
        onOk={onSubmit}
        onCancel={onCancel}
        width={width}
        maskClosable={maskClosable}
        closable={false}>
        {children}
      </StyledModal>
    </>
  );
};

export default ModalComponent;
