import { useMutation, useQuery } from 'react-query';
import { ColorCodeI } from '../types/colorCode.types';
import { http } from './_apiService';
import { ApiError } from './_types';
import { Message } from '../components/message/Message';

export const useGetCodes = () =>
  useQuery<ColorCodeI[], ApiError>(
    'getCodes',
    async () => {
      const response = await http.get('/item-color-code');
      return response.data;
    },
    {
      enabled: false,
    },
  );

export const useCreateCode = () =>
  useMutation<string, ApiError, ColorCodeI>(
    async (data: ColorCodeI) => {
      const response = await http.post('/item-color-code', data);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
      onSuccess: () => {
        Message.success('Code added successfully');
      },
    },
  );

export const useUpdateCode = () =>
  useMutation<string, ApiError, { id: string; data: ColorCodeI }>(
    async ({ id, data }) => {
      const response = await http.put(`/item-color-code/${id}`, data);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
      onSuccess: () => {
        Message.success('Code updated successfully');
      },
    },
  );

export const useDeleteCode = () =>
  useMutation<string, ApiError, string>(
    async (id) => {
      const response = await http.delete(`/item-color-code/${id}`);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
      onSuccess: () => {
        Message.success('Code deleted successfully');
      },
    },
  );

export const useGetItemsForCode = () =>
  useMutation<string[], ApiError, string>(
    async (id) => {
      const response = await http.get(`/item-color-code/existing-items/${id}`);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
    },
  );
