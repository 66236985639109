import React, { FC } from 'react';

import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Text } from '../';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
type Props = SpaceProps &
  PhoneInputProps & {
    label?: string;
    hint?: string;
    flexDirection?: string;
    size?: 'small' | 'large';
    status?: 'error' | 'warning';
  };

const StyledInput = styled(PhoneInput)<Props>`
  .form-control {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: ${(props) =>
      props.status && props.status.length > 0
        ? `1px solid ${theme.red}`
        : `0.5px solid ${theme.gray400}`};

    color: ${(props) =>
      props.disabled ? theme.disabledInputText : theme.black};
    background: ${(props) => (props.disabled ? theme.gray100 : theme.white)};
  }
  .form-control:hover {
    border-color: ${theme.gray600} !important;
  }
  .react-tel-input:hover {
    border-color: ${theme.gray600};
    box-shadow: unset;
  }
  .flag-dropdown {
    border: 0.5px solid ${theme.gray400};
    border-radius: 8px 0 0 8px;
    background-color: ${(props) =>
      props.disabled ? theme.gray100 : theme.white}!important;
  }
  .selected-flag {
    border-radius: 8px 0 0 8px;
    border: ${(props) =>
      props.status && props.status.length > 0
        ? `1px solid ${theme.red}`
        : `0.5px solid ${theme.gray400}`};
  }

  ${space};
`;

export const PhoneNumberInput: FC<Props> = (props) => {
  const {
    label,
    hint,
    flexDirection = 'column',
    size = 'large',
    status,
  } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {label && (
        <Text type="subheading-regular" color={theme.black} mb="6px">
          {label}
        </Text>
      )}
      <StyledInput flexDirection={flexDirection} size={size} {...props} />
      {hint && (
        <Text
          type={'caption2-regular'}
          color={status ? theme.red : theme.gray600}
          mt="6px">
          {hint}
        </Text>
      )}
    </div>
  );
};
