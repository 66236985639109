import React from 'react';
import TextArea from 'antd/lib/input/TextArea';

interface TextAreaProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

const CustomTextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder,
  disabled,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <TextArea
      value={value}
      onChange={handleInputChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default CustomTextArea;
