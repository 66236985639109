import { Flex } from 'antd';
import { FC } from 'react';
import { Text } from '../typography/Text';
import styled from '@emotion/styled';
import theme from '../../theme';

interface Props {
  text?: string;
  type?: 'success' | 'error' | 'warning';
  style?: string;
  textColor?: string;
}

const OuterComp = styled(Flex)<Props>`
  ${(props) => props.style};
  height: 34px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.type === 'success'
      ? theme.brandTeal10
      : props.type === 'error'
        ? theme.red10
        : theme.yellow10};
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.textColor
      ? props.textColor
      : props.type === 'success'
        ? theme.brandTeal
        : props.type === 'error'
          ? theme.red
          : theme.yellow};
`;

const TypeIndicator = styled.span<Props>`
  border-radius: 16px 0px 0px 16px;
  width: 4px;
  height: 32px;
  left: 0px;
  top: 50%;
  background: ${(props) =>
    props.type === 'success'
      ? theme.brandTeal
      : props.type === 'error'
        ? theme.red
        : theme.yellow};
`;

export const AlertMessage: FC<Props> = (props) => {
  const { text, type = 'warning', textColor } = props;

  return (
    <OuterComp text={text} type={type} textColor={textColor}>
      <TypeIndicator type={type} />
      <Flex>
        <Text type="regular" ml={'8px'}>
          {text}
        </Text>
      </Flex>
    </OuterComp>
  );
};
