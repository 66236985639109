import { Col, Form, Row } from 'antd';
import { FC, useEffect } from 'react';
import ModalComponent from '../../../components/modals/ModalComponent';
import { ILabelModalData } from '../../../types/TranslationTypes';
import { Image } from '../../../components';
import styled from '@emotion/styled';
import { keyNameRules } from '../validations/labelValidation';
import { Flex, FormContainer, Input } from '../../../components';
import { Nederlanden, UnitedStates } from '../../../assets';
import { useAuth } from '../../../providers/auth-context';
import CustomTextArea from '../../../components/TextArea/CustomTextArea';

const StyledLabelImage = styled(Image)`
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
`;

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (formValues: ILabelModalData) => void;
  loading: boolean;
  data?: ILabelModalData;
  refetchData: () => void;
  selectedLabel?: ILabelModalData | null;
}

const AddEditLabelsModal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  loading,
  refetchData,
  data,
  selectedLabel,
}) => {
  const [form] = Form.useForm();

  const { currentUser: userData, isLoading: authLoading } = useAuth();

  const onFinish = (formValues: ILabelModalData) => {
    onCancel();
    onSubmit(formValues);
  };

  useEffect(() => {
    if (visible) {
      if (selectedLabel) {
        form.setFieldsValue({
          key: selectedLabel.key,
          labelEn: selectedLabel.labelEn,
          labelNl: selectedLabel.labelNl,
          description: selectedLabel.description,
        });
      } else {
        form.resetFields();
      }
    }
  }, [visible, selectedLabel]);

  const onOk = () => {
    form && form.submit();
  };

  return (
    <ModalComponent
      onCancel={onCancel}
      closable={true}
      width={524}
      hideCancel={false}
      header={selectedLabel ? 'Edit Label' : 'Add Label'}
      primaryBtnLabel={selectedLabel ? 'Update' : 'Create Key'}
      visible={visible}
      onSubmit={onOk}
      loadingBtn={loading}>
      <Flex width={'100%'} display="">
        <FormContainer
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          onFinish={(v) => onFinish(v)}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Key Name *" name="key" rules={keyNameRules}>
                <Input
                  placeholder="Add a Key Name"
                  disabled={!userData?.isLabelAdmin}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <Flex display="flex" alignItems="center">
                    <StyledLabelImage src={UnitedStates} />
                    US-Eng *
                  </Flex>
                }
                name="labelEn"
                rules={[
                  {
                    required: true,
                    message: 'Please input English label!',
                  },
                ]}>
                <Input placeholder="Label in English" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <Flex display="flex" alignItems="center">
                    <StyledLabelImage src={Nederlanden} />
                    NL-Dutch
                  </Flex>
                }
                name="labelNl">
                <Input placeholder="Label in Dutch" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Description" name="description">
                <CustomTextArea
                  placeholder="Add description"
                  disabled={!userData?.isLabelAdmin}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormContainer>
      </Flex>
    </ModalComponent>
  );
};
export default AddEditLabelsModal;
