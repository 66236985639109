import styled from '@emotion/styled';
import { Dropdown, Menu } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logout, Settings, WhiteDropdown } from '../../assets';
import { AvatarComponent, Flex, Text } from '../../components';
import { useAuth } from '../../providers/auth-context';
import theme from '../../theme';
import { getPascalCaseUtil } from '../../utils/caseUtil';
import { addDotsForLongText } from '../../utils/threeDotPopoverUtil';

const MenuItem = styled(Menu.Item)`
  border-top: 0.5px solid ${theme.gray300};
  .ant-dropdown-menu-title-content {
    padding: 5px 80px 5px 4px;
  }
`;
const SettingsMenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content {
    padding: 5px 80px 5px 4px;
  }
`;

const StyledFlex = styled(Flex)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
  margin: 0px auto 0px 0px;

  @media screen and (max-width: 760px) {
    width: fit-content !important;
  }
`;

const StyledName = styled(Text)`
  color: ${theme.white};

  @media screen and (max-width: 760px) {
    color: ${theme.brandDarkTeal};
  }
`;

const Profile: FC = () => {
  const navigate = useNavigate();
  const { logout, currentUser } = useAuth();

  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={() => (
        <Menu>
          <SettingsMenuItem onClick={() => navigate('settings/profile')}>
            <Flex display={'flex'}>
              <Settings />
              <Text type="subheading-regular" ml="8px" color={theme.black}>
                Settings
              </Text>
            </Flex>
          </SettingsMenuItem>

          <MenuItem
            onClick={() => {
              logout();
              navigate('/login');
            }}>
            <Flex display={'flex'}>
              <Logout />
              <Text type="subheading-regular" ml="8px" color={theme.red}>
                Logout
              </Text>
            </Flex>
          </MenuItem>
        </Menu>
      )}>
      <StyledFlex>
        <img src={WhiteDropdown} />
        <Flex p={'8px'} pb={'4px'} display="flex" flexDirection="column">
          <StyledName type="footnote-regular" color={theme.white}>
            {currentUser
              ? addDotsForLongText(
                  currentUser?.firstName + ' ' + currentUser?.lastName,
                  18,
                )
              : '-'}
          </StyledName>
          <Text type="caption1-regular" color={theme.brandTeal}>
            {currentUser?.role.role
              ? getPascalCaseUtil(currentUser?.role.role)
              : '-'}
          </Text>
        </Flex>
        <AvatarComponent
          src={currentUser?.profilePicture}
          size={40}
          mr="10px"
          shape="circle"
          title={currentUser?.firstName + ' ' + currentUser?.lastName}
        />
      </StyledFlex>
    </Dropdown>
  );
};

export default Profile;
