export const keyNameRules = [
  { required: true, message: 'Please input a Key Name!' },
  {
    pattern: /^[a-z].*[a-z]$/,
    message:
      'Key name cannot be end or start with a period or cannot include numbers!',
  },
  {
    validator: (_: any, value: string) => {
      const words = value.split('.');
      for (let word of words) {
        if (/^[A-Z]/.test(word) || /[A-Z]$/.test(word)) {
          return Promise.reject(
            'A word after a period cannot start or end with an uppercase letter!',
          );
        }
      }
      return Promise.resolve();
    },
  },
  {
    pattern: /^(?!.*\.\.).*$/,
    message: 'Key Name cannot have consecutive periods!',
  },
  {
    pattern: /^[a-zA-Z.]*$/,
    message:
      'Key Name cannot contain special characters other than a dot or a letter!',
  },
];
