import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLogoutUser } from '../api/authHooks';
import { useGetMe } from '../api/userHooks';
import { AuthPayload } from '../types/auth.types';
import { UserMeResponseI, UserRoleIdEnum } from '../types/user.types';
import {
  getAuthPayloads,
  getStoredAuthToken,
  setAuthPayloads,
  storeAuthToken,
} from '../utils/storageUtil';

type AuthProviderProps = { children: React.ReactNode };

const AuthStateContext = createContext<State | undefined>(undefined);
type State = {
  isLoggedIn: boolean;
  currentUser: UserMeResponseI | null;
  setToken: (token: string) => void;
  setPermission: (permission: AuthPayload) => void;
  logout: () => void;
  isUserRole: (roles: UserRoleIdEnum | UserRoleIdEnum[]) => boolean;
  isLoading: boolean;
  permission: AuthPayload;
  fetchUser: () => void;
};
const useAuth = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, storeCurrentUser] = useState<UserMeResponseI | null>(
    null,
  );
  const [token, setToken] = useState<string | null>(getStoredAuthToken());
  const [permission, setPermission] = useState<any>(getAuthPayloads());

  const queryClient = useQueryClient();
  const { data: userData, refetch, isLoading: isLoading, error } = useGetMe();

  const { mutate: logoutUser, isSuccess: isLogoutSuccess } = useLogoutUser();

  const logout = async () => {
    logoutUser();
  };

  useEffect(() => {
    if (isLogoutSuccess) {
      queryClient.clear();
      queryClient.resetQueries();
      storeCurrentUser(null);
      setToken(null);
      localStorage.clear();
    }
  }, [isLogoutSuccess]);

  const fetchUser = () => {
    refetch();
  };

  useEffect(() => {
    if (token) {
      storeAuthToken(token);
      setAuthPayloads(permission);
      fetchUser();
    }
  }, [token, refetch]);

  useEffect(() => {
    if (error?.status === 401) {
      logout();
    }
  }, [error]);

  useEffect(() => {
    storeCurrentUser(userData || null);
  }, [userData]);

  const isUserRole = (roles: UserRoleIdEnum | UserRoleIdEnum[]) => {
    if (Array.isArray(roles)) {
      return Boolean(
        currentUser?.role.id && roles.includes(currentUser?.role.id),
      );
    }
    return roles === currentUser?.role.id;
  };

  return (
    <AuthStateContext.Provider
      value={{
        currentUser,
        setToken,
        setPermission,
        permission,
        isLoggedIn: !!token,
        logout,
        isUserRole,
        isLoading: isLoading,
        fetchUser,
      }}>
      {children}
    </AuthStateContext.Provider>
  );
};

export { AuthProvider, useAuth };
