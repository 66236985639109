import { ManageByEnum } from '../types/cleaningProducts.types';
import { isBusinessOwner } from './userRoleUtil';

export const hideThreeDotsInCodeTable = (
  type: ManageByEnum,
  currentUserRoleId: number,
): boolean => {
  return isBusinessOwner(currentUserRoleId) && type === ManageByEnum.CC_MANAGED
    ? true
    : false;
};
