import React, { FC } from 'react';

import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { space, SpaceProps } from 'styled-system';

import styled from '@emotion/styled';

import theme from '../../theme';
type Props = SpaceProps &
  Omit<ButtonProps, 'shape' | 'ghost' | 'danger' | 'type'> & {
    size?: 'small' | 'middle' | 'large';
  };

const StyledButton = styled(Button)<Props>`
  color: ${theme.brandDarkTeal};
  border: 1px solid ${theme.brandDarkTeal};
  height: 40px;
  padding: 10px 24px 10px 24px;
  border-radius: 8px;
  font-family: SF Pro Display;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  font-weight: 590;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(102, 204, 176, 0.2) !important;
    border: 1px solid rgba(102, 204, 176, 1) !important;
    color: ${theme.brandDarkTeal}!important;
  }

  &:active {
    background: rgba(102, 204, 176, 0.2) !important;
    border: 1px solid ${theme.brandDarkTeal};
    color: ${theme.brandDarkTeal}!important;
  }
  &:disabled {
    cursor: not-allowed !important;
    border-color: ${theme.gray600} !important;
    color: ${theme.gray600}!important;
    background: ${theme.white}!important;
    box-shadow: none !important;
  }
  &.btn-middle {
    height: 32px;
    padding: 2px 24px;
    font-size: 15px;
    font-weight: 590;
    line-height: 20px;
  }
  &.btn-small {
    height: 22px;
    padding: 2px 24px;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
  }
  &.ant-btn-icon-only {
    padding-inline: 8px;
  }
  ${space}
`;
export const SecondaryButton: FC<Props> = ({ size = 'large', ...props }) => {
  let icon: React.ReactNode | null = null;
  let className = `btn-${size} ${props.className || ''}`;
  if (props.icon || props.loading) {
    icon = <span className="btn-custom-icon-left">{props.icon}</span>;
  }
  if (props.disabled) {
    className = 'btn-disabled ' + className;
  }

  return (
    <StyledButton {...props} size={size} icon={icon} className={className}>
      {props.children}
    </StyledButton>
  );
};
