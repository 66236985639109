import { Col, Form, Row, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { UserI } from '../../../types/settings.types';
import ModalComponent from '../../../components/modals/ModalComponent';
import {
  Flex,
  FormContainer,
  PhoneNumberInput,
  SelectComp,
  Input,
} from '../../../components';
import {
  UserRoleFormattedI,
  formFieldStatusI,
} from '../../../types/user.types';
import { getRolesWithId } from '../../../utils/userRoleUtil';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (formValues: UserI) => void;
  loading: boolean;
  data?: UserI;
  rolesToCreate?: number[];
}

const AddEditUserModal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  loading,
  data,
  rolesToCreate,
}) => {
  const [form] = Form.useForm();

  const [contactNoStatus, setContactNoStatus] = useState(false);
  const [possibleRoles, setPossibleRoles] = useState<UserRoleFormattedI[]>([]);
  const isEditing = data ? true : false;

  const onFinish = (formValues: UserI) => {
    if (!isEditing) {
      formValues.phoneNumber = `+${formValues.phoneNumber}`;
    }
    onSubmit(formValues);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      form.setFieldValue('role', data?.role?.id);
    } else {
      form.resetFields();
    }
  }, [data, form]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (rolesToCreate) {
      const roles = getRolesWithId(rolesToCreate);
      setPossibleRoles(roles);
    }
  }, [rolesToCreate]);

  useEffect(() => {
    form.setFieldsValue({
      role: data?.role.id ? data.role.id : possibleRoles[0]?.id,
    });
  }, [possibleRoles, form]);

  const onOk = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo): void => {
        const phoneError = errorInfo.errorFields.find(
          (field: formFieldStatusI) => field.name[0] === 'phoneNumber',
        );
        setContactNoStatus(phoneError?.errors.length > 0 ? true : false);
      });
  };

  return (
    <ModalComponent
      onCancel={onCancel}
      closable={true}
      width={524}
      hideCancel={false}
      header={isEditing ? 'Edit User' : 'Add User'}
      visible={visible}
      onSubmit={onOk}
      loadingBtn={loading}
      primaryBtnLabel={isEditing ? 'Save' : 'Add'}>
      <Flex width={'100%'} display="">
        <FormContainer
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          onFinish={(v) => onFinish(v)}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="First Name *"
                name="firstName"
                rules={[{ required: true, message: 'Enter first name' }]}>
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Last Name *"
                name="lastName"
                rules={[{ required: true, message: 'Enter last name' }]}>
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Role *"
                name="role"
                rules={[{ required: true, message: 'Choose the user role' }]}>
                <SelectComp placeholder="Choose">
                  {possibleRoles?.map((role, index) => (
                    <Select.Option key={index} value={role.id}>
                      {role.role}
                    </Select.Option>
                  ))}
                </SelectComp>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Contact number *"
                name="phoneNumber"
                rules={[
                  { required: true, message: 'Enter the contact number' },
                  {
                    min: 10,
                    message: 'Phone number must be at least 10 digits',
                  },
                ]}>
                {/* 
                  TODO: Find an easier method to pass validations to the phone number
                  input component. 
                */}
                <PhoneNumberInput
                  disabled={data ? true : false}
                  placeholder="Enter contact number"
                  country={'nl'}
                  status={contactNoStatus ? 'error' : undefined}
                  onBlur={() => {
                    form
                      .validateFields(['phoneNumber'])
                      .then(() => setContactNoStatus(false))
                      .catch((errorInfo) => {
                        const roleError = errorInfo.errorFields.find(
                          (field: formFieldStatusI) =>
                            field.name[0] === 'phoneNumber',
                        );
                        setContactNoStatus(
                          roleError?.errors.length > 0 ? true : false,
                        );
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Email *"
                name="email"
                rules={[{ required: true, message: 'Enter the email' }]}>
                <Input
                  disabled={data ? true : false}
                  placeholder="Enter email"
                />
              </Form.Item>
            </Col>
          </Row>
        </FormContainer>
      </Flex>
    </ModalComponent>
  );
};
export default AddEditUserModal;
