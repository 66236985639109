import React, { FC } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { color, ColorProps, space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../../theme';

type Props = ColorProps &
  SpaceProps &
  Omit<ButtonProps, 'shape' | 'ghost' | 'danger' | 'type'> & {
    size?: 'small' | 'middle' | 'large';
  };

const StyledButton = styled(Button)<Props>`
  color: ${theme.brandDarkTeal};
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 590;
  line-height: 21px;
  &:hover {
    background: ${theme.brandCream} !important;
    color: ${theme.brandDarkTeal};
  }
  ${space}
  ${color}
`;

export const TextButton: FC<Props> = ({ size = 'large', ...props }) => {
  let icon: React.ReactNode | null = null;
  let className = `btn-${size} ${props.className || ''}`;
  if (props.icon) {
    icon = <span className="btn-custom-icon-left">{props.icon}</span>;
  }
  if (props.disabled) {
    className = 'btn-disabled ' + className;
  }

  return (
    <StyledButton
      {...props}
      size={size}
      className={className}
      icon={icon}
      type="text">
      {props.children}
    </StyledButton>
  );
};
