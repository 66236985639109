import styled from '@emotion/styled';
import { ColorPicker } from 'antd';
import { Color } from 'antd/es/color-picker/color';
import { FC } from 'react';
import theme from '../../theme';

export interface Props {
  size?: 'large' | 'middle' | 'small';
  onChange?: (value: Color, hex: string) => void;
  setColor?: string;
}

const ColorPickerStyle = styled(ColorPicker)`
  width: 100%;
  border: 0.5px solid ${theme.gray400};

  justify-content: left !important;
  .ant-color-picker-trigger-text {
    font-size: 15px !important;
  }
  :hover {
    border-color: ${theme.gray600};
  }
  .ant-color-picker-trigger.ant-color-picker-trigger-active {
    box-shadow: unset !important;
    border-color: ${theme.gray600} !important;
  }
`;

const ColorPickerComp: FC<Props> = (props) => {
  const { size = 'large', onChange, setColor } = props;
  return (
    <ColorPickerStyle
      defaultValue={setColor ? setColor : theme.brandTeal}
      showText
      size={size}
      onChange={onChange}
    />
  );
};

export default ColorPickerComp;
