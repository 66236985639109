import { useMutation, useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ILabelModalData } from '../types/TranslationTypes';
import { IEditLabel, IEditNewLabelParams } from '../types/TranslationTypes';
import { http, getApiError } from './_apiService';
import { ApiError } from './_types';
import { Message } from '../components/message/Message';

export const usePostLabel = () => {
  return useMutation<string, ApiError, ILabelModalData>(
    async (data: ILabelModalData) => {
      const response: AxiosResponse<string> = await http.post(`/labels`, data);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Label added successfully');
      },
    },
  );
};

export const useGetAllLabels = () => {
  return useQuery<ILabelModalData[], AxiosError>(
    'allLabels',
    async () => {
      const response: AxiosResponse<ILabelModalData[]> =
        await http.get(`/labels/all-labels`);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(getApiError(error));
      },
    },
  );
};

export const useGetJsonValues = () => {
  return useMutation<ILabelModalData, AxiosError, string>(
    async (key: string) => {
      const response: AxiosResponse<ILabelModalData> = await http.get(
        `/labels/${key}`,
      );
      return response.data;
    },
  );
};

export const useDeleteLabel = () => {
  return useMutation<string, ApiError, string>(
    async (key: string) => {
      const response: AxiosResponse<string> = await http.delete(
        `/labels/${key}`,
      );
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Successfully deleted the label.');
      },
    },
  );
};

export const useEditLabel = () => {
  return useMutation<string, ApiError, { key: string; data: IEditLabel }>(
    async ({ key, data }) => {
      const response: AxiosResponse<string> = await http.put(
        `/labels/${key}`,
        data,
      );
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Label edited successfully');
      },
    },
  );
};

export const useEditNewLabel = () => {
  return useMutation<string, ApiError, IEditNewLabelParams>(
    async ({ oldKey, newKey, data }) => {
      const response: AxiosResponse<string> = await http.put(
        `/labels/${oldKey}/${newKey}`,
        data,
      );
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Label edited successfully');
      },
    },
  );
};
