import { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import {
  Flex,
  FormContainer,
  H,
  PrimaryButton,
  Text,
  Input,
} from '../../components';
import theme from '../../theme';
import styled from '@emotion/styled';
import AuthLayout from './shared/AuthLayout';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordFormI } from '../../types/auth.types';
import { useSubmitForgotPassword } from '../../api/authHooks';
import { Message } from '../../components/message/Message';
import ResentLinkSent from './ResentLinkSent';
import { useSubmitDeleteAccountEmail } from '../../api/userHooks';

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const StyledLink = styled(Text)`
  color: ${theme.brandTeal};
  &:hover {
    color: ${theme.brandDarkTeal};
    cursor: pointer;
  }
`;

interface Props {
  isDeleteAccount?: boolean;
}

const ForgotPasswordPage: FC<Props> = ({ isDeleteAccount }) => {
  const [form] = Form.useForm();
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    mutate: submitForgotPassword,
    data: forgotPasswordData,
    isError: isForgotPasswordError,
    isLoading: isForgotPasswordLoading,
    error: forgotPasswordError,
  } = useSubmitForgotPassword();

  const {
    mutate: submitDeleteUserEmail,
    data: deleteAccountData,
    isError: isErrorSubmitDeleteUserEmail,
  } = useSubmitDeleteAccountEmail();

  const submitForgotPasswordEmail = (values: ForgotPasswordFormI) => {
    if (!emailSubmitted) {
      if (isDeleteAccount) {
        submitDeleteUserEmail(values);
      } else {
        submitForgotPassword(values);
      }
    }
  };

  useEffect(() => {
    if (
      (!isForgotPasswordError && forgotPasswordData) ||
      (!isErrorSubmitDeleteUserEmail && deleteAccountData)
    ) {
      setEmailSubmitted(true);
    }
  }, [forgotPasswordData, deleteAccountData]);

  useEffect(() => {
    if (forgotPasswordError) {
      Message.error(forgotPasswordError.msg);
    }
  }, [forgotPasswordData, isForgotPasswordError]);

  return (
    <AuthLayout>
      <div>
        {!emailSubmitted ? (
          <>
            <Flex width={'358px'}>
              <H type="largeTitle" color={theme.brandDarkTeal} mb="16px">
                {isDeleteAccount ? 'Delete Account' : 'Forgot Password'}
              </H>
              <Text type="subheading-regular" color={theme.gray800} mb="40px">
                If there is an account linked to these credentials, we&apos;ll
                <br /> send you a{' '}
                {isDeleteAccount
                  ? 'link to delete the account.'
                  : 'code for password reset.'}
              </Text>
            </Flex>

            <FormContainer
              form={form}
              layout="vertical"
              onFinish={(values) => {
                submitForgotPasswordEmail(values);
              }}>
              <FormItem
                label="Email ID *"
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  {
                    type: 'email',
                    message: 'Please input a valid email!',
                  },
                ]}>
                <Input placeholder="Enter email id" />
              </FormItem>
              <Form.Item>
                <PrimaryButton
                  htmlType="submit"
                  block
                  mt="40px"
                  loading={isForgotPasswordLoading}
                  disabled={isForgotPasswordLoading || emailSubmitted}>
                  {isDeleteAccount
                    ? 'Request Account Deletion'
                    : 'Reset Password'}
                </PrimaryButton>
              </Form.Item>
              <Flex textAlign={'center'}>
                <StyledLink
                  type="subheading-regular"
                  onClick={() => navigate('/login')}>
                  Back to Sign In
                </StyledLink>
              </Flex>
            </FormContainer>
          </>
        ) : (
          <ResentLinkSent form={form} isDeleteAccount={isDeleteAccount} />
        )}
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
