import { PermissionEnum } from './permission.types';

export enum UserRoleIdEnum {
  SUPER_ADMIN = 1,
  BUSINESS_OWNER = 2,
  CLEANER = 3,
  ADMIN = 4, // Business Level Admin
  SUPER_ADMIN_MEMBER = 5,
}
export enum UserRoleEnum {
  SUPER_ADMIN = 'SUPER_ADMIN',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  CLEANER = 'CLEANER',
  ADMIN = 'ADMIN',
  SUPER_ADMIN_MEMBER = 'SUPER_ADMIN_MEMBER',
}

export interface UserRoleI {
  id: UserRoleIdEnum;
  role: UserRoleEnum;
  permissions: PermissionEnum[];
}

export interface UserI {
  id?: string;
  firstName: string;
  lastName: string;
  role: UserRoleI; //  UserRoleIdEnum
  roleId: UserRoleIdEnum;
  phoneNumber: string;
  email: string;
  profilePicture: string;
}

export interface PutUserI {
  firstName: string;
  lastName: string;
  profilePicture?: File;
}

export interface UserRoleFormattedI {
  id: UserRoleIdEnum;
  role: string;
}

export interface UserMeResponseI {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: UserRoleI;
  profilePicture: string;
  isLabelAdmin: boolean;
  organization: OrganizationInMeI;
}

export interface OrganizationInMeI {
  businessName: string;
  venueLimit: number;
}

export interface formFieldStatusI {
  name: string[];
  errors: string[];
  warnings: string[];
}

export interface DeleteAccountEmailI {
  email: string;
}

export interface DeleteAccountI {
  token: string;
}

export interface UserDeleteAccountI {
  deleteToken: string;
}
