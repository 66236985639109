import { FC, useEffect, useState } from 'react';
import { Button, Dropdown, Menu, MenuProps } from 'antd';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Image } from '../../components';
import { DropdownBlack } from '../../assets';
import { AreaTypeFilterEnum } from '../../types/equipmentsItems.types';
import { getPascalCaseUtil } from '../../utils/caseUtil';

export const MenuComp = styled(Menu)`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  padding: 0px !important;
  width: 150px;

  @media (max-width: 760px) {
    width: 120px;
  }
`;

export const MenuItem = styled.div`
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 14px;
  margin-bottom: 0px !important;
`;
export const MenuItemClear = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 14px;
`;
export const DropdownComp = styled.span`
  margin-left: 5px;
  background: ${theme.gray200};
  border-radius: 14px;
  padding: 0px 8px;
`;
export const DropdownButton = styled(Button)`
  margin: 0px 16px !important;
  display: flex;
  justify-content: space-between;
  height: 32px;
  width: 150px;
  padding: 4px 12px 6px 16px !important;
  text-align: start !important;
  border-radius: 8px;
  border: 0.5px solid ${theme.brandDarkTeal} !important;

  @media (max-width: 760px) {
    margin-left: 0px !important;
    width: 100%;
  }

  &:hover {
    color: ${theme.brandDarkTeal} !important;
  }
  :focus {
    color: ${theme.black} !important;
    border-color: ${theme.brandDarkTeal} !important;
  }
`;
interface Props {
  onChange: (value: AreaTypeFilterEnum) => void;
}

const menuItems = [
  {
    key: AreaTypeFilterEnum.ALL_AREA,
    name: 'All Area',
  },
  {
    key: AreaTypeFilterEnum.BUILDING,
    name: 'Building',
  },
  {
    key: AreaTypeFilterEnum.KITCHEN,
    name: 'Kitchen',
  },
  {
    key: AreaTypeFilterEnum.SANITARY,
    name: 'Sanitary',
  },
];

const AreaSelector: FC<Props> = ({ onChange }) => {
  const [filter, setFilter] = useState<AreaTypeFilterEnum>(
    AreaTypeFilterEnum.ALL_AREA,
  );

  useEffect(() => {
    onChange(filter);
  }, [filter]);
  const items: MenuProps['items'] = menuItems.map((item) => {
    return {
      key: item.key,
      label: <a onClick={() => setFilter(item.key)}>{item.name}</a>,
    };
  });

  return (
    <Dropdown menu={{ items }}>
      <DropdownButton>
        <div>{filter ? getPascalCaseUtil(filter) : 'All Area'}</div>
        <div>
          <Image src={DropdownBlack} />
        </div>
      </DropdownButton>
    </Dropdown>
  );
};

export default AreaSelector;
