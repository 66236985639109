import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';
import {
  ColorProps,
  SpaceProps,
  TypographyProps,
  color,
  space,
  typography,
} from 'styled-system';

export type HeadingFontTypes =
  | 'largeTitle' //LargeTitle
  | 'title1Bold' //Title1
  | 'title2Bold' //Title2
  | 'title3Bold' //Title3
  | 'headlineBold' //Headline
  | 'largeTitleRegular' //LargeTitle
  | 'title1Regular' //Title1
  | 'title2Regular' //Title2
  | 'title3Regular'; //Title3

type Props = React.HTMLAttributes<HTMLDivElement> &
  SpaceProps &
  ColorProps &
  TypographyProps & {
    type: HeadingFontTypes;
    children?: string | React.ReactNode;
    id?: string;
    display?: CSSProperties['display'];
    onClick?: () => void;
    cursor?: CSSProperties['cursor'];
  };

const getStyleFontType = (type: HeadingFontTypes) => {
  switch (type) {
    case 'largeTitle':
      return `
            font-size: 34px;
            font-weight: 700;
            line-height: 41px;
          `;
    case 'title1Bold':
      return `
          font-size: 28px;
          font-weight: 700;
          line-height: 34px;
          `;

    case 'title2Bold':
      return `
          font-size: 22px;
          font-weight: 700;
          line-height: 28px;
          `;
    case 'title3Bold':
      return `
          font-size: 20px;
          font-weight: 590;
          line-height: 25px;
          `;
    case 'headlineBold':
      return `
          font-size: 17px;
          font-weight: 590;
          line-height: 22px;
          `;
    case 'largeTitleRegular':
      return `
          font-size: 34px;
          font-weight: 400;
          line-height: 41px;
          `;
    case 'title1Regular':
      return `
          font-size: 28px;
          font-weight: 400;
          line-height: 34px;
          `;
    case 'title2Regular':
      return `
          font-size: 22px;
          font-weight: 400;
          ine-height: 28px;
          `;
    case 'title3Regular':
      return `
          font-size: 20px;
          font-weight: 400;
          line-height: 25px;
          `;
    default:
      return null;
  }
};

export const H = styled.p<Props>`
  margin-bottom: 0px;
  font-family: SF Pro Display;
  letter-spacing: -0.4000000059604645px;
  cursor: ${(props) => props.cursor && props.cursor};
  ${(props) => getStyleFontType(props.type)}
  ${typography}
  ${space}
  ${color}
`;
