/* eslint-disable prefer-destructuring */
import styled from '@emotion/styled';
import { Form, Radio, RadioChangeEvent } from 'antd';
import qs from 'qs';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginUser } from '../../api/authHooks';
import {
  Flex,
  FormContainer,
  H,
  Input,
  PasswordInput,
  PhoneNumberInput,
  PrimaryButton,
  Text,
} from '../../components';
import { Message } from '../../components/message/Message';
import { useAuth } from '../../providers/auth-context';
import theme from '../../theme';
import { UserLoginI } from '../../types/auth.types';
import { validateEmail } from '../../utils/emailValidation.util';
import { getStoredAuthToken } from '../../utils/storageUtil';
import AuthLayout from './shared/AuthLayout';

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-wrapper {
    &:hover .ant-radio-inner {
      border-color: ${theme.brandTeal};
    }

    &.ant-radio-wrapper-checked {
      .ant-radio-inner {
        border-color: ${theme.brandTeal};
        background-color: ${theme.brandTeal};
      }
    }
  }
`;

const LoginPage: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setToken, setPermission, logout } = useAuth();
  const location = useLocation();
  const messageStr = location.state?.message;
  const {
    isLoading,
    error,
    data,
    mutate: loginUser,
    isSuccess,
  } = useLoginUser();
  const [selectedOption, setSelectedOption] = useState('email');

  useEffect(() => {
    if (messageStr) {
      Message.success(messageStr);
      location.state.message = undefined;
    }
    error && Message.error(error.msg);
  }, [error]);

  useEffect(() => {
    const token = getStoredAuthToken();
    if (token) logout();
  }, []);

  useEffect(() => {
    if (data && !error && data.access_token) {
      setToken(data.access_token);
      setPermission(data);
      const queries = qs.parse(location.search.replace('?', ''));
      const redirectTo = queries['redirectTo'];
      if (redirectTo && typeof redirectTo === 'string') {
        navigate(redirectTo);
      } else {
        navigate('/');
      }
    } else if (data && !error && data.resetToken) {
      navigate(`/reset-password/${data.resetToken}`);
    }
  }, [isSuccess]);

  const onFinish = (data: UserLoginI) => {
    if (selectedOption === 'contactNumber') {
      data.username = `+${data.username}`;
    }
    loginUser(data);
  };

  const handleOptionSelection = ({ target: { value } }: RadioChangeEvent) => {
    form.resetFields(['username']);
    setSelectedOption(value);
  };

  const loginOptions = [
    { label: 'Email', value: 'email' },
    { label: 'Contact Number', value: 'contactNumber' },
  ];

  return (
    <AuthLayout>
      <div>
        <Flex width={'358px'}>
          <H type="largeTitle" color={theme.brandDarkTeal} mb="16px">
            Sign In
          </H>
          <Text type="subheading-regular" color={theme.gray800} mb="40px">
            A spotless restaurant experience inside! Just enter <br />
            your email address and password.
          </Text>
        </Flex>
        <FormContainer
          form={form}
          layout="vertical"
          onFinish={(v) => onFinish(v as UserLoginI)}>
          <Form.Item label="Sign In via">
            <StyledRadioGroup
              options={loginOptions}
              onChange={handleOptionSelection}
              value={selectedOption}
            />
          </Form.Item>
          <Form.Item
            label={
              selectedOption === 'email' ? 'Email ID *' : 'Contact Number *'
            }
            name="username"
            rules={[
              {
                required: true,
                message:
                  selectedOption === 'email'
                    ? 'Please input your email!'
                    : 'Please input your contact number!',
              },
              {
                validator: (_, value) => validateEmail(selectedOption, value),
              },
            ]}>
            {selectedOption === 'email' ? (
              <Input placeholder="Enter email" />
            ) : (
              <PhoneNumberInput
                placeholder="Enter contact number"
                country={'nl'}
              />
            )}
          </Form.Item>
          <FormItem
            label="Password "
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
            ]}>
            <PasswordInput
              placeholder="Enter password"
              showPasswordPolicy={false}
            />
          </FormItem>
          <Text
            onClick={() => navigate('/forgot-password')}
            type="subheading-regular"
            color={theme.brandTeal}
            mt="8px"
            cursor="pointer">
            Forgot Password?
          </Text>
          <Form.Item>
            <PrimaryButton
              htmlType="submit"
              block
              mt="40px"
              loading={isLoading}>
              Sign In
            </PrimaryButton>
          </Form.Item>
        </FormContainer>
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
