import { FC, useEffect } from 'react';
import {
  Flex,
  FormContainer,
  PasswordInput,
  PrimaryButton,
  Text,
} from '../../../components';
import SettingsLayout from '../settingsLayout/SettingsLayout';
import { Col, Form, Row, Flex as AntFlex } from 'antd';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { checkPasswordStrengthWarningMsg } from '../../../utils/checkPasswordStrengthWarningMsgUtil';
import { ChangePasswordFormI } from '../../../types/auth.types';
import { useChangePassword } from '../../../api/authHooks';
import { Message } from '../../../components/message/Message';

const FlexComp = styled(AntFlex)`
  border-bottom: 1px solid var(--Button-Fill, ${theme.gray400});
  padding: 24px 24px 16px 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ChangePassword: FC = () => {
  const [form] = Form.useForm();
  const {
    mutate: changePassword,
    data: changedPassword,
    error: changedPasswordError,
    isSuccess: isSuccessChangedPassword,
    isLoading: changingThePassword,
  } = useChangePassword();

  useEffect(() => {
    if (changedPassword) {
      Message.success('Password changed successfully!');
      form.resetFields();
    }
  }, [isSuccessChangedPassword]);

  useEffect(() => {
    if (changedPasswordError) Message.error(`${changedPasswordError.msg}`);
  }, [changedPasswordError]);

  const submitChangePassword = (values: ChangePasswordFormI) => {
    if (values)
      changePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
  };
  return (
    <SettingsLayout>
      <FlexComp>
        <Text type="body-bold" color={theme.brandDarkTeal} fontSize={'20px'}>
          Change Password
        </Text>
      </FlexComp>
      <Flex p="32px 24px" overflow="auto" height={'60vh'}>
        <FormContainer
          form={form}
          layout="vertical"
          onFinish={(values) => submitChangePassword(values)}>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[
                  { required: true, message: 'Please enter your password!' },
                ]}>
                <PasswordInput
                  placeholder="Enter current password"
                  showPasswordPolicy={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: ' ',
                  },
                  () => ({
                    validator(_, value, callback) {
                      try {
                        checkPasswordStrengthWarningMsg(value);
                        callback();
                      } catch (error) {
                        if (error instanceof Error) callback(' ');
                      }
                    },
                  }),
                ]}>
                <PasswordInput
                  placeholder="Enter new password"
                  showPasswordPolicy={true}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={['newPassword']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value, callback) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return callback();
                      }
                      return callback(
                        'The confirm password that you entered do not match',
                      );
                    },
                  }),
                  {
                    required: true,
                    message: 'Please enter your password again',
                  },
                ]}>
                <PasswordInput
                  placeholder="Confirm password"
                  showPasswordPolicy={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormContainer>
      </Flex>
      <Flex
        display="flex"
        flexDirection="row-reverse"
        p={'16px 32px'}
        borderTop={`1px solid var(--Button-Fill, ${theme.gray400})`}>
        <PrimaryButton
          onClick={() => form.submit()}
          disabled={changingThePassword && !changedPassword}
          loading={changingThePassword && !changedPassword}>
          Save
        </PrimaryButton>
      </Flex>
    </SettingsLayout>
  );
};
export default ChangePassword;
