import { FC, PropsWithChildren } from 'react';
import { Col, Row } from 'antd';

import SettingsMenu from './SettingsMenu';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { H } from '../../../components';
import { MainCard } from '../../../components/MainCard';

const RowComp = styled(Row)`
  border-top: 1px solid ${theme.gray300};
`;

const MenuCol = styled(Col)`
  padding: 24px 36px 24px 24px;
  border-radius: 8px 0px 0px 8px;
  background: #66ccb00a;
  border-right: ${`1px solid var(--Button-Fill,${theme.gray300})`};
`;

const BodyCol = styled(Col)`
  @media (max-width: 768px) {
    height: fit-content !important;
  }
`;

const SettingsLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MainCard>
      <H type="title3Bold" mx="24px" mb="16px" color={theme.brandDarkTeal}>
        Settings
      </H>
      <RowComp>
        <MenuCol xs={24} sm={24} md={6} lg={6} xl={5} xxl={4}>
          <SettingsMenu />
        </MenuCol>
        <BodyCol xs={24} sm={24} md={18} lg={18} xl={19} xxl={20}>
          {children}
        </BodyCol>
      </RowComp>
    </MainCard>
  );
};
export default SettingsLayout;
