import { Form } from 'antd';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, FormContainer, H, Text } from '../../components';
import theme from '../../theme';
import AuthLayout from './shared/AuthLayout';
import { useDeleteAccount, useValidateDeleteToken } from '../../api/userHooks';
import { DeleteAccountI } from '../../types/user.types';
import { DeleteButton } from '../../components/buttons/DeleteButton';

const ConfirmDeleteAccountPage: FC = () => {
  const [form] = Form.useForm();
  const { token } = useParams();
  const navigate = useNavigate();
  const { mutate: deleteAccount, data: deleteAccountData } = useDeleteAccount();

  const {
    mutate: validateDeleteToken,
    error: validateDeleteTokenError,
    data: validatedDeleteToken,
  } = useValidateDeleteToken();

  useEffect(() => {
    if (deleteAccountData) {
      navigate('/login');
    }
  }, [deleteAccountData]);

  useEffect(() => {
    if (token) {
      validateDeleteToken(token);
    }
  }, []);

  useEffect(() => {
    if (validateDeleteTokenError) {
      navigate(`/404/${token}`);
    }
  }, [validateDeleteTokenError]);

  return (
    <>
      {validatedDeleteToken?.deleteToken && (
        <AuthLayout>
          <div>
            <div>
              <Flex width={'358px'}>
                <H type="largeTitle" color={theme.brandDarkTeal} mb="16px">
                  Delete Account
                </H>
                <Text type="subheading-regular" color={theme.gray800} mb="40px">
                  When you delete the account, all associated data and access
                  rights will be permanently removed. This action cannot be
                  undone.
                </Text>
              </Flex>
              <FormContainer
                form={form}
                layout="vertical"
                onFinish={() => {
                  const deleteAccountFormData: DeleteAccountI = {
                    token: validatedDeleteToken.deleteToken,
                  };
                  deleteAccount(deleteAccountFormData);
                }}>
                <Form.Item>
                  <DeleteButton block onClick={() => form.submit()}>
                    Confirm Delete
                  </DeleteButton>
                </Form.Item>
              </FormContainer>
            </div>
          </div>
        </AuthLayout>
      )}
    </>
  );
};

export default ConfirmDeleteAccountPage;
